export const action = {
  controlButtonClicked: "ControlButtonClicked",
  screenDisplayed: "ScreenDisplayed",
  controlHyperLinkClicked: "ControlHyperLinkClicked",
  controlTileClicked: "ControlTileClicked",
  controlToggleEnabled: "ControlToggleEnabled",
  controlDisplayed: "ControlDisplayed",
  moduleDisplayed: "ModuleDisplayed",
};

export const activity = {
  scanV01: "Scan-v01",
};

export const cdmData = {
  navigation: {
    GENERAL_NAVIGATION_BACK: {
      controlName: "BackButton",
      activity: activity.scanV01,
      action: action.controlButtonClicked,
    },
  },
  /**
   * MarkupApplyScreenContainer
   */
  MARKUP_PLACE_DELETE_MARK: {
    controlName: "DeleteButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_PLACE_FINISH: {
    controlName: "DoneButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_PLACE_HISTORY_UNDO: {
    controlName: "UndoButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_PLACE_HISTORY_REDO: {
    controlName: "RedoButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_PLACE_ADD_A_NEW_MARK: {
    controlName: "AddNewMarkButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_PLACE_NEW_MARK: {
    controlName: "NewMarkButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_PLACE_SAVED_MARKS: {
    controlName: "SavedMarksButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_PLACE_MANAGE_MARKS: {
    controlName: "ManageMarkButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_PLACE_START_SCALING: {
    controlName: "StartScaling",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_PLACE_ALIGN_MARK: {
    controlName: "AlignMark",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_PLACE_ALIGN_PAGE: {
    controlName: "AlignPage",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  /**
   * MarkupCreateScreenContainer
   */
  MARKUP_CREATE_SAVE_THICKNESS_THIN: {
    controlName: "ScribbleSelection",
    controlDetail: "Thin",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_CREATE_SAVE_THICKNESS_MEDIUM: {
    controlName: "ScribbleSelection",
    controlDetail: "Medium",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_CREATE_SAVE_THICKNESS_THICK: {
    controlName: "ScribbleSelection",
    controlDetail: "Thick",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_CREATE_SAVE_THICKNESS_TEXT: {
    controlName: "ScribbleSelection",
    controlDetail: "Text",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_CREATE_HISTORY_UNDO: {
    controlName: "UndoButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_CREATE_HISTORY_DELETE: {
    controlName: "DeleteButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_CREATE_HISTORY_REDO: {
    controlName: "RedoButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_CREATE_ADD: {
    controlName: "AddButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_CREATE_SAVE: {
    controlName: "PlaceButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_CREATE_START_DRAWING: {
    controlName: "StartDrawing",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_SAVE_MARK_CHECKBOX_ENABLED: {
    controlName: "SaveMarkCheckBox",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
    controlDetail: "Enabled",
  },
  MARKUP_SAVE_MARK_CHECKBOX_DISABLED: {
    controlName: "SaveMarkCheckBox",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
    controlDetail: "Disabled",
  },

  /**
   * MarkupListScreenContainer
   */
  MARKUP_SELECT_EDIT: {
    controlName: "SelectButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_SELECT_CANCEL: {
    controlName: "CancelButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_SELECT_ADD: {
    controlName: "AddButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_SELECT_DELETE: {
    controlName: "DeleteButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  MARKUP_SELECT_LIST: {
    controlName: "MarkupList",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
    adaptControlDetail: ({ count }) => {
      return {
        markupListCount: count,
      };
    },
  },
  /**
   * DesktopFooter
   */
  FOOTER_CANCEL_BUTTON: {
    controlName: "CancelButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  FOOTER_DONE_BUTTON: {
    controlName: "DoneButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  /**
   * PageSelectionView
   */
  PAGE_SELECT_TILES_VIEW: {
    controlName: "TilesViewButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },
  PAGE_SELECT_PAGE_VIEW: {
    controlName: "PageViewButton",
    activity: activity.scanV01,
    action: action.controlButtonClicked,
  },

  /**
   * Plan
   */
  PLAN: {
    controlName: "UserPlanInfo",
    action: action.moduleDisplayed,
    adaptControlDetail: ({ programLevel, serviceLevel }) => ({
      programLevel,
      serviceLevel,
    }),
  },
};
