/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { tokens } from "../../css.settings";
import { useEffect, useState } from "react";

export const SidebarTabContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(100% + 1px);
  width: 195px;
  background-color: ${tokens.color.white};
  box-shadow: -8px 0px 20px #00000004;
  z-index: 0;

  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateX(100%);
  opacity: 0;

  &.is-open {
    transform: translateX(0%);
    opacity: 1;
  }
`;

const DesktopSidebarTabContent = ({
  children,
  isOpen,
  isCurrent,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <SidebarTabContent
      {...props}
      className={open ? "is-open" : ""}
      hidden={!isCurrent}
    >
      {children}
    </SidebarTabContent>
  );
};

export default DesktopSidebarTabContent;
