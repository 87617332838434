/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import ViewModeSwitch from "../ViewModeSwitch";

const sidebarContainerStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ThumbnailWrapper = styled.div`
  overflow-y: scroll;
  max-height: 100%;

  /* Hide Sidebar */
  -ms-overflow-style: none; /* Edge */
  ::-webkit-scrollbar {
    display: none; /* Safari & Chrome */
  }
`;

const PageSelectionSidebar = ({
  items,
  onFullClick,
  onTilesClick,
  currentView = "full",
}) => {
  return (
    <div data-testid="page-selection-sidebar" css={sidebarContainerStyle}>
      <ViewModeSwitch
        currentView={currentView}
        onFullClick={onFullClick}
        onTilesClick={onTilesClick}
      />
      <ThumbnailWrapper>{items}</ThumbnailWrapper>
    </div>
  );
};

export default PageSelectionSidebar;
