/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import PageSelection from "../../../components/PageSelection/PageSelection";
import useMediaControls from "../../../hooks/useMediaControls";
import { StaticViewport } from "../../../utils/markup-placement-viewer/static-viewport";

const FullPageSelectionContainer = ({ onSelectPage }) => {
  const [mediaReady, setMediaReady] = useState(false);
  const [mediaLoading, setMediaLoading] = useState(false);

  const {
    media,
    numPages,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
    selectedPage,
    selectedDocument,
    globalSelectedPage,
  } = useMediaControls();

  const viewerRef = useRef();
  const pageRef = useRef(null);
  const pageContainerRef = useRef(null);

  const adJustViewerSize = () => {
    viewerRef.current.setViewportSize(
      pageContainerRef.current.offsetWidth,
      pageContainerRef.current.offsetHeight
    );
  };

  const onMediaLoad = (isLoading) => {
    setMediaLoading(isLoading);
  };

  useEffect(() => {
    if (!media) return;

    viewerRef.current = new StaticViewport(
      pageRef.current,
      media,
      selectedDocument,
      selectedPage
    );

    adJustViewerSize();

    viewerRef.current.onSelectPage(onSelectPage);
    viewerRef.current.onLoadingUpdate(onMediaLoad);
    viewerRef.current.render().then(() => setMediaReady(true));

    window.addEventListener("resize", adJustViewerSize);
    return () => {
      window.removeEventListener("resize", adJustViewerSize);
      viewerRef.current.onDestroy();
    };
  }, []);

  useEffect(() => {
    viewerRef.current.showPage(globalSelectedPage);
  }, [selectedDocument, selectedPage]);

  useEffect(() => {
    if (!media) return;

    viewerRef.current.media = media;
  }, [media]);

  return (
    <PageSelection
      currentPage={globalSelectedPage}
      onClickNext={nextPage}
      onClickPrev={prevPage}
      canGoNext={hasNextPage}
      canGoPrev={hasPrevPage}
      numberOfPages={numPages}
      ready={mediaReady}
      loading={mediaLoading}
      pageRef={pageRef}
      pageContainerRef={pageContainerRef}
    />
  );
};

export default FullPageSelectionContainer;
