import axios from "axios";

export const request = async ({
  resource = "",
  method = "GET",
  accessToken,
  authToken,
  sessionId,
  url,
  headers = {},
  ...rest
}) => {
  return await axios({
    url: url + resource,
    method,
    headers: {
      ...((accessToken && {
        Authorization: `Bearer ${accessToken}`,
      }) ||
        {}),
      ...((sessionId && {
        "x-session-id": sessionId,
      }) ||
        {}),
      ...((authToken && {
        "x-auth-token": authToken,
      }) ||
        {}),
      ...headers,
    },
    ...rest,
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
