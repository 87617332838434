export const ViewportEvents = {
  MARKUP_UPDATE: "markup:update",
  MARKUP_DELETE: "markup:delete",
  MARKUP_SCALE: "markup:scale",
  MARKUP_MOVE: "markup:move",
  SELECTION_UPDATE: "selection:update",
  VIEWPORT_SCALE: "viewport:scale",
  VIEWPORT_MOVE: "viewport:move",
};

export class ViewportEventSystem {
  constructor() {
    this._callbackMapping = {};
  }

  on(eventName, callback) {
    if (!this._callbackMapping[eventName]) {
      this._callbackMapping[eventName] = [];
    }
    this._callbackMapping[eventName].push(callback);
  }

  off(eventName, callback) {
    const eventCallbacks = this._callbackMapping[eventName];
    if (!eventCallbacks) return;
    const callbackIndex = eventCallbacks.findIndex((cb) => cb === callback);
    if (callbackIndex >= 0) {
      eventCallbacks.splice(callbackIndex, 1);
    }
  }

  emit(eventName, payload) {
    const eventCallbacks = this._callbackMapping[eventName];
    if (!eventCallbacks) return;
    eventCallbacks.forEach((callback) => callback(payload));
  }

  clear() {
    this._callbackMapping = {};
  }
}
