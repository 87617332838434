const createFirstMark = (isMobile = false) => ({
  id: "create-first-mark",
  isRepeatable: true,
  isClosable: false,
  requirement: ({ numMarks }) => numMarks === 0,
  steps: [
    {
      title: "coachmark.create_first_mark.step_0",
      placement: isMobile ? "top" : "trailing",
      anchorElement: '[data-coachmark="new-mark-button"]',
    },
  ],
});

const applyOnboarding = (isMobile = false) => ({
  id: "apply-onboarding",
  isRepeatable: false,
  isClosable: true,
  requirement: ({ placedMarks }) => placedMarks > 0,
  steps: [
    {
      title: "coachmark.apply_onboarding.step_0",
      placement: "top",
      anchorElement: isMobile
        ? '[data-coachmark="markup-element"]'
        : '[data-coachmark="zoom-controls"]',
    },
    {
      title: [
        "coachmark.apply_onboarding.step_1.line_1",
        "coachmark.apply_onboarding.step_1.line_2",
      ],
      placement: "top",
      anchorElement: '[data-coachmark="markup-element"]',
    },
  ],
});

const MarkupApplyCoachmarks = {
  desktop: {
    autoPlay: false,
    coachmarks: [createFirstMark(false), applyOnboarding(false)],
  },
  mobile: {
    autoPlay: false,
    coachmarks: [createFirstMark(true), applyOnboarding(true)],
  },
};

export default MarkupApplyCoachmarks;
