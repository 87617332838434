export default class MediaLoader {
  get targetMimeTypes() {
    return [];
  }

  canMergeMimeType(mimeType) {
    return this.targetMimeTypes.some((mime) => mime === mimeType);
  }

  async loadMedia(payload) {}
}
