/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../css.settings";
import FeatureToggle, { Features } from "../FeatureToggle/FeatureToggle";

import StrokeThicknessButton from "../StrokeThicknessButton";
import TextButton from "../TextButton";

//TODO: Verificar se é family 0 mesmo
const rootStyle = css`
  display: flex;
  align-items: center;
  font-family: ${tokens.typography.family1};
  span {
    font-weight: 400;
  }
`;

const StrokeThicknessButtons = ({
  onSelectThin,
  onSelectMedium,
  onSelectThick,
  onSelectText,
  thickness = "thin",
  isTyping,
  styles = {},
}) => {
  const { t } = useTranslation();
  return (
    <section
      data-testid="stroke-thickness-buttons"
      data-coachmark="stroke-thickness-buttons"
      css={[rootStyle, styles.root]}
    >
      <StrokeThicknessButton
        label={t("page.new_mark.thickness.thin")}
        active={!isTyping && thickness === "thin"}
        onClick={onSelectThin}
      />
      <StrokeThicknessButton
        label={t("page.new_mark.thickness.medium")}
        thickness="medium"
        active={!isTyping && thickness === "medium"}
        onClick={onSelectMedium}
      />
      <StrokeThicknessButton
        label={t("page.new_mark.thickness.thick")}
        thickness="thick"
        active={!isTyping && thickness === "thick"}
        onClick={onSelectThick}
      />
      <FeatureToggle feature={Features.TEXT_FEATURE}>
        <TextButton
          label={t("page.new_mark.text.label")}
          active={isTyping}
          onClick={onSelectText}
        />
      </FeatureToggle>
    </section>
  );
};

export default StrokeThicknessButtons;
