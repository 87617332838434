import { Route } from "react-router-dom";
import useDevice from "../../hooks/useDevice";
const DeviceRoute = ({ path, mobile, desktop, exact = false }) => {
  const { isDesktop } = useDevice();

  return (
    <Route path={path} exact={exact}>
      {isDesktop ? desktop : mobile}
    </Route>
  );
};

export default DeviceRoute;
