/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ActionButtons from "../../../components/ActionButtons";
import CircularIndicator from "../../../components/CircularIndicator";
import Screen from "../../../components/Screen";
import { useFetchMarks } from "../../../contexts/FetchMarks";
import { useJarvisContext } from "../../../contexts/Jarvis";
import { usePlaceMarkContext } from "../../../contexts/PlaceMark";
import { useSessionContext } from "../../../contexts/Session";
import useCallbackRequest from "../../../hooks/useCallbackRequest";
import useMergeMedia from "../../../hooks/useMergeMedia";
import { sendInternalEvent } from "../../../plugins/analytics";
import { refs } from "../../../plugins/analytics/refs";
import { paths } from "../../../route.settings";
import { Viewport } from "../../../utils/markup-placement-viewer/viewport";
import { ViewportEvents } from "../../../utils/markup-placement-viewer/viewport-events";
import FooterContainer from "../FooterContainer";
import { useCoachmarksStore } from "../../../store";

const viewerStyle = css`
  position: relative;
  width: 100%;
  height: 100%;
`;

const circularIndicatorStyle = {
  height: "92%",
};

const MarkupsApplyScreenContainer = () => {
  const { t } = useTranslation();
  const { callbackSchema, callbackSessionId, redirectUrl } =
    useSessionContext();

  const showCoachmark = useCoachmarksStore((store) => store.showCoachmark);

  const { markups = [], isLoading } = useFetchMarks();

  const sendCallbackData = useCallbackRequest(
    callbackSchema,
    callbackSessionId
  );
  const {
    undo,
    redo,
    canUndo,
    canRedo,
    hasMarkups,
    placedMarks,
    placedMarksList,
    updateMarkPosition,
    removePlacedMark,
    selectedDocument,
    selectedPage,
    numberOfPages,
  } = usePlaceMarkContext();

  const { replaceDocuments, closeInstance } = useJarvisContext();

  const { loading, mergeResult, mergeMedia } = useMergeMedia();

  const history = useHistory();

  const viewerRef = useRef();
  const containerRef = useRef(null);
  const bodyRef = useRef(null);

  const [isDirty, setIsDirty] = useState(false);
  const [canTrash, setCanTrash] = useState(false);
  const [mediaLoading, setMediaLoading] = useState(true);
  const [isMarkMoved, setIsMarkMoved] = useState(false);
  const [isPageMoved, setIsPageMoved] = useState(false);

  const onViewPortMove = () => setIsPageMoved(true);
  const onMarkMove = () => setIsMarkMoved(true);

  const adjustViewerSize = () => {
    if (!viewerRef.current) return;
    viewerRef.current.setWidth(bodyRef.current.offsetWidth);
    viewerRef.current.setHeight(bodyRef.current.offsetHeight);
  };

  const onTrash = () => {
    const viewer = viewerRef.current;
    const markup = viewer.activeComponent;
    viewer.deleteMark(markup.uid);
    removePlacedMark(markup.uid);
    sendInternalEvent(refs.MARKUP_PLACE_DELETE_MARK);
  };

  const onDone = async () => {
    if (isMarkMoved) {
      sendInternalEvent(refs.MARKUP_PLACE_ALIGN_MARK);
    }

    if (isPageMoved) {
      sendInternalEvent(refs.MARKUP_PLACE_ALIGN_PAGE);
    }
    sendInternalEvent(refs.MARKUP_PLACE_FINISH);
    await mergeMedia(placedMarksList, selectedDocument.mimeType);
  };

  const onUndo = () => {
    sendInternalEvent(refs.MARKUP_PLACE_HISTORY_UNDO);
    undo();
  };

  const onRedo = () => {
    sendInternalEvent(refs.MARKUP_PLACE_HISTORY_REDO);
    redo();
  };

  const redirectToUrl = (url) => {
    window.location.href = url;
  };

  const onSelectionUpdate = useCallback(
    (selectedComponent) => {
      setCanTrash(hasMarkups && selectedComponent.isMarkup);
    },
    [hasMarkups, placedMarks]
  );

  const onUpdateMarkScale = () => {
    setIsDirty(true);
  };

  const onHistoryBack = () => {
    history.goBack();

    if (numberOfPages === 1) {
      closeInstance({ resultData: { source: "backButton" } });
    }
  };

  useEffect(() => {
    if (isDirty) {
      sendInternalEvent(refs.MARKUP_PLACE_START_SCALING);
    }
  }, [isDirty]);

  useEffect(() => {
    if (selectedPage === null || !selectedDocument) {
      return history.replace(paths.DOCUMENT_SELECT);
    }

    viewerRef.current = new Viewport(containerRef.current);
    viewerRef.current.setMedia(selectedDocument, selectedPage);
    viewerRef.current
      .load()
      .then(() => viewerRef.current.render())
      .then(() => {
        setMediaLoading(false);
      });

    adjustViewerSize();
    return () => {
      viewerRef.current.onDestroy();
    };
  }, [selectedPage, selectedDocument]);

  useEffect(() => {
    if (!viewerRef.current) return;
    viewerRef.current.eventSystem.clear();
    viewerRef.current.eventSystem.on(
      ViewportEvents.SELECTION_UPDATE,
      onSelectionUpdate
    );
    viewerRef.current.eventSystem.on(
      ViewportEvents.VIEWPORT_MOVE,
      onViewPortMove
    );
    viewerRef.current.eventSystem.on(
      ViewportEvents.MARKUP_UPDATE,
      updateMarkPosition
    );
    viewerRef.current.eventSystem.on(
      ViewportEvents.MARKUP_SCALE,
      onUpdateMarkScale
    );
    viewerRef.current.eventSystem.on(ViewportEvents.MARKUP_MOVE, onMarkMove);
  }, [viewerRef, onSelectionUpdate]);

  useEffect(() => {
    if (!viewerRef.current || isLoading || mediaLoading || !markups) return;
    viewerRef.current.loadMarks(placedMarks);
    showCoachmark({
      numMarks: markups.length,
      placedMarks: placedMarks.length,
    });
  }, [markups, placedMarks, viewerRef, isLoading, mediaLoading]);

  useEffect(() => {
    (async () => {
      if (!mergeResult.data) {
        return;
      }

      if (redirectUrl) {
        sendCallbackData({
          signed_document_url: mergeResult.data,
        }).then(() => {
          redirectToUrl(redirectUrl);
        });
      } else if (callbackSchema) {
        const callbackURL = new URL(callbackSchema);
        const searchParams = callbackURL.searchParams;
        const encodedResult = encodeURIComponent(mergeResult.data);
        searchParams.set("signed_document_url", encodedResult);
        redirectToUrl(callbackURL.href);
      } else {
        await replaceDocuments(mergeResult.data);
        await closeInstance({ resultData: { source: "doneButton" } });
      }
    })();
  }, [mergeResult, callbackSchema]);

  useEffect(() => {
    adjustViewerSize();
    window.addEventListener("resize", adjustViewerSize);

    return () => {
      window.removeEventListener("resize", adjustViewerSize);
    };
  }, []);

  return (
    <Screen
      bodyRef={bodyRef}
      scrollable={false}
      title={t("page.apply_mark.title")}
      actionButtonTitle={t("common.done")}
      actionButtonDisabled={!hasMarkups}
      actionButtonLoading={loading}
      onClickActionButton={onDone}
      onHistoryBack={onHistoryBack}
      bodyFooter={
        <ActionButtons
          canTrash={canTrash}
          canRedo={canRedo}
          canUndo={canUndo}
          onTrash={onTrash}
          onUndo={onUndo}
          onRedo={onRedo}
        />
      }
      body={
        <div css={[viewerStyle]} ref={containerRef}>
          {mediaLoading && (
            <CircularIndicator scale={1.5} style={circularIndicatorStyle} />
          )}
        </div>
      }
      footer={<FooterContainer />}
    />
  );
};

export default MarkupsApplyScreenContainer;
