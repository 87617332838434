export const MAX_AREA = 16777216;

const resizeImage = ({ width, height }, maximumPixels = MAX_AREA / 2) => {
  const requiredPixels = width * height;
  if (requiredPixels <= maximumPixels) return { width, height };

  const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels);
  return {
    width: Math.floor(width * scalar),
    height: Math.floor(height * scalar),
  };
};

export default resizeImage;
