import { AssetUnitType, DataCollectionEventNames } from "@jarvis/jweb-core";

export default class CDMAnalyticsService {
  constructor(publisher, appInstanceId, platform) {
    this._publisher = publisher;
    this._appInstanceId = appInstanceId;
    this.platform = platform;
    console.debug("Analytics: Initializing CDM");
  }

  sendEvent(eventName, cdmEventData) {
    const valveControllerMetadata = {
      assetUnit:
        this.platform === "mobile"
          ? AssetUnitType.mobile
          : AssetUnitType.desktop,
      appInstanceId: this._appInstanceId,
    };

    // Construct DataValveCDMEvent
    const eventData = {
      events: [cdmEventData],
      valveControllerMetadata,
    };

    // Publishes event
    this._publisher.publish(eventName, eventData).then((result) => {
      if (result instanceof Error) {
        console.error("Error while publishing analytics data", result);
        return;
      }
    });
  }

  buildCdmEventAndSend(event) {
    if (!event) return;

    const cdmEvent = {
      dateTime: new Date().toISOString(),
      eventDetailType:
        "com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1",
      eventCategory: "simpleUi",
      version: "1.3.0",
      eventDetail: event,
    };

    this.sendEvent(DataCollectionEventNames.cdmEvent, cdmEvent);
  }

  sendPageView(event) {
    this.buildCdmEventAndSend(event);
  }

  sendInternalEvent(event) {
    this.buildCdmEventAndSend(event);
  }

  sendLoginEvent() {
    //TODO: Implement cdm internal event?
  }
}
