/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DeviceRoute from "../components/DeviceRoute";
import MetaTagManager from "../components/MetaTagManager/MetaTagManager";
import { JarvisProvider } from "../contexts/Jarvis";
import { MediaManagerProvider } from "../contexts/MediaManager";
import { PlaceMarkProvider } from "../contexts/PlaceMark";
import { SessionProvider } from "../contexts/Session";
import useAnalytics from "../hooks/useAnalytics";
import useQuery from "../hooks/useQuery";
import { paths } from "../route.settings";
import "../style/style.css";
import Env from "../utils/env";
import DesktopMarkupsApplyScreenContainer from "./desktop/screens/DesktopMarkupsApplyScreenContainer";
import JarvisLoginContainer from "./JarvisLoginContainer";
import LoginContainer from "./LoginContainer";
import DocumentPageSelectionScreenContainer from "./shared/screens/DocumentPageSelectionScreenContainer";
import MarkupsApplyScreenContainer from "./mobile/screens/MarkupsApplyScreenContainer";
import MarkupsCreateScreenContainer from "./mobile/screens/MarkupsCreateScreenContainer";
import MarkupsListScreenContainer from "./mobile/screens/MarkupsListScreenContainer";
import DesktopMarkupsCreateScreenContainer from "./desktop/screens/DesktopMarkupsCreateScreenContainer";
import FetchMarksProvider from "../contexts/FetchMarks/FetchMarksProvider";
import { SidebarProvider } from "../contexts/Sidebar/SidebarContext";
import CoachmarkContainer from "./shared/CoachmarkContainer/CoachmarkContainer";

const Routes = () => {
  const { stack } = useQuery();
  const { initAnalytics } = useAnalytics(true);

  useEffect(() => {
    initAnalytics();
  }, []);

  Env.getEnv(stack);
  return (
    <Switch>
      <Route path={paths.DOCUMENT_SELECT}>
        <DocumentPageSelectionScreenContainer />
      </Route>
      <DeviceRoute
        path={paths.MARKUPS_CREATE}
        mobile={<MarkupsCreateScreenContainer />}
        desktop={<DesktopMarkupsCreateScreenContainer />}
      />
      <Route path={paths.MARKUPS_LIST}>
        <MarkupsListScreenContainer />
      </Route>
      <DeviceRoute
        path={paths.MARKUPS_APPLY}
        mobile={<MarkupsApplyScreenContainer />}
        desktop={<DesktopMarkupsApplyScreenContainer />}
      />
      <Route path={paths.LOGIN}>
        <LoginContainer />
      </Route>
      <Route exact path={paths.JARVIS_LOGIN}>
        <JarvisLoginContainer />
      </Route>
    </Switch>
  );
};

const AppContainer = () => {
  return (
    <JarvisProvider>
      <SessionProvider>
        <MetaTagManager />
        <SidebarProvider>
          <MediaManagerProvider>
            <PlaceMarkProvider>
              <FetchMarksProvider>
                <BrowserRouter>
                  <Routes />
                  <CoachmarkContainer />
                </BrowserRouter>
              </FetchMarksProvider>
            </PlaceMarkProvider>
          </MediaManagerProvider>
        </SidebarProvider>
      </SessionProvider>
    </JarvisProvider>
  );
};

export default AppContainer;
