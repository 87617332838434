/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button, IconChevronLeft, IconChevronRight } from "@veneer/core";
import CircularIndicator from "../CircularIndicator";
import { useMemo } from "react";
import { tokens } from "../../css.settings";

const rootStyle = css`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
`;

const pageContainerStyle = css`
  flex: 4;
  display: flex;
  flex-direction: column;
  max-width: 66vw;
  height: 100%;
  justify-content: center;
`;

const pageStyle = css`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const buttonContainerStyle = css`
  display: flex;
  flex: 1;
`;

const buttonLeftContainerStyle = css`
  justify-content: end;

  @media (max-width: ${tokens.layout.smMax}) {
    justify-content: start;
  }
`;

const buttonRightContainerStyle = css`
  justify-content: start;

  @media (max-width: ${tokens.layout.smMax}) {
    justify-content: end;
  }
`;

const buttonLeftStyle = css`
  @media (max-width: ${tokens.layout.smMax}) {
    justify-content: end;
  }
`;

const buttonRightStyle = css`
  @media (max-width: ${tokens.layout.smMax}) {
    justify-content: start;
  }
`;

const labelStyle = css`
  text-align: center;
  margin-top: ${tokens.layout.size4};
`;

const buttonElementStyle = css`
  width: 3rem;
  height: 3rem;

  @media (max-width: ${tokens.layout.smMax}) {
    justify-content: start;
  }
`;

const indicatorStyle = {
  position: "absolute",
};

const buttonIconStyle = {
  width: "2.5rem",
  height: "2.5rem",
};

const PageSelection = ({
  currentPage,
  numberOfPages,
  onClickNext,
  onClickPrev,
  canGoNext,
  canGoPrev,
  pageRef,
  pageContainerRef,
  loading = false,
  ready = false,
}) => {
  const currentPageIndicator = useMemo(
    () => `${currentPage}/${numberOfPages}`,
    [currentPage, numberOfPages]
  );

  const isLoading = useMemo(() => loading || !ready, [loading, ready]);

  return (
    <div data-testid="full-page-selection" css={rootStyle}>
      <div css={[buttonContainerStyle, buttonLeftContainerStyle]}>
        <Button
          disabled={!canGoPrev}
          onClick={onClickPrev}
          data-testid="prev-page-button"
          appearance="ghost"
          css={[buttonElementStyle, buttonLeftStyle]}
          leadingIcon={<IconChevronLeft style={buttonIconStyle} />}
        />
      </div>
      <div
        data-testid="current-page"
        ref={pageContainerRef}
        css={pageContainerStyle}
      >
        <div ref={pageRef} css={pageStyle}>
          {isLoading && (
            <CircularIndicator style={indicatorStyle} scale={1.5} />
          )}
        </div>
        {ready && (
          <span data-testid={"current-page-indicator"} css={labelStyle}>
            {currentPageIndicator}
          </span>
        )}
      </div>
      <div css={[buttonContainerStyle, buttonRightContainerStyle]}>
        <Button
          disabled={!canGoNext}
          onClick={onClickNext}
          data-testid="next-page-button"
          appearance="ghost"
          css={[buttonElementStyle, buttonRightStyle]}
          leadingIcon={<IconChevronRight style={buttonIconStyle} />}
        />
      </div>
    </div>
  );
};

export default PageSelection;
