import { useCallback, useMemo } from "react";
import { useMediaManagerContext } from "../contexts/MediaManager";
import { usePlaceMarkContext } from "../contexts/PlaceMark";

const useMediaControls = () => {
  const { media } = useMediaManagerContext();
  const {
    selectedPage,
    selectedDocument,
    setSelectedPage,
    setSelectedDocument,
  } = usePlaceMarkContext();

  const currentDocumentIndex = useMemo(() => {
    if (!media) return 0;

    return media.documents.indexOf(selectedDocument);
  }, [media, selectedDocument]);

  const updatePageNumber = useCallback(
    (currentPage, operation) => {
      const newPage = currentPage + operation;
      const { numPages } = selectedDocument;

      if (newPage > 0 && newPage <= numPages) {
        return newPage;
      }

      const newDocument = media.documents[currentDocumentIndex + operation];
      if (newDocument) {
        setSelectedDocument(newDocument);
        return operation > 0 ? 1 : newDocument.numPages;
      }

      return currentPage;
    },
    [media, selectedDocument, setSelectedDocument, currentDocumentIndex]
  );

  const nextPage = useCallback(() => {
    setSelectedPage((current) => updatePageNumber(current, +1));
  }, [setSelectedPage, updatePageNumber]);

  const prevPage = useCallback(() => {
    setSelectedPage((current) => updatePageNumber(current, -1));
  }, [setSelectedPage, updatePageNumber]);

  const globalSelectedPage = useMemo(() => {
    if (selectedDocument?.isImage()) {
      return media.documents.indexOf(selectedDocument) + 1;
    }
    return selectedPage;
  }, [selectedDocument, selectedPage, media]);

  const hasNextPage = useMemo(() => {
    if (!media) return false;

    const canChangeDocument = currentDocumentIndex + 1 < media.documents.length;
    const canChangePageNumber = globalSelectedPage + 1 <= media.numPages;

    return canChangeDocument || canChangePageNumber;
  }, [media, currentDocumentIndex, globalSelectedPage]);

  const hasPrevPage = useMemo(() => {
    if (!media) return false;

    const canChangeDocument = currentDocumentIndex - 1 >= 0;
    const canChangePageNumber = selectedPage - 1 > 0;

    return canChangeDocument || canChangePageNumber;
  }, [media, currentDocumentIndex, selectedPage]);

  const numPages = useMemo(() => media?.numPages, [media]);

  return {
    media,
    numPages,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
    selectedDocument,
    selectedPage,
    globalSelectedPage,
  };
};

export default useMediaControls;
