import MediaLoader from "./MediaLoader";
import DocumentMedia from "./DocumentMedia";
import { PDF_VIEWPORT_SCALE } from "../../constants";
import { importPdfJS } from "../../utils/pdfjs";
import { MAX_AREA, resizeImage } from "../../utils/image-utils";

const GENERIC_ID = 1;

export default class PdfMediaLoader extends MediaLoader {
  constructor(mimeType = "application/pdf") {
    super();
    this.mimeType = mimeType;
  }

  get targetMimeTypes() {
    return ["application/pdf"];
  }

  async findBestScale(documentInstance) {
    const firstPageViewport = (await documentInstance.getPage(1)).getViewport({
      scale: PDF_VIEWPORT_SCALE,
    });

    const { width } = resizeImage(
      {
        width: firstPageViewport.width,
        height: firstPageViewport.height,
      },
      MAX_AREA
    );

    const deltaScale = width / firstPageViewport.width;

    return PDF_VIEWPORT_SCALE * deltaScale;
  }

  async parseToMedia(data) {
    const options = {
      maxImageSize: Math.pow(1024),
    };

    const pdfjs = await importPdfJS();
    const pdfInstance = await pdfjs.getDocument({
      ...options,
      data: data,
    }).promise;

    const scale = await this.findBestScale(pdfInstance);

    return {
      content: pdfInstance,
      scale,
    };
  }

  async loadMedia(data) {
    const { content, scale } = await this.parseToMedia(data);
    const numPages = content.numPages;

    return new DocumentMedia({
      id: GENERIC_ID,
      mimeType: this.mimeType,
      content,
      scale,
      numPages,
    });
  }
}
