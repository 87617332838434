export class GreyAreaElement {
  constructor(parent, onClick) {
    this.parent = parent;
    this.onClick = onClick;
    this.node = document.createElement("div");
    this.node.style.position = "absolute";
    this.node.style.left = 0;
    this.node.style.right = 0;
    this.node.style.top = 0;
    this.node.style.bottom = 0;
    this.node.style.opacity = 0;
    this.node.addEventListener("click", this.onClick);
    this.parent.appendChild(this.node);
  }

  getDimensions() {
    const { width, height } = this.node.getBoundingClientRect();
    return { width, height };
  }

  onDestroy() {
    if (!this.parent.contains(this.node)) return;
    this.node.removeEventListener("click", this.onClick);
    this.parent.removeChild(this.node);
  }
}
