/* eslint-disable react-hooks/exhaustive-deps */
import { css } from "@emotion/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ActionButtons from "../../../components/ActionButtons";
import PageSelectionLayout from "../../../components/PageSelection/PageSelectionLayout";
import Screen from "../../../components/Screen";
import ZoomControls from "../../../components/ZoomControls";
import { ZOOM_BUTTON_FACTOR } from "../../../constants";
import { useFetchMarks } from "../../../contexts/FetchMarks";
import { useJarvisContext } from "../../../contexts/Jarvis";
import { usePlaceMarkContext } from "../../../contexts/PlaceMark";
import { useSessionContext } from "../../../contexts/Session";
import { useSidebarContext } from "../../../contexts/Sidebar/SidebarContext";
import useCallbackRequest from "../../../hooks/useCallbackRequest";
import useMediaControls from "../../../hooks/useMediaControls";
import useMergeMedia from "../../../hooks/useMergeMedia";
import { sendInternalEvent } from "../../../plugins/analytics";
import { refs } from "../../../plugins/analytics/refs";
import { paths } from "../../../route.settings";
import { useCoachmarksStore } from "../../../store";
import { Viewport } from "../../../utils/markup-placement-viewer/viewport";
import { ViewportEvents } from "../../../utils/markup-placement-viewer/viewport-events";
import FooterContainer from "../FooterContainer";
import MarkupTabs from "../MarkupTabsContainer";
import PageSelectionSidebarContainer from "../PageSelectionSidebarContainer/PageSelectionSidebarContainer";

const VIEWPORT_SIZE_RATIO = 0.9;

const actionButtonsStyle = (isSidebarOpen) => css`
  margin-right: ${isSidebarOpen ? "200px" : "0px"};
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
`;

const DesktopMarkupsApplyScreenContainer = () => {
  const { t } = useTranslation();
  const { hasNextPage, hasPrevPage, nextPage, prevPage } = useMediaControls();
  const { callbackSchema, callbackSessionId, redirectUrl } =
    useSessionContext();

  const showCoachmark = useCoachmarksStore((store) => store.showCoachmark);

  const sendCallbackData = useCallbackRequest(
    callbackSchema,
    callbackSessionId
  );
  const {
    undo,
    redo,
    canUndo,
    canRedo,
    numberOfPages,
    hasMarkups,
    placedMarks,
    placedMarksList,
    updateMarkPosition,
    removePlacedMark,
    selectedDocument,
    selectedPage,
  } = usePlaceMarkContext();

  const { replaceDocuments, closeInstance } = useJarvisContext();

  const { loading: loadingMerge, mergeResult, mergeMedia } = useMergeMedia();
  const { markups = [], isLoading: isLoadingMarks } = useFetchMarks();

  const history = useHistory();

  const viewerRef = useRef();
  const containerRef = useRef(null);

  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [canTrash, setCanTrash] = useState(false);
  const [isMarkMoved, setIsMarkMoved] = useState(false);
  const [isPageMoved, setIsPageMoved] = useState(false);
  const [zoom, setZoom] = useState(100);

  const { isSidebarOpen } = useSidebarContext();

  const hasMultiplePages = numberOfPages > 1;

  const adjustViewerSize = () => {
    if (!viewerRef.current) return;
    const bounds = containerRef.current.getBoundingClientRect();
    viewerRef.current.setWidth(bounds.width);
    viewerRef.current.setHeight(bounds.height);
  };

  const onTrash = () => {
    const viewer = viewerRef.current;
    const markup = viewer.activeComponent;
    removePlacedMark(markup.uid);
    sendInternalEvent(refs.MARKUP_PLACE_DELETE_MARK);
  };

  const onDone = async () => {
    if (isMarkMoved) {
      sendInternalEvent(refs.MARKUP_PLACE_ALIGN_MARK);
    }

    if (isPageMoved) {
      sendInternalEvent(refs.MARKUP_PLACE_ALIGN_PAGE);
    }
    sendInternalEvent(refs.MARKUP_PLACE_FINISH);
    await mergeMedia(placedMarksList, selectedDocument.mimeType);
  };

  const onUndo = () => {
    sendInternalEvent(refs.MARKUP_PLACE_HISTORY_UNDO);
    undo();
  };

  const onRedo = () => {
    sendInternalEvent(refs.MARKUP_PLACE_HISTORY_REDO);
    redo();
  };

  const redirectToUrl = (url) => {
    window.location.href = url;
  };

  const onSelectionUpdate = useCallback(
    (selectedComponent) => {
      setCanTrash(hasMarkups && selectedComponent.isMarkup);
    },
    [hasMarkups, placedMarks]
  );

  const onUpdateMarkScale = () => {
    setIsDirty(true);
  };

  const onZoomPlusClick = () => {
    viewerRef.current.applyZoomOnCenter(ZOOM_BUTTON_FACTOR);
  };

  const onZoomLabelClick = () => {
    viewerRef.current.applyScale();
    viewerRef.current.centerViewport();
  };

  const onZoomMinusClick = () => {
    viewerRef.current.applyZoomOnCenter(-ZOOM_BUTTON_FACTOR);
  };

  const onHistoryBack = () => {
    const instance = { resultData: { source: "backButton" } };
    closeInstance(instance);
    history.goBack();
  };

  const onCancel = () => {
    sendInternalEvent(refs.FOOTER_CANCEL_BUTTON);
    onHistoryBack();
  };

  const onViewPortMove = () => setIsPageMoved(true);
  const onMarkMove = () => setIsMarkMoved(true);

  useEffect(() => {
    if (isDirty) {
      sendInternalEvent(refs.MARKUP_PLACE_START_SCALING);
    }
  }, [isDirty]);

  useEffect(() => {
    if (selectedPage === null || !selectedDocument) {
      return history.replace(paths.DOCUMENT_SELECT);
    }

    setLoading(true);
    viewerRef.current = new Viewport(containerRef.current, {
      viewportRelativeRatio: VIEWPORT_SIZE_RATIO,
      bottomMargin: 0,
    });
    viewerRef.current.setMedia(selectedDocument, selectedPage);
    viewerRef.current
      .load()
      .then(() => viewerRef.current.render())
      .then(() => {
        setLoading(false);
        adjustViewerSize();
      });

    adjustViewerSize();

    return () => {
      viewerRef.current.onDestroy();
    };
  }, [selectedPage, selectedDocument]);

  useEffect(() => {
    if (!viewerRef.current) return;
    viewerRef.current.eventSystem.clear();
    viewerRef.current.eventSystem.on(
      ViewportEvents.SELECTION_UPDATE,
      onSelectionUpdate
    );
    viewerRef.current.eventSystem.on(
      ViewportEvents.VIEWPORT_MOVE,
      onViewPortMove
    );
    viewerRef.current.eventSystem.on(
      ViewportEvents.MARKUP_UPDATE,
      updateMarkPosition
    );
    viewerRef.current.eventSystem.on(
      ViewportEvents.MARKUP_SCALE,
      onUpdateMarkScale
    );
    viewerRef.current.eventSystem.on(ViewportEvents.MARKUP_MOVE, onMarkMove);
    viewerRef.current.eventSystem.on(ViewportEvents.VIEWPORT_SCALE, setZoom);
  }, [viewerRef, onSelectionUpdate]);

  useEffect(() => {
    if (!viewerRef.current || loading || isLoadingMarks || !markups) return;
    viewerRef.current.loadMarks(placedMarks);
    showCoachmark({
      numMarks: markups.length,
      placedMarks: placedMarks.length,
    });
  }, [markups, placedMarks, viewerRef, isLoadingMarks, loading]);

  useEffect(() => {
    (async () => {
      if (!mergeResult.data) {
        return;
      }

      if (redirectUrl) {
        sendCallbackData({
          signed_document_url: mergeResult.data,
        }).then(() => {
          redirectToUrl(redirectUrl);
        });
      } else if (callbackSchema) {
        const callbackURL = new URL(callbackSchema);
        const searchParams = callbackURL.searchParams;
        const encodedResult = encodeURIComponent(mergeResult);
        searchParams.set("signed_document_url", encodedResult);
        redirectToUrl(callbackURL.href);
      } else {
        await replaceDocuments(mergeResult.data);
        await closeInstance({ resultData: { source: "doneButton" } });
      }
    })();
  }, [mergeResult, callbackSchema]);

  useEffect(() => {
    adjustViewerSize();
    window.addEventListener("resize", adjustViewerSize);
    return () => {
      window.removeEventListener("resize", adjustViewerSize);
    };
  }, []);

  return (
    <Screen
      scrollable={false}
      title={t("page.apply_mark.title")}
      onHistoryBack={onHistoryBack}
      variant={"desktop"}
      bodyHeader={
        <ActionButtons
          canTrash={canTrash}
          canRedo={canRedo}
          canUndo={canUndo}
          onTrash={onTrash}
          onUndo={onUndo}
          onRedo={onRedo}
          variant={"desktop"}
          styles={actionButtonsStyle(isSidebarOpen)}
        />
      }
      body={
        <PageSelectionLayout
          loading={loading}
          prevButtonEnabled={hasPrevPage}
          nextButtonEnabled={hasNextPage}
          onClickNext={nextPage}
          onClickPrev={prevPage}
          containerRef={containerRef}
          hasMultiplePages={hasMultiplePages}
        />
      }
      bodyLeftSidebar={hasMultiplePages && <PageSelectionSidebarContainer />}
      bodyRightSidebar={<MarkupTabs />}
      footer={
        <FooterContainer
          onDone={onDone}
          onCancel={onCancel}
          doneDisabled={!hasMarkups || loadingMerge}
          children={
            <ZoomControls
              currentZoomValue={zoom}
              onMinusClick={onZoomMinusClick}
              onLabelClick={onZoomLabelClick}
              onPlusClick={onZoomPlusClick}
            />
          }
        />
      }
    />
  );
};

export default DesktopMarkupsApplyScreenContainer;
