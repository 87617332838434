import environments from "../environments.json";

const getCurrentURLDomain = () => {
  const { hostname } = new URL(window.location.href);
  return hostname;
};
export default class Env {
  constructor(environments, preferredEnv) {
    this.currentDomain = getCurrentURLDomain();
    if (preferredEnv) {
      Object.assign(this, environments[preferredEnv].values);
      console.log("Loading %s environment", preferredEnv);
    } else {
      this._loadEnvironment(Object.keys(environments), environments);
    }
  }

  _loadEnvironment(stacks, environments) {
    const matchedStack = stacks.find((stack) => {
      const { targetDomains } = environments[stack];
      const hasAnyMatch = targetDomains.some((expression) =>
        this._matchDomain(expression)
      );
      return hasAnyMatch;
    });
    if (!matchedStack) throw new Error("No matched stacks");
    this.currentStack = matchedStack;
    console.log("Loading %s environment", matchedStack);
    Object.assign(this, environments[matchedStack].values);
  }

  _matchDomain(expression) {
    if (expression.regex) {
      return new RegExp(expression.regex).test(this.currentDomain);
    } else {
      return expression.domain === this.currentDomain;
    }
  }

  static getEnv(preferredEnv) {
    if (!Env._instance) {
      Env._instance = new Env(environments, preferredEnv);
    }
    return Env._instance;
  }
}
