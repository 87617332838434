import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import FullScreenCircularIndicator from "./components/FullScreenCircularIndicator";
import AppContainer from "./containers/AppContainer";
import "./i18n";

ReactDOM.render(
  <Suspense fallback={<FullScreenCircularIndicator />}>
    <AppContainer />
  </Suspense>,
  document.getElementById("root")
);
