/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { tokens } from "../../css.settings";
import PageEditedIcon from "../icons/PageEditedIcon";

const THUMBNAIL_DEFAULT_SIZE = 168;

const introAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const thumbnailContainerStyle = (size) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${size}px;
  max-height: ${size}px;
  width: 80%;
  margin: 12px;
  background-color: ${tokens.color.white};
  border-radius: ${tokens.layout.cornerRadius4};
  box-sizing: border-box;
  cursor: pointer;
  border: 1px transparent solid;
  opacity: 0;
  animation: ${introAnimation} 200ms cubic-bezier(0.34, 1.56, 0.64, 1) forwards;

  :hover {
    background-color: ${tokens.color.gray1};
  }
`;

const animationDelay = (delay) => css`
  animation-delay: ${delay}ms;
`;

const selectedThumbnailContainerStyle = css`
  background-color: ${tokens.color.gray1};
  border-color: ${tokens.color.gray5};
`;

const thumbnailImageWrapper = css`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 60%;
  height: auto;
  max-height: 80%;
  margin: 8px;
`;
const thumbnailImageStyle = css`
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
`;

const pageNumberStyle = css`
  font-size: ${tokens.typography.size1};
`;

const thumbnailIndicatorWrapper = css`
  width: ${tokens.layout.size5};
  height: ${tokens.layout.size5};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${tokens.color.hpBlue6};
  border-radius: 100%;
  position: absolute;
  bottom: ${tokens.layout.size1};
  right: ${tokens.layout.size1};
`;

const PageThumbnail = ({
  source,
  pageNumber,
  imageRef,
  isSelected,
  isEdited,
  onClick,
  index,
  size = THUMBNAIL_DEFAULT_SIZE,
}) => {
  return (
    <div
      onClick={onClick}
      data-testid={`thumbnail-${index}`}
      css={[
        thumbnailContainerStyle(size),
        animationDelay(pageNumber * 50),
        isSelected ? selectedThumbnailContainerStyle : null,
      ]}
    >
      <div css={thumbnailImageWrapper}>
        {isEdited && (
          <div css={thumbnailIndicatorWrapper}>
            <PageEditedIcon />
          </div>
        )}
        <img
          ref={imageRef}
          alt={`document page ${pageNumber}`}
          draggable={false}
          src={source}
          css={thumbnailImageStyle}
        />
      </div>
      <span css={pageNumberStyle}>{pageNumber}</span>
    </div>
  );
};

export default PageThumbnail;
