/**
 * References the label/action object on data.js and cdm-data.js
 */
export const refs = {
  labels: {
    navitagion: "markup.navigation",
  },
  navigation: {
    GENERAL_NAVIGATION_BACK: (path) => `${refs.labels.navitagion}.${path}`,
  },
  PLAN: "PLAN",
  /**
   * MarkupApplyScreenContainer
   */
  MARKUP_PLACE_DELETE_MARK: "MARKUP_PLACE_DELETE_MARK",
  MARKUP_PLACE_FINISH: "MARKUP_PLACE_FINISH",
  MARKUP_PLACE_HISTORY_UNDO: "MARKUP_PLACE_HISTORY_UNDO",
  MARKUP_PLACE_HISTORY_REDO: "MARKUP_PLACE_HISTORY_REDO",
  MARKUP_PLACE_ADD_A_NEW_MARK: "MARKUP_PLACE_ADD_A_NEW_MARK",
  MARKUP_PLACE_NEW_MARK: "MARKUP_PLACE_NEW_MARK",
  MARKUP_PLACE_SAVED_MARKS: "MARKUP_PLACE_SAVED_MARKS",
  MARKUP_PLACE_MANAGE_MARKS: "MARKUP_PLACE_MANAGE_MARKS",
  MARKUP_PLACE_START_SCALING: "MARKUP_PLACE_START_SCALING",
  MARKUP_PLACE_ALIGN_PAGE: "MARKUP_PLACE_ALIGN_PAGE",
  MARKUP_PLACE_ALIGN_MARK: "MARKUP_PLACE_ALIGN_MARK",
  /**
   * MarkupCreateScreenContainer
   */
  MARKUP_CREATE_SAVE_THICKNESS_THIN: "MARKUP_CREATE_SAVE_THICKNESS_THIN",
  MARKUP_CREATE_SAVE_THICKNESS_MEDIUM: "MARKUP_CREATE_SAVE_THICKNESS_MEDIUM",
  MARKUP_CREATE_SAVE_THICKNESS_THICK: "MARKUP_CREATE_SAVE_THICKNESS_THICK",
  MARKUP_CREATE_SAVE_THICKNESS_TEXT: "MARKUP_CREATE_SAVE_THICKNESS_TEXT",
  MARKUP_CREATE_HISTORY_UNDO: "MARKUP_CREATE_HISTORY_UNDO",
  MARKUP_CREATE_HISTORY_DELETE: "MARKUP_CREATE_HISTORY_DELETE",
  MARKUP_CREATE_HISTORY_REDO: "MARKUP_CREATE_HISTORY_REDO",
  MARKUP_CREATE_ADD: "MARKUP_CREATE_ADD",
  MARKUP_CREATE_SAVE: "MARKUP_CREATE_SAVE",
  MARKUP_CREATE_START_DRAWING: "MARKUP_CREATE_START_DRAWING",
  MARKUP_SAVE_MARK_CHECKBOX_ENABLED: "MARKUP_SAVE_MARK_CHECKBOX_ENABLED",
  MARKUP_SAVE_MARK_CHECKBOX_DISABLED: "MARKUP_SAVE_MARK_CHECKBOX_DISABLED",
  /**
   * MarkupListScreenContainer
   */
  MARKUP_SELECT_EDIT: "MARKUP_SELECT_EDIT",
  MARKUP_SELECT_CANCEL: "MARKUP_SELECT_CANCEL",
  MARKUP_SELECT_ADD: "MARKUP_SELECT_ADD",
  MARKUP_SELECT_DELETE: "MARKUP_SELECT_DELETE",
  MARKUP_SELECT_LIST: "MARKUP_SELECT_LIST",
  /**
   * DesktopFooter
   */
  FOOTER_CANCEL_BUTTON: "FOOTER_CANCEL_BUTTON",
  FOOTER_DONE_BUTTON: "FOOTER_DONE_BUTTON",
  /**
   * PageSelection
   */
  PAGE_SELECT_TILES_VIEW: "PAGE_SELECT_TILES_VIEW",
  PAGE_SELECT_PAGE_VIEW: "PAGE_SELECT_PAGE_VIEW",
};
