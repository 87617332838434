/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button, Header as VeneerHeader, IconArrowLeft } from "@veneer/core";
import { forwardRef } from "react";
import { useHistory } from "react-router";
import { tokens } from "../../css.settings";
import { sendInternalEvent } from "../../plugins/analytics";
import { refs } from "../../plugins/analytics/refs";
import Title from "../Title";

const historyBackIconStyle = css`
  cursor: pointer;
  margin-right: ${tokens.layout.size5};
`;

const leftAreaStyle = css`
  display: flex;
  align-items: center;
`;

const customActionButtonStyle = css`
  &:disabled {
    color: #027aae;
    background-color: transparent;
    border-color: transparent;
    min-width: 80px;
    padding: calc(4px + 1px) calc(8px - 1px);
    opacity: 0.4;
  }
`;

const headerRootStyle = css`
  padding: 16px;
`;

const Header = forwardRef(
  (
    {
      title = "",
      headerName,
      actionButtonTitle = "",
      actionButtonDisabled = false,
      actionButtonLoading = false,
      onClickActionButton,
      onHistoryBack,
      style = null,
      ...rest
    },
    ref
  ) => {
    const history = useHistory();

    const onBackClick = () => {
      const routePaths = history.location.pathname.split("/").filter((p) => p);
      const navigationParams = headerName ? [headerName] : routePaths;
      const path = ["navigation", ...navigationParams].join(".");

      sendInternalEvent(refs.navigation.GENERAL_NAVIGATION_BACK(path));

      if (onHistoryBack) {
        onHistoryBack();
        return;
      }

      history.goBack();
    };

    const canClickButton = !actionButtonLoading && !actionButtonDisabled;
    return (
      <VeneerHeader
        data-testid="header"
        ref={ref}
        css={[headerRootStyle, style]}
        leadingArea={
          <div css={leftAreaStyle}>
            <IconArrowLeft
              data-testid="history-back-icon"
              css={historyBackIconStyle}
              onClick={onBackClick}
            />
            {title && <Title data-testid="header-title">{title}</Title>}
          </div>
        }
        trailingArea={
          actionButtonTitle && (
            <Button
              data-testid="action-button"
              data-coachmark="done-button"
              customStyle={customActionButtonStyle}
              disabled={actionButtonDisabled}
              loading={actionButtonLoading}
              onClick={(canClickButton && onClickActionButton) || null}
              appearance="ghost"
              small={true}
            >
              {actionButtonTitle}
            </Button>
          )
        }
        {...rest}
      >
        header
      </VeneerHeader>
    );
  }
);

export default Header;
