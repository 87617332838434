/* eslint-disable react-hooks/exhaustive-deps */
import { Coachmark } from "@veneer/core";
import { useShallow } from "zustand/react/shallow";
import { useLocation } from "react-router";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCoachmarksStore } from "../../../store";
import useDevice from "../../../hooks/useDevice";

const CoachmarkContainer = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isDesktop } = useDevice();

  const coachmark = useCoachmarksStore(
    useShallow((state) => ({
      current: state.current,
      activeStep: state.activeStep,
      onChangeStep: state.changeStep,
      setPlatform: state.setPlatform,
      updateLocation: state.updateLocation,
      onClose: state.close,
    }))
  );

  useEffect(() => {
    coachmark.setPlatform(isDesktop ? "desktop" : "mobile");
  }, []);

  useEffect(() => {
    coachmark.updateLocation(location.pathname);
  }, [location]);

  const { activeStep, current, onChangeStep, onClose } = coachmark;

  const translate = (value) => {
    if (Array.isArray(value)) {
      return value.map((item) => t(item)).join("\n");
    }
    return t(value);
  };

  const translatedSteps = useMemo(() => {
    return current?.steps.map((step) => ({
      ...step,
      title: translate(step.title),
      description: step.description ? translate(step.description) : undefined,
    }));
  }, [current]);

  if (!current || activeStep < 0) {
    return null;
  }

  const currentAnchor = current.steps[activeStep].anchorElement;

  return (
    <Coachmark
      data-testid="coachmark"
      closeButton={current?.isClosable}
      anchorElement={document.querySelector(currentAnchor)}
      placement={current.placement}
      steps={translatedSteps}
      closeOnScroll={false}
      activeStep={activeStep}
      onChange={(_, step) => onChangeStep(step)}
      onClose={onClose}
    />
  );
};

export default CoachmarkContainer;
