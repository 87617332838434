/** @jsxImportSource @emotion/react */
import CircularIndicator from "../CircularIndicator";

const fullScreenStyle = {
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const FullScreenCircularIndicator = () => {
  return (
    <div style={fullScreenStyle}>
      <CircularIndicator scale={1.5} />
    </div>
  );
};

export default FullScreenCircularIndicator;
