import { useState } from "react";

import Context from "./SessionContext";

const SessionProvider = ({ children, props = {} }) => {
  const [sessionId, setSessionId] = useState(null);
  const [callbackSessionId, setCallbackSessionId] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [callbackSchema, setCallbackSchema] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);
  const [langCountryCode, setLangCountryCode] = useState(null);

  const setSession = ({
    id,
    sessionToken,
    callbackSessionId,
    callbackSchema,
    langCountryCode,
    documentUrl,
    redirectUrl,
  }) => {
    setSessionId(id);
    setCallbackSessionId(callbackSessionId);
    setSessionToken(sessionToken);
    setCallbackSchema(callbackSchema);
    setLangCountryCode(langCountryCode.replace("-", "_"));
    setDocumentUrl(documentUrl);
    setRedirectUrl(redirectUrl);
  };

  const value = {
    sessionId,
    setSessionId,
    sessionToken,
    setSessionToken,
    callbackSchema,
    setCallbackSchema,
    documentUrl,
    setDocumentUrl,
    langCountryCode,
    setLangCountryCode,
    redirectUrl,
    setRedirectUrl,
    callbackSessionId,
    setCallbackSessionId,
    setSession,
    ...props,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default SessionProvider;
