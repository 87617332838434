export class InternalExtraData {
  constructor({ count }) {
    this.count = count;
  }
}

export class InternalEvent {
  static eventName = "event:internal";

  constructor({ label, extra, shouldSkipUdl = false }) {
    this.eventName = InternalEvent.eventName;
    this.label = label;
    this.extra = extra;
    this.shouldSkipUdl = shouldSkipUdl;
  }
}
