import { Rectangle } from "../../math";
import { IdleState } from "./idle-state";
import { ViewportEvents } from "../viewport-events";

export class TranslatingState {
  constructor(controller, cursorIcon) {
    this.controller = controller;
    this.cursorIcon = cursorIcon;
  }

  getCursorIcon(_mouseX, _mouseY) {
    return this.cursorIcon;
  }

  handle(transform) {
    const markup = this.controller.markup;
    const { width, height } = markup.viewport.element;
    const relativeX = transform.x / transform.viewportScale;
    const relativeY = transform.y / transform.viewportScale;
    const viewport = new Rectangle(0, 0, width, height);
    const nextPosition = markup.clone();

    nextPosition.transform({ x: relativeX });
    nextPosition.fitTo(viewport);
    if (nextPosition.isInside(viewport)) {
      markup.setX(nextPosition.x);
    } else {
      nextPosition.transform({ x: -relativeX });
    }

    nextPosition.transform({ y: relativeY });
    nextPosition.fitTo(viewport);
    if (nextPosition.isInside(viewport)) {
      markup.setY(nextPosition.y);
    }
  }

  handleStop() {
    this.controller.currentState = new IdleState(this.controller);
    this.controller.markup.viewport.eventSystem.emit(
      ViewportEvents.MARKUP_MOVE
    );
  }
}
