import { useSessionContext } from "../contexts/Session";
import { useEffect, useState } from "react";

import { request } from "../services/request";
import Env from "../utils/env";

const useApi = (resource, method = "GET", headers = {}) => {
  const { sessionToken } = useSessionContext();
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const _resourceWithParams = (params) => {
    if (!params.length) return resource;
    return resource + "/" + params.join("/");
  };

  const tryFetch = async (data, params) => {
    try {
      setIsFetching(true);
      setError(null);
      const resourceWithParams = _resourceWithParams(params);
      const responseData = await request({
        accessToken: sessionToken,
        resource: resourceWithParams,
        method,
        headers,
        data,
        url: Env.getEnv().SCRIBBLE_API_BASE_URL,
      });
      setData(responseData);
    } catch (err) {
      setError(err);
    } finally {
      setIsFetching(false);
    }
  };

  const startFetch = async (body, ...params) => {
    await tryFetch(body, params);
  };

  const hasSessionToken = !!sessionToken;

  useEffect(() => {
    setData(null);
    setIsFetching(false);
  }, []);

  return {
    isFetching,
    hasSessionToken,
    startFetch,
    error,
    data,
  };
};

export default useApi;
