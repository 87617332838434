/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaManagerContext } from "../contexts/MediaManager";
import { usePlaceMarkContext } from "../contexts/PlaceMark";
import { sendPlanEvent } from "../plugins/analytics";
import { getUserPlan } from "../services/user-plan";
import useSessionApi from "./useSessionApi";
import Env from "../utils/env";

const useSetup = ({ onError }) => {
  const { i18n } = useTranslation();
  const [loadingLocale, setLoadingLocale] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const { selectedDocument, setSelectedDocument, setNumberOfPages } =
    usePlaceMarkContext();
  const {
    media,
    loadMedia,
    parseMediaDocuments,
    loading: loadingMedia,
  } = useMediaManagerContext();

  const {
    getSession: loadSession,
    data: sessionData,
    loading: loadingSession,
    error: sessionError,
  } = useSessionApi();

  useEffect(() => {
    if (media) {
      setNumberOfPages(media.numPages);
      setSelectedDocument(media.firstDocument);
    }
  }, [media]);

  const applyAppFallbackLocale = async () => {
    const { SCRIBBLE_DEFAULT_LANGUAGE } = Env.getEnv();
    i18n.changeLanguage(SCRIBBLE_DEFAULT_LANGUAGE, (err) => {
      if (err) {
        onError();
      }
    });
  };

  const applyAppLocale = async (locale) => {
    setLoadingLocale(true);
    i18n.changeLanguage(locale, async (err) => {
      if (err) {
        await applyAppFallbackLocale();
      }
      setLoadingLocale(false);
    });
  };

  const sendUserPlanToAnalytics = async (accessToken) => {
    try {
      const plansInfo = await getUserPlan(accessToken);
      sendPlanEvent(plansInfo);
    } catch (error) {
      console.error("Error while fetching users plan info", error);
    }
  };

  useEffect(() => {
    setIsReady(!!sessionData && !loadingLocale && !loadingMedia);
  }, [sessionData, loadingLocale, loadingMedia]);

  return {
    selectedDocument,
    sessionData,
    sessionError,
    loadingLocale,
    loadingMedia,
    loadingSession,
    isReady,
    applyAppLocale,
    loadMedia,
    loadSession,
    parseMediaDocuments,
    sendUserPlanToAnalytics,
  };
};

export default useSetup;
