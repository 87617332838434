/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import ActionButtons from "../../../components/ActionButtons";
import CenteredCheckbox from "../../../components/CenteredCheckbox";
import Footer from "../../../components/Footer";
import Screen from "../../../components/Screen";
import SignaturePad from "../../../components/SignaturePad";
import StrokeThicknessButtons from "../../../components/StrokeThicknessButtons";
import useCreateMarkup from "../../../hooks/useCreateMarkup";

const strokeButtonsStyle = css`
  flex: 1;
  justify-content: center;
`;

const MarkupsCreateScreenContainer = () => {
  const { t } = useTranslation();

  const {
    bodyRef,
    registerCanvas,
    saveMarkup,
    onCheckboxChange,
    onSelectThin,
    onSelectMedium,
    onSelectThick,
    onSelectText,
    onFocus,
    onBlur,
    onTrash,
    onType,
    onUndo,
    onRedo,
    isTyping,
    isDoneDisabled,
    input,
    canUndo,
    canRedo,
    canTrash,
    loading,
    padSize,
    thickness,
  } = useCreateMarkup();

  return (
    <Screen
      scrollable={false}
      bodyRef={bodyRef}
      title={t("page.new_mark.title")}
      actionButtonTitle={t("common.save")}
      actionButtonLoading={loading}
      actionButtonDisabled={isDoneDisabled}
      onClickActionButton={saveMarkup}
      bodyHeader={
        <CenteredCheckbox
          data-testid="save-checkbox"
          data-coachmark="save-markup"
          label={t("page.new_mark.save_when_placing")}
          defaultChecked
          onChange={onCheckboxChange}
        />
      }
      bodyFooter={
        !isTyping && (
          <ActionButtons
            onUndo={onUndo}
            onRedo={onRedo}
            canUndo={canUndo}
            canRedo={canRedo}
            onTrash={onTrash}
            canTrash={canTrash}
          />
        )
      }
      body={
        <SignaturePad
          ref={registerCanvas}
          width={padSize.width}
          height={padSize.height}
          onUndo={onUndo}
          onRedo={onRedo}
          onType={onType}
          onFocus={onFocus}
          onBlur={onBlur}
          canUndo={canUndo}
          canRedo={canRedo}
          isTyping={isTyping}
          input={input}
        />
      }
      footer={
        <Footer>
          <StrokeThicknessButtons
            styles={{ root: strokeButtonsStyle }}
            onSelectThin={onSelectThin}
            onSelectMedium={onSelectMedium}
            onSelectThick={onSelectThick}
            onSelectText={onSelectText}
            thickness={thickness}
            isTyping={isTyping}
          />
        </Footer>
      }
    />
  );
};

export default MarkupsCreateScreenContainer;
