import MediaLoader from "./MediaLoader";
import { resizeImage, getImage } from "../../utils/image-utils";
import DocumentMedia from "./DocumentMedia";
import { IMAGE_VIEWPORT_SCALE } from "../../constants";
import { Buffer } from "buffer";

const GENERIC_ID = 1;

export default class ImageMediaLoader extends MediaLoader {
  constructor(mimeType) {
    super();
    this.mimeType = mimeType;
  }

  get targetMimeTypes() {
    return ["image/jpg", "image/jpeg", "image/png"];
  }

  async parseToMedia(base64Media) {
    const image = await getImage(base64Media);

    const newDim = resizeImage({
      width: image.width * IMAGE_VIEWPORT_SCALE,
      height: image.height * IMAGE_VIEWPORT_SCALE,
    });

    const newScale = newDim.width / image.width;
    image.width = newDim.width;
    image.height = newDim.height;

    return {
      content: image,
      scale: newScale,
    };
  }

  async loadMedia(data) {
    const encodedMedia = Buffer.from(data).toString("base64");
    const base64media = `data:${this.mimeType};base64,${encodedMedia}`;
    const { content, scale } = await this.parseToMedia(base64media);

    return new DocumentMedia({
      id: GENERIC_ID,
      mimeType: this.mimeType,
      content,
      scale,
    });
  }
}
