import { useState } from "react";
import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useSessionContext } from "../../contexts/Session";

const MetaTagManager = () => {
  const [countryCode, setCountryCode] = useState("US");
  const { langCountryCode } = useSessionContext();

  const setHtmlLang = (lang) => {
    document.documentElement.lang = lang;
  };

  useEffect(() => {
    if (langCountryCode) {
      const [lang, country] = langCountryCode.toLowerCase().split("_");
      setHtmlLang([lang, country].join("-"));
      setCountryCode(country || "us");
    }
  }, [langCountryCode]);

  return (
    <MetaTags>
      <title>Scribble Webapp</title>
      <meta name="target_country" content={countryCode} />
    </MetaTags>
  );
};

export default MetaTagManager;
