import { InternalEvent, UserLoginEvent } from "../events";

export class AnalyticsEventHandler {
  constructor(mediator, service, adapter) {
    this._mediator = mediator;
    this._service = service;
    this._adapter = adapter;
  }

  static init(mediator, service, adapter, targetEvents) {
    const handler = new AnalyticsEventHandler(
      mediator,
      service,
      adapter,
      targetEvents
    );
    handler.listenTo(targetEvents);
  }

  listenTo(eventNames) {
    eventNames.forEach((name) => {
      this._mediator.registerListener(name, this);
    });
  }

  handle(event) {
    if (event instanceof InternalEvent) {
      this._service.sendInternalEvent(this._adapter.adaptInternalEvent(event));
    } else if (event instanceof UserLoginEvent) {
      this._service.sendLoginEvent(this._adapter.adaptUserLoginEvent(event));
    } else {
      this._service.sendPageView(this._adapter.adaptPageViewEvent(event));
    }
  }
}
