import { create } from "zustand";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/local-storage";
import coachmarksMapping from "./coachmarksdata";

const saveCoachmarkToLocalStorage = (coachmarkId) => {
  const parsedData = getLocalStorageItem("coachmarks");
  parsedData[coachmarkId] = true;
  setLocalStorageItem("coachmarks", parsedData);
};

const useCoachmarksStore = create((set, get) => ({
  current: null,
  params: null,
  platform: "desktop",
  activeStep: -1,
  location: null,

  changeStep: (step = -1) => {
    set({ activeStep: step });
  },
  setPlatform: (platform) => {
    set({ platform });
  },
  close: () => {
    const { current } = get();
    saveCoachmarkToLocalStorage(current.id);
    set({ current: null, activeStep: -1 });
  },

  /**
   * Find and update the current coachmark with the one that met the requirements
   * @param {Boolean} checkAutoplay When true, will instantly show any coachmark having autoplay enabled
   */
  updateCoachmark() {
    const shownCoachmarks = getLocalStorageItem("coachmarks");
    const { current, platform, location, params } = get();
    const platformCoachmarks = coachmarksMapping[location]?.[platform] || {};

    let wasCandidateFound = false;
    const coachmark = platformCoachmarks?.coachmarks?.find((coachmarkItem) => {
      const { id, isRepeatable, requirement } = coachmarkItem;
      const wasShown = !!shownCoachmarks[id];
      const canShow = isRepeatable || !wasShown;
      const requirementFulfilled = requirement?.(params || {}) ?? true;

      if (canShow && !requirementFulfilled) wasCandidateFound = true;

      return canShow && requirementFulfilled;
    });

    if (!coachmark) {
      return set({
        current: null,
        activeStep: -1,
        params: wasCandidateFound ? params : null,
      });
    }

    if (current?.id === coachmark.id) {
      return;
    }

    let activeStep = params ? 0 : -1;

    if (platformCoachmarks?.autoPlay) {
      activeStep = 0;
    }

    set({
      current: coachmark,
      activeStep,
    });
  },

  /**
   * Updates the coarchmark location and show it when autoplay is enabled
   * @param {String} location
   */
  updateLocation: (location) => {
    const { updateCoachmark } = get();
    set({ location });
    updateCoachmark();
  },

  /**
   * Attempts to show a coachmark if the given parameters meet the requirements
   * @param {Object} params
   */
  showCoachmark(params = null) {
    const { updateCoachmark } = get();
    set({ params });
    updateCoachmark();
  },
}));

export default useCoachmarksStore;
