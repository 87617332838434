import { cleanUpCanvas } from "../canvas-utils";
export class BackgroundMediaElement {
  constructor(parent) {
    this.parent = parent;
    this.node = document.createElement("img");
    this.node.setAttribute("draggable", false);
    this.canvas = document.createElement("canvas");
    this.node.style.boxSizing = "border-box";
    this.node.style.position = "absolute";
    this.node.style.top = 0;
    this.node.style.left = 0;
    this.scale = 1;
    this._width = 0;
    this._height = 0;
  }

  render() {
    this.node.src = this.canvas.toDataURL("image/jpeg", 1.0);
    cleanUpCanvas(this.canvas);
  }

  set width(value) {
    this._width = value;
    this.node.style.width = `${value}px`;
    this.canvas.width = value;
  }

  set height(value) {
    this._height = value;
    this.node.style.height = `${value}px`;
    this.canvas.height = value;
  }
}
