const MAX_HEIGHT_PERCENT = 0.8;

export class StaticViewportElement {
  constructor(parent) {
    this.element = document.createElement("img");
    this.element.setAttribute("draggable", false);
    this.element.style.visibility = "hidden";
    this.element.style.border = "1px #c9c9c9 solid";
    this.element.style.cursor = "pointer";
    this.element.setAttribute("data-testid", "document-page");
    this.parent = parent;
    this.parent.appendChild(this.element);
  }

  getScale() {
    return Math.min(
      this.width / this.mediaWidth,
      (this.height * MAX_HEIGHT_PERCENT) / this.mediaHeight
    );
  }

  updateScale() {
    const scale = this.getScale();
    this.element.style.width = `${this.mediaWidth * scale}px`;
    this.element.style.height = `${this.mediaHeight * scale}px`;
  }

  setMediaSize(width, height) {
    this.mediaWidth = width;
    this.mediaHeight = height;
  }

  setSize(width, height) {
    this.width = width;
    this.height = height;
  }

  onClick(callbackFunction) {
    this.callbackFunction = callbackFunction;
    this.element.addEventListener("click", this.callbackFunction);
  }

  render(source) {
    this.element.src = source;
  }

  show() {
    this.element.style.visibility = "visible";
  }

  hide() {
    this.element.style.visibility = "hidden";
  }

  destroy() {
    if (this.callbackFunction) {
      this.element.removeEventListener("click", this.selectCallback);
    }
    this.parent.removeChild(this.element);
  }
}
