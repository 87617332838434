import { useEffect, useState } from "react";

import Env from "../utils/env";
import { request } from "../services/request";

const useSessionApi = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchSession = async (sessionId) => {
    setLoading(true);
    try {
      setError(null);
      const responseData = await request({
        sessionId: sessionId,
        url: `${Env.getEnv().SCRIBBLE_GET_SESSION_URL}/ssm/session`,
      });
      setData(responseData);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const getSession = async (sessionId) => {
    await fetchSession(sessionId);
  };

  useEffect(() => {
    setData(null);
    setLoading(false);
  }, []);

  return {
    loading,
    getSession,
    error,
    data,
  };
};

export default useSessionApi;
