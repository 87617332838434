/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import { Card, Checkbox } from "@veneer/core";
import { useState, useEffect } from "react";
import GridColumn from "../GridColumn";

import { tokens } from "../../css.settings";

const cardStyle = css`
  border-color: #cecece;
  border-radius: 8px;
`;

const contentStyle = css`
  padding: ${tokens.layout.size5};
  position: relative;
  cursor: pointer;
`;

const checkboxStyles = css`
  width: 16px;
  height: 16px;
  > span.vn-checkbox__span {
    width: 16px;
    height: 16px;
    > svg.vn-checkbox__span--icon {
      width: 15px;
      height: 15px;
    }
  }
`;

const checkboxContainerStyle = css`
  position: absolute;
  top: 5px;
  left: 5px;
`;

const GridListColumn = ({
  id,
  content,
  selectable = false,
  selected = false,
  onToggleSelection,
  onClick,
  customStyle,
  ...rest
}) => {
  const [checked, setChecked] = useState(false);

  const handleSelectionClick = () => {
    if (!selectable) {
      if (onClick) onClick({ id });
    } else {
      setChecked(!checked);
      onToggleSelection(id);
    }
  };

  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  return (
    <div style={customStyle}>
      <GridColumn
        {...rest}
        style={customStyle}
        data-testid={rest["data-testid"] || "grid-list-column"}
      >
        <Card
          css={cardStyle}
          content={
            <div
              css={contentStyle}
              onClick={handleSelectionClick}
              data-testid="grid-list-column-content"
            >
              {selectable && (
                <div css={checkboxContainerStyle}>
                  <Checkbox
                    css={checkboxStyles}
                    checked={checked}
                    data-testid="grid-list-column-checkbox"
                  />
                </div>
              )}
              <div>{content}</div>
            </div>
          }
        />
      </GridColumn>
    </div>
  );
};

export default GridListColumn;
