import Env from "../utils/env";
import { request } from "./request";

export const getUserPlan = async (authToken) => {
  return request({
    url: Env.getEnv()["SCRIBBLE_GET_SESSION_URL"],
    resource: "/ssm/plan",
    authToken,
  });
};
