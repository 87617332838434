/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";

export default function useVisible(ref) {
  const [isVisible, setIsVisible] = useState(false);

  const observer = useMemo(() => {
    return new IntersectionObserver(([entry]) =>
      setIsVisible(entry.isIntersecting)
    );
  }, [ref]);

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isVisible;
}
