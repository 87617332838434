/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import Grid from "../Grid";
import GridListRow from "../GridListRow";

const rootStyle = css`
  height: 100%;
  width: 100%;
`;

const GridList = ({
  items = [],
  colsSmall,
  colsMedium,
  colsLarge,
  ...rest
}) => {
  return (
    <Grid {...rest} style={rootStyle}>
      <GridListRow
        items={items}
        colsSmall={colsSmall}
        colsMedium={colsMedium}
        colsLarge={colsLarge}
      />
    </Grid>
  );
};

export default GridList;
