/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button as VeneerButton } from "@veneer/core";

import { tokens } from "../../css.settings";

export const rootStyle = () => css`
  border-radius: 50%;
  border-color: ${tokens.color.gray3};
  margin-left: ${tokens.layout.size1};
  margin-right: ${tokens.layout.size1};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  > button {
    border-radius: 50%;
    border-color: ${tokens.color.gray3};
    background-color: ${tokens.color.gray0};
    color: ${tokens.color.gray7};

    &:hover {
      background-color: ${tokens.color.gray0};
    }

    &[disabled] {
      color: ${tokens.color.gray4};
    }
  }
`;

const IconButton = ({ label = "", styles = {}, ...rest }) => {
  return (
    <label
      css={[rootStyle, styles.root]}
      data-coachmark={rest["data-coachmark"]}
      data-testid={rest["data-testid"] || "icon-button"}
    >
      <VeneerButton {...rest} css={[styles.button]} data-testid={null} />
      {label && <span>{label}</span>}
    </label>
  );
};

export default IconButton;
