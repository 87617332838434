import { paths } from "../../../route.settings";
import { action, activity, cdmData } from "../cdm-data";
import { refs } from "../refs";

export class CDMEventAdapter {
  constructor(serviceOptions) {
    this.serviceOptions = serviceOptions;
  }

  static _mapScreenName(route) {
    return {
      [paths.DOCUMENT_SELECT]: "SelectYourPage",
      [paths.JARVIS_LOGIN]: "LoginPage",
      [paths.MARKUPS_APPLY]: "PlaceYourMark",
      [paths.MARKUPS_CREATE]: "AddNewMark",
      [paths.MARKUPS_LIST]: "ManageMarks",
    }[route];
  }

  adaptInternalEvent({ label, extra }) {
    let data = cdmData[label];

    if (label.includes(refs.labels.navitagion)) {
      data = cdmData.navigation.GENERAL_NAVIGATION_BACK;
    }

    const { preset, source } = this.serviceOptions;
    const pathname = new URL(window.location).pathname;
    const screenName = CDMEventAdapter._mapScreenName(pathname);
    const internalEvent = {
      screenName,
      screenPath: `/${preset}/Scribble/`,
      screenMode: source,
      version: "1.3.0",
      controlDetail: data.adaptControlDetail
        ? JSON.stringify(data.adaptControlDetail(extra))
        : data?.controlDetail,
      controlName: data.controlName,
      activity: data.activity,
      action: data.action,
    };
    return internalEvent;
  }

  adaptPageViewEvent(event) {
    const pathName = new URL(window.location).pathname;
    const { preset, source } = this.serviceOptions;
    const screenName = CDMEventAdapter._mapScreenName(pathName);
    return {
      screenName,
      screenMode: source,
      screenPath: `/${preset}/Scribble/`,
      activity: activity.scanV01,
      version: "1.3.0",
      action: action.screenDisplayed,
    };
  }

  adaptUserLoginEvent(event) {}
}
