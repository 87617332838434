import { Renderable } from "./renderable";
import { BackgroundMediaElement } from "./background-media-element";

export class BackgroundMedia extends Renderable {
  constructor(mediaRenderer, parentElement, onClick) {
    super();

    this.element = new BackgroundMediaElement(this);
    this.mediaViewportScale = mediaRenderer.viewportScale;

    this.parentElement = parentElement;
    this.parentElement.appendChild(this.element.node);

    this.mediaRenderer = mediaRenderer;

    this.onClick = onClick;
    this._onClick = this._onClick.bind(this);
    this.setupEvents();
  }

  _onClick() {
    this.onClick();
  }

  setupEvents() {
    this.element.node.addEventListener("click", this._onClick);
  }

  async load() {
    await this.mediaRenderer.loadMedia();
    const { width, height } = this.mediaRenderer;
    this.setWidth(width);
    this.setHeight(height);
  }

  async render() {
    await this.mediaRenderer.render(this.element.canvas);
    this.element.render();
  }

  get scaledWidth() {
    return this.width / this.mediaViewportScale;
  }

  get scaledHeight() {
    return this.height / this.mediaViewportScale;
  }

  onDestroy() {
    if (!this.parentElement.contains(this.element.node)) return;
    this.parentElement.removeChild(this.element.node);
    this.element.node.removeEventListener("click", this._onClick);
  }
}
