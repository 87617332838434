import Hammer from "hammerjs";
import {
  ZOOM_WHEEL_FACTOR,
  PINCH_WAIT_TIME,
  PAN_WAIT_TIME,
} from "../../constants";

export class ViewportController {
  constructor(viewportComponent, containerElement) {
    this.viewportComponent = viewportComponent;
    this.containerElement = containerElement;

    this.hammer = new Hammer(this.containerElement);
    this.hammer.get("pinch").set({ enable: true });
    this.hammer.get("pan").set({ direction: Hammer.DIRECTION_ALL });
    this.lastPinchTime = 0;
    this.lastPanTime = 0;
    this.lastX = 0;
    this.lastY = 0;
    this.mouseX = 0;
    this.mouseY = 0;
    this.lastScale = 0;

    this._onPanStart = this._onPanStart.bind(this);
    this._onPanMove = this._onPanMove.bind(this);
    this._onPanEnd = this._onPanEnd.bind(this);
    this._onPinchStart = this._onPinchStart.bind(this);
    this._onPinch = this._onPinch.bind(this);
    this._onPinchEnd = this._onPinchEnd.bind(this);
    this._onMouseMove = this._onMouseMove.bind(this);
    this._onWheel = this._onWheel.bind(this);
  }

  enableControl() {
    this.hammer.on("panstart", this._onPanStart);
    this.hammer.on("panmove", this._onPanMove);
    this.hammer.on("panend", this._onPanEnd);
    this.hammer.on("pinchstart", this._onPinchStart);
    this.hammer.on("pinch", this._onPinch);
    this.hammer.on("pinchend", this._onPinchEnd);
    this.containerElement.addEventListener("wheel", this._onWheel);
    this.containerElement.addEventListener("mousemove", this._onMouseMove);
  }

  disableControl() {
    this.hammer.off("panstart", this._onPanStart);
    this.hammer.off("panmove", this._onPanMove);
    this.hammer.off("panend", this._onPanEnd);
    this.hammer.off("pinchstart", this._onPinchStart);
    this.hammer.off("pinch", this._onPinch);
    this.hammer.off("pinchend", this._onPinchEnd);
    this.containerElement.removeEventListener("wheel", this._onWheel);
    this.containerElement.removeEventListener("mousemove", this._onMouseMove);
  }

  /* This wait time is required to avoid accidental pan movement while finishing the pinch */
  canMove() {
    return Date.now() > this.lastPinchTime + PINCH_WAIT_TIME;
  }

  canClick() {
    return Date.now() > this.lastPanTime + PAN_WAIT_TIME;
  }

  _updateMousePosition(screenX, screenY) {
    const viewportArea = this.viewportComponent.greyElement.node;
    const { left, top } = viewportArea.getBoundingClientRect();
    this.mouseX = screenX - left;
    this.mouseY = screenY - top;
  }

  _onPanStart(e) {
    if (!this.canMove()) return;
    this.lastX = e.center.x - e.deltaX;
    this.lastY = e.center.y - e.deltaY;
    this._onPanMove(e);
  }
  _onPanMove(e) {
    if (!this.canMove()) return;
    this.viewportComponent.transformActiveElement({
      type: "translation",
      x: e.center.x - this.lastX,
      y: e.center.y - this.lastY,
      mouseX: this.lastX,
      mouseY: this.lastY,
    });
    this.lastX = e.center.x;
    this.lastY = e.center.y;
  }
  _onPanEnd(e) {
    if (!this.canMove()) return;
    this.lastX = e.center.x;
    this.lastY = e.center.y;
    this.viewportComponent.transformEndActiveElement({
      type: "translation",
      cancelled: false,
    });
    this.lastPanTime = Date.now();
  }

  _onPinchStart(e) {
    this.lastScale = e.scale;
    this.lastX = e.center.x;
    this.lastY = e.center.y;
    this._onPinch(e);
  }
  _onPinch(e) {
    this.viewportComponent.transformActiveElement({
      type: "scale",
      source: "pinch",
      centerX: this.lastX,
      centerY: this.lastY,
      relative: true,
      scale: e.scale - this.lastScale,
    });
    this.lastScale = e.scale;
  }
  _onPinchEnd(e) {
    this.lastX = e.center.x;
    this.lastY = e.center.y;
    this.viewportComponent.transformEndActiveElement({
      type: "scale",
      cancelled: false,
    });
    this.lastPinchTime = Date.now();
  }

  _onWheel(e) {
    this.viewportComponent.transformActiveElement({
      type: "scale",
      source: "scroll",
      centerX: this.mouseX || e.clientX,
      centerY: this.mouseY || e.clientY,
      relative: true,
      scale: Math.sign(-e.deltaY) * ZOOM_WHEEL_FACTOR,
    });
    this.lastScale = e.scale;
    this.viewportComponent.transformEndActiveElement({
      type: "scale",
      cancelled: false,
    });
  }

  _onMouseMove(e) {
    this.viewportComponent.onMouseMove(e.clientX, e.clientY);
    this._updateMousePosition(e.clientX, e.clientY);
  }
}
