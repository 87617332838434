export const importPdfJS = () => {
  if (window.pdfjsLib) {
    return window.pdfjsLib;
  }

  return new Promise((resolve, reject) => {
    const pdfLibScript = document.createElement("script");
    pdfLibScript.async = true;
    pdfLibScript.src =
      "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.2.228/build/pdf.js";
    document.head.appendChild(pdfLibScript);
    pdfLibScript.addEventListener("load", () => {
      window.pdfjsLib.GlobalWorkerOptions.workerSrc =
        "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.2.228/build/pdf.worker.js";
      resolve(window.pdfjsLib);
    });
    pdfLibScript.addEventListener("error", () => reject());
  });
};
