/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button } from "@veneer/core";
import { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { tokens } from "../../css.settings";
import { sendInternalEvent } from "../../plugins/analytics";
import { refs } from "../../plugins/analytics/refs";
import { paths } from "../../route.settings";
import CircularIndicator from "../CircularIndicator";
import GridList from "../GridList";

const rootStyle = css`
  padding: 0 ${tokens.layout.size4};
  height: 100%;
`;

const wrapperStyle = css`
  padding: ${tokens.layout.size4} ${tokens.layout.size2};
`;

const buttonStyle = css`
  margin: ${tokens.layout.size2} 0;
`;

const textStyle = css`
  margin: ${tokens.layout.size2} 0;
`;

const MarkupList = (
  {
    items = [],
    isLoading,
    selectable,
    columns,
    onClick,
    onToggleSelection,
    styles = {},
    onClickNewMark,
  },
  ref
) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onNewMarkClick = () => {
    sendInternalEvent(refs.MARKUP_PLACE_ADD_A_NEW_MARK);

    if (onClickNewMark) {
      return onClickNewMark();
    }

    history.push(paths.MARKUPS_CREATE);
  };

  const newItems = useMemo(
    () =>
      (items || []).map((item) => ({
        ...item,
        selectable,
        onToggleSelection,
        onClick,
      })),
    [items, selectable, onToggleSelection, onClick]
  );

  return (
    <div ref={ref} data-testid="markup-list" css={[rootStyle, styles.root]}>
      {!isLoading && !items.length && (
        <div css={wrapperStyle}>
          <Button
            data-testid="markup-list-no-items-button"
            css={buttonStyle}
            appearance="secondary"
            expanded={true}
            onClick={onNewMarkClick}
          >
            {t("page.saved_marks.add_new_marks")}
          </Button>
          <p data-testid="markup-list-no-items-text" css={textStyle}>
            {t("page.saved_marks.no_marks")}
          </p>
        </div>
      )}
      {isLoading ? (
        <CircularIndicator scale={1.5} />
      ) : (
        <GridList
          items={newItems}
          colsSmall={columns}
          colsMedium={columns}
          colsLarge={columns}
        />
      )}
    </div>
  );
};

export default forwardRef(MarkupList);
