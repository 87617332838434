import { SignaturePadExtension as SigPad } from "../../../utils/signature-pad-extension";
import { useState, useRef, useEffect, useMemo } from "react";

import debounce from "../../../utils/debounce";

import { THICKNESS_SIZES } from "../constants";
import SignatureUtil from "../../../utils/signature-util";

import { useSessionContext } from "../../../contexts/Session";

const useSignaturePad = ({
  initialData = [],
  initialThickness = THICKNESS_SIZES.thin.name,
  onDataUpdate = () => {},
}) => {
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [canTrash, setCanTrash] = useState(false);
  const [thickness, setThickness] = useState(initialThickness);
  const [data, setData] = useState(initialData);
  const [redoData, setRedoData] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const { sessionToken } = useSessionContext();

  const canSave = useMemo(() => {
    return (isTyping && !!input) || canUndo;
  }, [isTyping, input, canUndo]);

  const canvasRef = useRef(null);
  const sigPadRef = useRef(null);

  const registerCanvas = ($el) => {
    canvasRef.current = $el;
  };

  const getSignatureAsSVG = async () => {
    return isTyping
      ? SignatureUtil.textToSvg(input, sessionToken)
      : SignatureUtil.pathsToSVG(data, THICKNESS_SIZES[thickness].value);
  };

  const undo = () => {
    const end = Math.max(0, data.length - 1);
    setData([...data.slice(0, end)]);
  };

  const redo = () => {
    setData([...data.concat(redoData.slice(data.length, data.length + 1))]);
  };

  const onEnd = () => {
    setData([...sigPadRef.current._data]);
    setRedoData([...sigPadRef.current._data]);
    setIsEdited(true);
  };

  const trash = () => {
    setData(initialData);
    setRedoData([]);
    setIsEdited(false);
    setIsTyping(false);
  };

  const onResize = debounce(() => {
    sigPadRef.current.fromData(sigPadRef.current._data);
  });

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    sigPadRef.current = new SigPad(canvasRef.current);
    sigPadRef.current.onEnd = onEnd;

    setTimeout(() => {
      sigPadRef.current.fromData(data);
      window.addEventListener("resize", onResize);
    }, 300);
    return () => {
      sigPadRef.current.off();
      window.removeEventListener("resize", onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!sigPadRef.current) {
      return;
    }
    sigPadRef.current.fromData(data);
    onDataUpdate(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!sigPadRef.current || !THICKNESS_SIZES[thickness]) {
      return;
    }
    sigPadRef.current.maxWidth = THICKNESS_SIZES[thickness].value;
    sigPadRef.current.fromData(data);
  }, [thickness, data]);

  useEffect(() => {
    setCanUndo(isEdited && data.length > 0);
  }, [data, isEdited]);

  useEffect(() => {
    setCanRedo(isEdited && redoData.length > data.length);
  }, [redoData, data, isEdited]);

  useEffect(() => {
    setCanTrash(isEdited);
  }, [redoData, data, isEdited]);

  useEffect(() => {
    setInput("");
    setData(initialData);
    setRedoData([]);
    setIsEdited(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTyping]);

  return {
    getSignatureAsSVG,
    undo,
    canUndo,
    redo,
    canRedo,
    thickness,
    isTyping,
    data,
    input,
    setThickness,
    setIsTyping,
    setInput,
    registerCanvas,
    trash,
    canTrash,
    canSave,
  };
};

export default useSignaturePad;
