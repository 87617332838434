export const THICKNESS_SIZES = {
  thin: {
    name: "thin",
    value: 1,
  },
  medium: {
    name: "medium",
    value: 3,
  },
  thick: {
    name: "thick",
    value: 5,
  },
};
