/* eslint-disable prettier/prettier */
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useRef, useEffect } from "react";
import useDevice from "../../hooks/useDevice";

import Header from "../Header";

import { tokens } from "../../css.settings";
import { BREAKPOINT_LEFT_SIDEBAR_VISIBLE } from "../../constants";

const rootStyle = css`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

const bodyWrapperStyle = css`
  box-sizing: border-box;
  overflow: hidden;
  flex: 1;
  display: flex;
`;

const bodyStyle = css`
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  background-color: ${tokens.color.gray0};
`;

const bodyContainerStyle = ({ scrollable, variant }) => css`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: ${variant === "mobile" ? "static" : "relative"};
  padding: 12px;
  overflow-x: hidden;
  overflow-y: ${(scrollable && "auto") || "hidden"};
`;

const bodyContentStyle = css`
  flex: 1;
`;

const bodyHeaderWrapperStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`;

const bodyRightSidebarWrapperStyle = css``;

const bodyLeftSidebarWrapperStyle = css`
  max-width: 192px;
  width: 15vw;
  padding: 12px;
  transition: width 500ms ease;

  @media (max-width: ${BREAKPOINT_LEFT_SIDEBAR_VISIBLE}px) {
    width: 0;
  }
`;

const bodyFooterWrapperStyle = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
`;

const bodyHeaderContainerStyle = css`
  padding: ${tokens.layout.size5};
  display: flex;
  align-items: center;
`;

const bodyFooterContainerStyle = css`
  padding: ${tokens.layout.size5};
`;

const footerWrapperStyle = css`
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
`;

const headerVariantStyle = {
  desktop: css`
    border-bottom: 1px ${tokens.color.gray3} solid;
  `,
  mobile: css`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
  `,
};

const viewport = window.visualViewport || {};
const visualViewportHeightOriginal = viewport.height;

const Screen = ({
  title,
  screenName,
  actionButtonTitle,
  actionButtonDisabled,
  actionButtonLoading,
  onClickActionButton,
  onHistoryBack,
  scrollable = true,
  body,
  footer,
  bodyHeader,
  bodyRightSidebar,
  bodyLeftSidebar,
  bodyFooter,
  children,
  bodyRef,
  variant = "mobile",
  styles = {},
}) => {
  const bodyWrapperRef = useRef();
  const bodyContainerRef = useRef();
  const bodyRef_ = useRef();
  const bodyContentRef = useRef();
  const bodyHeaderWrapperRef = useRef();
  const bodyRightSidebarWrapperRef = useRef();
  const bodyLeftSidebarWrapperRef = useRef();
  const bodyFooterWrapperRef = useRef();
  const headerWrapperRef = useRef();
  const footerWrapperRef = useRef();

  const { isIos } = useDevice();

  const visualViewportHeight = viewport.height;

  const adjustBodyDimensions = () => {
    const webviewHeight = visualViewportHeight - viewport.height;

    const headerWrapperRect = headerWrapperRef.current.getBoundingClientRect();
    const footerWrapperRect =
      footerWrapperRef?.current?.getBoundingClientRect();

    bodyWrapperRef.current.style.paddingTop = `${headerWrapperRect.height}px`;

    bodyWrapperRef.current.style.paddingBottom = `${
      footerWrapperRect?.height || 0
    }px`;

    const computedStyles = window.getComputedStyle(bodyRef_.current);

    const bodyPadding = {
      left: computedStyles.getPropertyValue("padding-left").replace("px", ""),
      top: computedStyles.getPropertyValue("padding-top").replace("px", ""),
      right: computedStyles.getPropertyValue("padding-right").replace("px", ""),
      bottom: computedStyles
        .getPropertyValue("padding-bottom")
        .replace("px", ""),
    };

    const maxBoddyPadding = Number(
      Math.max(
        bodyPadding.left,
        bodyPadding.top,
        bodyPadding.right,
        bodyPadding.bottom
      )
    );

    const bodyWidth = bodyWrapperRef.current.offsetWidth - maxBoddyPadding * 2;

    const bodyHeight =
      (isIos ? webviewHeight : window.innerHeight) -
      (headerWrapperRect.height || 0) -
      (footerWrapperRect?.height || 0) -
      maxBoddyPadding * 2;

    bodyRef_.current.style.padding = `${maxBoddyPadding}px`;
    bodyRef_.current.style.width = `${bodyWidth}px`;
    bodyRef_.current.style.height = `${bodyHeight}px`;
  };

  const adjustFooterPosition = () => {
    if (footerWrapperRef && footerWrapperRef.current) {
      const webviewHeight = visualViewportHeightOriginal - viewport.height;
      footerWrapperRef.current.style.bottom = `${webviewHeight}px`;
    }
  };

  useEffect(() => {
    adjustBodyDimensions();
    window.addEventListener("resize", adjustBodyDimensions);

    if (isIos) {
      adjustFooterPosition();
      window.visualViewport.addEventListener("resize", adjustFooterPosition);
    }

    return () => {
      window.removeEventListener("resize", adjustBodyDimensions);
      if (isIos)
        window.visualViewport.addEventListener("resize", adjustFooterPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    adjustBodyDimensions();
    if (isIos) {
      adjustFooterPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body, footer, children, bodyHeader, bodyFooter]);

  return (
    <div css={rootStyle}>
      <Header
        ref={headerWrapperRef}
        title={title}
        headerName={screenName}
        style={headerVariantStyle[variant]}
        actionButtonTitle={actionButtonTitle}
        actionButtonLoading={actionButtonLoading}
        actionButtonDisabled={actionButtonDisabled}
        onClickActionButton={onClickActionButton}
        onHistoryBack={onHistoryBack}
      />
      <div ref={bodyWrapperRef} css={bodyWrapperStyle}>
        <main ref={bodyRef_} css={[styles.body, bodyStyle]}>
          {bodyLeftSidebar && (
            <div
              ref={bodyLeftSidebarWrapperRef}
              css={[bodyLeftSidebarWrapperStyle]}
            >
              {bodyLeftSidebar}
            </div>
          )}
          <div
            ref={bodyContainerRef}
            css={bodyContainerStyle({ scrollable, variant })}
          >
            {bodyHeader && (
              <div ref={bodyHeaderWrapperRef} css={bodyHeaderWrapperStyle}>
                <div css={bodyHeaderContainerStyle}>{bodyHeader}</div>
              </div>
            )}
            <div
              ref={($el) => {
                bodyContentRef.current = $el;
                if (bodyRef) {
                  bodyRef.current = $el;
                }
              }}
              css={bodyContentStyle}
            >
              {body || children}
            </div>
            {bodyFooter && (
              <div
                ref={bodyFooterWrapperRef}
                css={[bodyFooterWrapperStyle, styles.bodyFooter]}
              >
                <div css={bodyFooterContainerStyle}>{bodyFooter}</div>
              </div>
            )}
          </div>
          {bodyRightSidebar && (
            <div
              ref={bodyRightSidebarWrapperRef}
              css={[bodyRightSidebarWrapperStyle]}
            >
              {bodyRightSidebar}
            </div>
          )}
        </main>
      </div>
      {footer && (
        <div ref={footerWrapperRef} css={[footerWrapperStyle]}>
          {footer}
        </div>
      )}
    </div>
  );
};

export default Screen;
