/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useMediaManagerContext } from "../contexts/MediaManager";
import { usePlaceMarkContext } from "../contexts/PlaceMark";
import { PageThumbnailGenerator } from "../utils/markup-placement-viewer/page-thumbnail-generator";

const THUMBNAIL_QUALITY = 2 / 100;

const useThumbnailGenerator = () => {
  const { placedMarksList, selectedDocument, selectedPage } =
    usePlaceMarkContext();
  const { media } = useMediaManagerContext();
  const [thumbnailsData, setThumbnailsData] = useState([]);

  const isPageEdited = (document, page) => {
    return placedMarksList.some(
      (mark) => mark.document === document && mark.page === page
    );
  };

  const thumbnails = useMemo(
    () =>
      thumbnailsData.map((thumbnail) => {
        const { page, globalPageIndex, content, mediaDocument } = thumbnail;
        const isEdited = isPageEdited(mediaDocument, page);
        const isSelected =
          mediaDocument === selectedDocument && page === selectedPage;

        return {
          page,
          globalPageIndex,
          content,
          mediaDocument,
          isEdited,
          isSelected,
        };
      }),
    [thumbnailsData, placedMarksList, selectedDocument, selectedPage]
  );

  useEffect(() => {
    const thumbGenerator = new PageThumbnailGenerator({
      multiDocumentMedia: media,
      thumbnailQuality: THUMBNAIL_QUALITY,
      onFinishRendering: setThumbnailsData,
    });
    thumbGenerator.render();
  }, [media]);

  return {
    thumbnails,
  };
};

export default useThumbnailGenerator;
