import { cleanUpCanvas } from "../canvas-utils";
import { makeRenderer } from "./renderers/renderer-factory";
import { StaticViewportElement } from "./static-viewport-element.js";

export class StaticViewport {
  constructor(parentElement, media, initialMedia = null, initialPage) {
    this.media = media;
    this.element = new StaticViewportElement(parentElement);
    this.currentMedia = initialMedia || media.firstDocument;
    this.currentPage = initialPage;
    this.width = 1;
    this.height = 1;
    this.mediaWidth = 1;
    this.mediaHeight = 1;
    this.loading = true;
    this.shouldRenderAgain = false;
  }

  setLoading(isLoading) {
    this.loading = isLoading;
    this.loadingUpdateCallback(isLoading);

    if (!isLoading && this.shouldRenderAgain) {
      this.shouldRenderAgain = false;
      this.render();
    }
  }

  onLoadingUpdate(loadingUpdateCallback) {
    this.loadingUpdateCallback = loadingUpdateCallback;
  }

  onSelectPage(selectCallback) {
    this.selectCallback = () => {
      selectCallback(this.currentMedia, this.currentPage);
    };
    this.element.onClick(this.selectCallback);
  }

  async showPage(pageNumber) {
    if (this.currentMedia.isImage()) {
      this.currentPage = 1;
      this.currentMedia = this.media.documents[pageNumber - 1];
    } else {
      this.currentPage = pageNumber;
    }
    if (this.loading) {
      this.shouldRenderAgain = true;
    } else {
      await this.render();
    }
  }

  setViewportSize(width, height) {
    this.element.setSize(width, height);
    this.element.updateScale();
  }

  async render() {
    if (!this.currentMedia) return;
    this.element.hide();
    this.setLoading(true);

    this.mediaRenderer = makeRenderer(this.currentMedia, this.currentPage);
    await this.mediaRenderer.loadMedia();

    this.element.setMediaSize(
      this.mediaRenderer.width,
      this.mediaRenderer.height
    );

    const canvas = document.createElement("canvas");
    const scale = this.element.getScale();
    canvas.width = this.element.mediaWidth * scale;
    canvas.height = this.element.mediaHeight * scale;

    await this.mediaRenderer.render(canvas, this.currentPage);
    this.element.render(canvas.toDataURL("image/jpeg"));

    cleanUpCanvas(canvas);

    if (!this.shouldRenderAgain) {
      this.element.show();
    }

    this.setLoading(false);
  }

  onDestroy() {
    this.element.destroy();
  }
}
