import { useMedia } from "react-media";
import { tokens } from "@veneer/tokens";

const mediaQueries = {
  small: `screen and (max-width: ${tokens.layout.smMax})`,
  medium: `screen and  (min-width: ${tokens.layout.mdMin})`,
  large: `screen and (min-width: ${tokens.layout.lgMin})`,
};

const useMediaQueries = (queries = mediaQueries) => {
  return useMedia({ queries });
};

export { mediaQueries, tokens, useMediaQueries };
