import {
  CORNER_RATIO,
  MARKUP_MAX_SCALE,
  MARKUP_MIN_SCALE,
  MARKUP_VIEWPORT_ADJUSTMENT,
} from "../../constants";
import { Rectangle } from "../math";
import { MarkupController } from "./markup-controller";
import { MarkupElement } from "./markup-element";
import { Renderable } from "./renderable";

export class Markup extends Renderable {
  constructor(markupInfo, viewport) {
    super();

    this.id = markupInfo.id;
    this.uid = markupInfo.uid;
    this.page = markupInfo.page;
    this.documentId = markupInfo.documentId;
    this.mediaScale = viewport.backgroundMedia.mediaViewportScale;
    this.viewport = viewport;
    this.minScale = MARKUP_MIN_SCALE * this.mediaScale;
    this.maxScale = MARKUP_MAX_SCALE * this.mediaScale;

    this.isNew = markupInfo.isNew;
    this.x = (markupInfo.x || 0) * this.mediaScale;
    this.y = (markupInfo.y || 0) * this.mediaScale;

    const scale = (markupInfo.scale || 1) * this.mediaScale;
    this.scale = Math.max(this.minScale, Math.min(scale, this.maxScale));

    this.element = new MarkupElement(
      markupInfo.markSVG,
      this.x,
      this.y,
      this.scale
    );

    const { width, height } = this.element.getScaledDimensions();
    this.aspectRatio = width / height;
    this.width = width;
    this.height = height;

    this.element.setCornerSize(Math.min(width, height) * CORNER_RATIO);
    this.controller = new MarkupController(this);
    this.onClick = viewport.onClickComponent;
    this._onClick = this._onClick.bind(this);
    this.setupEvents();

    this.viewport.element.addChild(this.element.node);
  }

  centerAndPlayAnimation() {
    const { width, height } = this.viewport.element;
    const markupSize = this.element.width;
    const viewportPercent = width * MARKUP_VIEWPORT_ADJUSTMENT;
    const newScale = viewportPercent / markupSize;
    const viewport = new Rectangle(0, 0, width, height);

    this.setScale(newScale);
    this.centerInside(viewport);

    let rescaleAttempts = 10;
    while (!this.isInside(viewport) && rescaleAttempts > 0) {
      rescaleAttempts--;
      this.setScale(this.scale * MARKUP_VIEWPORT_ADJUSTMENT);
      this.centerInside(viewport);
    }

    this.element.node.classList.add("new-markup");
    this.viewport.notifyMarkupUpdate();
  }

  _onClick() {
    this.onClick(this);
  }

  setupEvents() {
    this.element.node.addEventListener("click", this._onClick);
  }

  onDestroy() {
    this.element.node.removeEventListener("click", this._onClick);
    this.viewport.element.removeChild(this.element.node);
  }

  handleScaling() {
    //Do nothing, scale with border dragging
  }

  handleScalingEnd(transform) {
    transform.cancelled = true;
  }

  handleTranslation(transform) {
    this.controller.handle(transform);
  }

  handleTranslationEnd() {
    this.controller.handleStop();
  }

  getState() {
    const markX = this.x / this.mediaScale;
    const markY = this.y / this.mediaScale;
    const markHeight = this.height / this.mediaScale;
    const markScale = this.scale / this.mediaScale;
    const documentHeight = this.viewport.backgroundMedia.scaledHeight;
    const yFromBottom = documentHeight - (markY + markHeight);

    return {
      uid: this.uid,
      x: markX,
      y: markY,
      scale: markScale,
      yFromBottom,
    };
  }

  setState(state) {
    this.setX(state.x * this.mediaScale);
    this.setY(state.y * this.mediaScale);
    this.setScale(state.scale * this.mediaScale);
  }

  setX(value) {
    super.setX(value);
    this.element.setPosition(value, this.element.y);
  }

  setY(value) {
    super.setY(value);
    this.element.setPosition(this.element.x, value);
  }

  setScale(value) {
    const newScale = Math.max(this.minScale, Math.min(value, this.maxScale));
    this.scale = newScale;
    this.element.applyScale(newScale);

    const { width, height } = this.element.getScaledDimensions();
    this.width = width;
    this.height = height;
  }

  render() {
    if (this.isNew) {
      this.centerAndPlayAnimation();
    }
    this.element.render();
  }

  set active(active) {
    if (active) {
      this.element.node.classList.add("active-corner");
    } else {
      this.element.node.classList.remove("active-corner");
    }
  }

  get isMarkup() {
    return true;
  }
}
