import Env from "../../utils/env";

export const Features = {
  TEXT_FEATURE: "SCRIBBLE_TEXT_FEAT_ENABLED",
};

const FeatureToggle = ({ feature, children }) => {
  const isFeatureEnabled = Env.getEnv()[feature];
  return <>{isFeatureEnabled && children}</>;
};

export default FeatureToggle;
