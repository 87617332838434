/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const rootStyle = css`
  display: inline-block;
  padding: 0;
`;

const PageEditedIcon = (props) => {
  return (
    <svg
      css={rootStyle}
      focusable={false}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.41667 0.75C0.772334 0.75 0.25 1.27233 0.25 1.91667V10.0833C0.25 10.7277 0.772335 11.25 1.41667 11.25H7.25C7.89433 11.25 8.41667 10.7277 8.41667 10.0833V8.1875C8.41667 8.02642 8.28608 7.89583 8.125 7.89583C7.96392 7.89583 7.83333 8.02642 7.83333 8.1875V10.0833C7.83333 10.4055 7.57217 10.6667 7.25 10.6667H1.41667C1.0945 10.6667 0.833333 10.4055 0.833333 10.0833V1.91667C0.833333 1.5945 1.0945 1.33333 1.41667 1.33333H7.25C7.57217 1.33333 7.83333 1.5945 7.83333 1.91667V2.9375C7.83333 3.09858 7.96392 3.22917 8.125 3.22917C8.28608 3.22917 8.41667 3.09858 8.41667 2.9375V1.91667C8.41667 1.27233 7.89433 0.75 7.25 0.75H1.41667Z"
        fill="white"
      />
      <path
        d="M2.29167 3.08333C2.13058 3.08333 2 3.21392 2 3.375C2 3.53608 2.13058 3.66667 2.29167 3.66667H5.79167C5.95275 3.66667 6.08333 3.53608 6.08333 3.375C6.08333 3.21392 5.95275 3.08333 5.79167 3.08333H2.29167Z"
        fill="white"
      />
      <path
        d="M2 5.125C2 4.96392 2.13058 4.83333 2.29167 4.83333H4.625C4.78608 4.83333 4.91667 4.96392 4.91667 5.125C4.91667 5.28608 4.78608 5.41667 4.625 5.41667H2.29167C2.13058 5.41667 2 5.28608 2 5.125Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75479 2.53123C10.106 2.15455 10.6996 2.14419 11.0638 2.50838C11.4104 2.85502 11.4202 3.41394 11.0859 3.77247L7.46332 7.65721C7.43404 7.68861 7.39819 7.71316 7.35833 7.7291L5.9 8.31244C5.8678 8.32531 5.83425 8.33212 5.80082 8.33317L5.79396 8.3333H4.91664C4.65556 8.3333 4.47134 8.1962 4.34622 8.04606C4.23307 7.91028 4.1472 7.7384 4.07948 7.60285L4.07243 7.58874C3.99538 7.43464 3.93744 7.32219 3.87226 7.24398C3.81509 7.17537 3.78056 7.16663 3.74997 7.16663C3.71953 7.16663 3.67719 7.16692 3.58367 7.35843C3.56262 7.40152 3.54219 7.44738 3.51896 7.49952L3.5183 7.50102C3.49558 7.552 3.47021 7.60889 3.44264 7.665C3.38853 7.77508 3.31583 7.90388 3.20881 8.01089C3.0057 8.214 2.76311 8.2916 2.58417 8.32046C2.49371 8.33505 2.41499 8.338 2.35792 8.33726C2.32921 8.33689 2.30546 8.33557 2.28785 8.33422C2.27903 8.33354 2.27171 8.33285 2.26601 8.33225L2.2587 8.33144L2.25599 8.33111L2.25487 8.33097L2.25414 8.33088C2.25402 8.33086 2.25391 8.33085 2.29164 8.04163L2.25414 8.33088C2.09441 8.31004 1.98159 8.16364 2.00242 8.00391C2.02315 7.845 2.16813 7.73274 2.32689 7.7521L2.33259 7.7526C2.33905 7.7531 2.35034 7.75378 2.36549 7.75398C2.39615 7.75438 2.44048 7.75277 2.49129 7.74457C2.59491 7.72786 2.70778 7.68697 2.79634 7.59841C2.83515 7.5596 2.87411 7.49927 2.91912 7.40768C2.9411 7.36296 2.96216 7.31589 2.98545 7.26362L2.98793 7.25806C3.00977 7.20902 3.03387 7.15492 3.0595 7.10244C3.15738 6.90202 3.34292 6.5833 3.74997 6.5833C4.01105 6.5833 4.19527 6.72039 4.32039 6.87054C4.43354 7.00631 4.51941 7.1782 4.58713 7.31375L4.59418 7.32786C4.67123 7.48196 4.72917 7.59441 4.79435 7.67262C4.85152 7.74123 4.88605 7.74996 4.91664 7.74996H5.59421L6.1042 6.47498C6.1176 6.44148 6.1371 6.41077 6.1617 6.38438L9.75479 2.53123ZM10.6513 2.92086C10.5206 2.79012 10.3075 2.79384 10.1814 2.92906L6.62554 6.7423L6.31523 7.51807L7.08116 7.2117L10.6592 3.37464C10.7793 3.24594 10.7758 3.04529 10.6513 2.92086Z"
        fill="white"
      />
    </svg>
  );
};

PageEditedIcon.displayName = "ScribbleIcon";

export default PageEditedIcon;
