/** @jsxImportSource @emotion/react */

import { IconPlus } from "@veneer/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Footer from "../../../components/Footer";
import IconButton from "../../../components/IconButton";
import ScribbleIcon from "../../../components/icons/ScribbleIcon";
import { sendInternalEvent } from "../../../plugins/analytics";
import { refs } from "../../../plugins/analytics/refs";
import { paths } from "../../../route.settings";

const FooterContainer = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const onNewMarkClick = () => {
    sendInternalEvent(refs.MARKUP_PLACE_ADD_A_NEW_MARK);
    history.push(paths.MARKUPS_CREATE);
  };

  const onSavedMarksClick = () => {
    sendInternalEvent(refs.MARKUP_PLACE_SAVED_MARKS);
    history.push(paths.MARKUPS_LIST);
  };

  return (
    <Footer>
      <IconButton
        data-testid="markups-create-button"
        data-coachmark="new-mark-button"
        appearance="ghost"
        leadingIcon={<IconPlus />}
        onClick={onNewMarkClick}
        label={t("page.new_mark.label")}
      />
      <IconButton
        data-testid="markups-list-button"
        appearance="ghost"
        leadingIcon={<ScribbleIcon />}
        onClick={onSavedMarksClick}
        label={t("page.saved_marks.title")}
      />
    </Footer>
  );
};

export default FooterContainer;
