import { useCallback } from "react";

const userAgents = {
  mobilesafari: "ios",
  macintosh: "mac",
  android: "android",
  ipad: "ios",
  iphone: "ios",
  "windows nt": "windows",
  linux: "linux",
};

const platforms = {
  android: "mobile",
  ios: "mobile",
  mac: "desktop",
  windows: "desktop",
  linux: "desktop",
};

const useDevice = () => {
  const getOS = useCallback(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const key = Object.keys(userAgents).find((agent) =>
      userAgent.includes(agent)
    );
    return userAgents[key] || "android";
  }, []);

  const deviceOS = getOS();
  const platform = platforms[deviceOS] || "mobile";

  const isMobile = platform === "mobile";
  const isDesktop = platform === "desktop";

  const isIos = deviceOS === "ios";
  const isAndroid = deviceOS === "android";
  const isWindows = deviceOS === "windows";
  const isMac = deviceOS === "mac";
  const isLinux = deviceOS === "linux";

  return {
    deviceOS,
    platform,
    isMobile,
    isDesktop,
    isIos,
    isAndroid,
    isWindows,
    isMac,
    isLinux,
  };
};

export default useDevice;
