export class PDFRenderer {
  constructor(media, pageIndex) {
    this.media = media;
    this.pageIndex = pageIndex;
    this.viewportScale = media.scale;
    this.renderingTasks = {};
  }

  async loadMedia() {
    const page = await this.media.content.getPage(this.pageIndex);
    this._viewport = page.getViewport({ scale: this.viewportScale });
    this._ready = true;

    this.width = this._viewport.width;
    this.height = this._viewport.height;

    this._viewport = this._viewport.clone({ scale: this.viewportScale });
  }

  async render(targetCanvas, pageIndex = 0) {
    const targetPageIndex = pageIndex || this.pageIndex;
    if (this.renderingTasks[targetPageIndex]) {
      this.renderingTasks[targetPageIndex].cancel();
    }

    const scaleDelta = targetCanvas.width / this.width;
    const scale = scaleDelta * this.viewportScale;

    const renderContext = {
      canvasContext: targetCanvas.getContext("2d"),
      viewport: this._viewport.clone({ scale }),
    };

    const page = await this.media.content.getPage(targetPageIndex);
    const renderTask = page.render(renderContext);
    this.renderingTasks[targetPageIndex] = renderTask;

    await renderTask.promise;
  }
}
