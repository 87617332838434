import { request } from "../services/request";

const useCallbackRequest = (url, callbackSessionId) => {
  const send = (data) => {
    return request({
      data,
      url,
      method: "POST",
      headers: {
        "x-session-id": callbackSessionId,
      },
    });
  };

  return send;
};

export default useCallbackRequest;
