/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import IconButton from "../IconButton";
import TextIcon from "../icons/TextIcon";

import { tokens } from "../../css.settings";

const rootStyle = ({ active }) => css`
  ${active === true &&
  `
    > button {
      border-width: 2px;
      border-color: ${tokens.color.gray7};
      &:after {
        opacity: 1;
        border-radius: 50%;
        margin: 2px;
        border-color: ${tokens.color.gray3};
      }
    }
  `}
`;

const TextButton = ({ label = "", active = false, disabled, onClick }) => {
  return (
    <IconButton
      data-testid={`text-button`}
      label={label}
      disabled={disabled}
      aria-selected={active}
      appearance="ghost"
      leadingIcon={<TextIcon />}
      onClick={onClick}
      styles={{ root: rootStyle({ active }) }}
    />
  );
};

export default TextButton;
