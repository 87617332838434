import { readDPI } from "../../utils/image-utils/image-dpi";
import { Buffer } from "buffer";

class DocumentLoader {
  constructor(DocProvider) {
    this.DocProvider = DocProvider;
  }

  _processData(data, mediaType) {
    if (mediaType === "application/pdf") {
      return Buffer.from(data, "base64");
    }
    return `data:${mediaType};base64,${data}`;
  }

  async getDocumentData(docSetId, doc) {
    const { id, mediaType, fileName } = doc;
    const docData = await this.DocProvider.getDocData({
      docSetId,
      docId: id,
    });

    const data = this._processData(docData.data, mediaType);
    const dpi = readDPI(docData.data, mediaType);
    console.debug(`Loading ${id} with ${dpi}dpi`);

    return {
      id,
      dpi,
      data,
      mimeType: mediaType,
      fileName,
    };
  }

  isDocProviderPluginError(value) {
    return value?.errorType !== undefined;
  }

  async getDocumentsData() {
    const result = await this.DocProvider.getResultDocSet();

    if (this.isDocProviderPluginError(result)) {
      console.error(
        `Error while getting DocSet: ${result.code} - ${result.message}`
      );
      return [];
    }

    const { id, docs } = result;
    const docsPromises = docs.map((doc) => this.getDocumentData(id, doc));
    return Promise.all(docsPromises);
  }
}

export default DocumentLoader;
