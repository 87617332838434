const createNewMarkCoachmarks = {
  id: "create-new-mark",
  isRepeatable: false,
  requirement: ({ isTyping }) => isTyping === false,
  isClosable: true,
  steps: [
    {
      title: "coachmark.create_new_mark.step_0",
      placement: "top",
      anchorElement: '[data-coachmark="signature-lines"]',
    },
    {
      title: "coachmark.create_new_mark.step_1",
      placement: "top",
      anchorElement: '[data-coachmark="stroke-thickness-buttons"]',
    },
    {
      title: "coachmark.create_new_mark.step_2",
      placement: "bottom",
      anchorElement: '[data-coachmark="save-markup"]',
    },
  ],
};

const MarkupCreateCoachmarks = {
  desktop: {
    autoPlay: false,
    coachmarks: [createNewMarkCoachmarks],
  },
  mobile: {
    autoPlay: false,
    coachmarks: [createNewMarkCoachmarks],
  },
};

export default MarkupCreateCoachmarks;
