/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { memo } from "react";
import { Button, IconChevronLeft, IconChevronRight } from "@veneer/core";
import CircularIndicator from "../CircularIndicator";

const rootStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const contentContainerStyle = css`
  flex-grow: 1;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const buildButtonStyle = (size) => ({
  width: `${size}em`,
  height: `${size}em`,
});

const PageSelectionLayout = ({
  children,
  onClickNext,
  onClickPrev,
  prevButtonEnabled,
  nextButtonEnabled,
  contentStyle = null,
  loading = false,
  containerRef,
  arrowSize = 3,
  hasMultiplePages,
}) => {
  const buttonStyle = buildButtonStyle(arrowSize);
  return (
    <div css={rootStyle}>
      {hasMultiplePages && (
        <Button
          data-testid="prev-page-button"
          appearance="ghost"
          onClick={onClickPrev}
          disabled={!prevButtonEnabled}
          leadingIcon={<IconChevronLeft style={buttonStyle} />}
        />
      )}
      <div css={[contentContainerStyle, contentStyle]} ref={containerRef}>
        {loading && <CircularIndicator scale={1.5} />}
        {children}
      </div>
      {hasMultiplePages && (
        <Button
          data-testid="next-page-button"
          appearance="ghost"
          onClick={onClickNext}
          disabled={!nextButtonEnabled}
          leadingIcon={<IconChevronRight style={buttonStyle} />}
        />
      )}
    </div>
  );
};

export default memo(PageSelectionLayout);
