/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { tokens } from "../../css.settings";

export const SidebarRoot = styled.div`
  position: relative;
  height: 100%;
  min-width: 76px;
  z-index: 1;
  border-left: 1px solid ${tokens.color.gray3};
`;

export const SidebarTabList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: ${tokens.color.gray0};
`;

export const SidebarTabItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  z-index: 3;

  & button.is-active:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: -4px;
    background-color: transparent;
    border-radius: 100%;
    border: 1px solid ${tokens.color.gray7};
  }
`;

export const SelectedTabIndicator = styled.div`
  position: absolute;

  z-index: 2;
  top: -16px;
  left: -1px;

  width: calc(100% + 2px);
  height: 132px;

  background-color: white;
  transform: translateY(${(props) => props.selectedIndex * 100}px);
  transition: transform 200ms ease-in-out;

  &::before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    width: 100%;
    height: 16px;
    background-color: ${tokens.color.gray0};
    border-left: 1px solid ${tokens.color.gray3};
    border-bottom-left-radius: ${tokens.layout.cornerRadius4};
    border-bottom: 1px solid
      ${(props) => (props.selectedIndex ? tokens.color.gray3 : "transparent")};
  }

  &::after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    width: 100%;
    height: 16px;
    background-color: ${tokens.color.gray0};
    border-top: 1px solid ${tokens.color.gray3};
    border-left: 1px solid ${tokens.color.gray3};
    border-top-left-radius: ${tokens.layout.cornerRadius4};
  }
`;
