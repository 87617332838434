/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Checkbox } from "@veneer/core";

const checkboxStyle = css`
  width: 100%;
  span.label {
    margin-top: auto;
  }
`;

const CenteredCheckbox = (props) => {
  return <Checkbox css={checkboxStyle} {...props} />;
};

export default CenteredCheckbox;
