/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import ActionButtons from "../../../components/ActionButtons";
import CenteredCheckbox from "../../../components/CenteredCheckbox";
import Screen from "../../../components/Screen";
import SignaturePad from "../../../components/SignaturePad";
import StrokeThicknessButtons from "../../../components/StrokeThicknessButtons";
import { tokens } from "../../../css.settings";
import useCreateMarkup from "../../../hooks/useCreateMarkup";
import FooterContainer from "../FooterContainer";

const strokeButtonsStyle = css`
  flex: 1;
  justify-content: center;
  span {
    font-size: 0.75rem;
  }
  button {
    background-color: ${tokens.color.white};
  }
`;

const bodyFooterStyle = css`
  pointer-events: auto;
`;

const buttonsStyle = css`
  button {
    background-color: ${tokens.color.white};
  }

  button:disabled {
    background-color: ${tokens.color.gray1};
    border-color: ${tokens.color.gray1};
  }
`;

const MarkupsCreateScreenContainer = () => {
  const { t } = useTranslation();
  const {
    bodyRef,
    registerCanvas,
    handleCancel,
    saveMarkup,
    onCheckboxChange,
    onSelectThin,
    onSelectMedium,
    onSelectThick,
    onSelectText,
    onTrash,
    onType,
    onUndo,
    onRedo,
    isTyping,
    isDoneDisabled,
    input,
    canUndo,
    canRedo,
    canTrash,
    padSize,
    thickness,
  } = useCreateMarkup();

  return (
    <Screen
      scrollable={false}
      bodyRef={bodyRef}
      title={t("page.new_mark.title")}
      bodyHeader={
        <>
          <CenteredCheckbox
            data-testid="save-checkbox"
            data-coachmark="save-markup"
            label={t("page.new_mark.save_when_placing")}
            defaultChecked
            onChange={onCheckboxChange}
          />
          <ActionButtons
            onUndo={onUndo}
            onRedo={onRedo}
            canUndo={canUndo}
            canRedo={canRedo}
            onTrash={onTrash}
            canTrash={canTrash}
            variant="desktop"
            styles={{ root: buttonsStyle }}
          />
        </>
      }
      body={
        <SignaturePad
          ref={registerCanvas}
          width={padSize.width}
          height={padSize.height}
          onUndo={onUndo}
          onRedo={onRedo}
          onType={onType}
          canUndo={canUndo}
          canRedo={canRedo}
          isTyping={isTyping}
          input={input}
          variant="desktop"
        />
      }
      bodyFooter={
        <StrokeThicknessButtons
          styles={{ root: strokeButtonsStyle }}
          onSelectThin={onSelectThin}
          onSelectMedium={onSelectMedium}
          onSelectThick={onSelectThick}
          onSelectText={onSelectText}
          thickness={thickness}
          isTyping={isTyping}
        />
      }
      footer={
        <FooterContainer
          onCancel={handleCancel}
          onDone={saveMarkup}
          doneDisabled={isDoneDisabled}
        />
      }
      styles={{
        bodyFooter: bodyFooterStyle,
      }}
    />
  );
};

export default MarkupsCreateScreenContainer;
