/** @jsxImportSource @emotion/react */
import {
  SelectedTabIndicator,
  SidebarRoot,
  SidebarTabItem,
  SidebarTabList,
} from "./DesktopSidebarStyles";
import DesktopSidebarTabContent from "../DesktopSidebarTabContent";

const DesktopSidebar = ({ tabs = [], currentTabIndex = -1 }) => {
  const absoluteCurrentTabIndex = Math.abs(currentTabIndex);
  return (
    <SidebarRoot className="tabs">
      <SidebarTabList role="tablist" aria-label="Navigation Menu">
        {tabs.map((tab, index) => {
          const isSelected = index === currentTabIndex;
          return (
            <SidebarTabItem
              role="tab"
              key={tab.id}
              id={`tab-${tab.id}`}
              data-testid={`tab-item-${tab.id}`}
              aria-controls={`panel-${tab.id}`}
              aria-selected={isSelected}
              tabIndex={isSelected ? 0 : -1}
            >
              {tab.labelComponent}
            </SidebarTabItem>
          );
        })}
        {currentTabIndex > -1 && (
          <SelectedTabIndicator selectedIndex={currentTabIndex} />
        )}
      </SidebarTabList>
      {tabs.map((tab, index) => (
        <DesktopSidebarTabContent
          key={tab.id}
          role="tabpanel"
          id={`panel-${tab.id}`}
          data-testid={`panel-${tab.id}`}
          aria-labelledby={`tab-${tab.id}`}
          isOpen={currentTabIndex > -1}
          isCurrent={index === absoluteCurrentTabIndex}
        >
          {index === absoluteCurrentTabIndex ? tab.content : null}
        </DesktopSidebarTabContent>
      ))}
    </SidebarRoot>
  );
};

export default DesktopSidebar;
