import { Buffer } from "buffer";

export const getDimensionsFromSVGElement = (markupElement) => {
  try {
    const width = markupElement.width.baseVal.value;
    const height = markupElement.height.baseVal.value;

    return {
      width,
      height,
    };
  } catch (error) {
    console.log(error);
  }
  return {};
};

export const createSVGNodeFromString = (svgString) => {
  const SVGContainer = document.createElement("div");
  SVGContainer.innerHTML = svgString;

  const svgNode = SVGContainer.firstElementChild;
  return svgNode;
};

export const convertSVGNodeToBase64 = (svgNode) => {
  let svgXml = new XMLSerializer().serializeToString(svgNode);
  return "data:image/svg+xml;base64," + Buffer.from(svgXml).toString("base64");
};
