const isBase64 = (value) => {
  const regex = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
  return regex.test(value);
};

export const formatBase64 = (data) => {
  return data.startsWith("data:") ? data.split(";base64,")[1] : data;
};

export default isBase64;
