import { SignaturePadExtension as SignaturePad } from "../../utils/signature-pad-extension";
import { request } from "../../services/request";
import Env from "../../utils/env";

class SignatureUtil {
  static getPathsBoundingBoxWithPadding(paths, padding = 0) {
    const boundingBox = {
      x: Number.MAX_VALUE,
      y: Number.MAX_VALUE,
      width: 0,
      height: 0,
    };

    const points = paths.reduce((acc, value) => acc.concat(value.points), []);

    points.forEach((point) => {
      boundingBox.x = Math.min(boundingBox.x, point.x);
      boundingBox.y = Math.min(boundingBox.y, point.y);
      boundingBox.width = Math.max(boundingBox.width, point.x);
      boundingBox.height = Math.max(boundingBox.height, point.y);
    });

    boundingBox.x -= padding;
    boundingBox.y -= padding;
    boundingBox.width += padding;
    boundingBox.height += padding;

    return boundingBox;
  }

  static translatePathPosition(path, xOffset, yOffset) {
    return path.map((path) => {
      const points = path.points.map(({ time, x, y }) => ({
        time,
        x: x - xOffset,
        y: y - yOffset,
      }));

      return { ...path, points };
    });
  }

  static pathsToSVG(paths, thickness) {
    const boundingBox = SignatureUtil.getPathsBoundingBoxWithPadding(paths, 20);
    const translatedPath = SignatureUtil.translatePathPosition(
      paths,
      boundingBox.x,
      boundingBox.y
    );

    const canvasElement = document.createElement("canvas");
    canvasElement.width = boundingBox.width - boundingBox.x;
    canvasElement.height = boundingBox.height - boundingBox.y;

    const signaturePad = new SignaturePad(canvasElement);
    signaturePad.maxWidth = thickness;
    signaturePad.fromData(translatedPath);

    // If devicePixelRatio is not equals to 1 the final svg size will be
    // different from canvas size and the output will be cropped
    const originalDevicePixelRatio = window.devicePixelRatio || 1;
    window.devicePixelRatio = 1;
    const dataURL = signaturePad.toDataURL("image/svg+xml");
    window.devicePixelRatio = originalDevicePixelRatio;

    const [, content] = dataURL.split(";base64,");
    const svgString = window.atob(content);
    return svgString;
  }

  static async textToSvg(text, accessToken) {
    const responseData = await request({
      method: "POST",
      url: Env.getEnv().SCRIBBLE_API_BASE_URL,
      resource: "svgconverter",
      accessToken,
      data: { text },
    });

    return responseData;
  }
}

export default SignatureUtil;
