/** @jsxImportSource @emotion/react  */
import { css } from "@emotion/react";

const titleStyle = css`
  font-size: 18px;
`;

const Title = ({ children, ...rest }) => {
  return (
    <h1
      data-testid={rest["data-testid"] || "title"}
      css={titleStyle}
      className="title-small"
    >
      {children}
    </h1>
  );
};

export default Title;
