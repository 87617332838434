/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { tokens } from "../../css.settings";

const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-width: 44px;

  padding: calc(${tokens.layout.size1} + 0.5px)
    calc(${tokens.layout.size4} + 0.4px);

  cursor: pointer;

  :hover {
    background-color: ${tokens.color.gray2};
  }
  background-color: ${tokens.color.white};
`;

const labelStyle = css`
  margin-top: -${tokens.layout.size2};
  font-size: 0.938rem;
`;

const primaryStyle = css`
  color: ${tokens.color.white};
  background-color: ${tokens.color.hpBlue6};
  :hover {
    background-color: ${tokens.color.hpBlue7};
  }
  :active {
    background-color: ${tokens.color.hpBlue8};
  }

  svg {
    color: ${tokens.color.white};
  }
`;

const disabledStyle = css`
  background-color: ${tokens.color.gray2};
  pointer-events: none;

  color: ${tokens.color.gray5};
  svg {
    color: ${tokens.color.gray5};
  }
`;

function SquareButton({ icon, label, onClick, primary, disabled, ...rest }) {
  return (
    <div
      data-testid="square-button"
      css={[
        container,
        primary ? primaryStyle : null,
        disabled ? disabledStyle : null,
      ]}
      onClick={disabled ? null : onClick}
      disabled={disabled}
      {...rest}
    >
      {icon && <div data-testid="square-button-icon">{icon}</div>}
      {label && (
        <div css={labelStyle} data-testid="square-button-label">
          {label}
        </div>
      )}
    </div>
  );
}

export default SquareButton;
