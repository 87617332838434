/* eslint-disable react-hooks/exhaustive-deps */
import { jWebReady } from "@jarvis/jweb-core";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { formatBase64 } from "../../utils/base64";
import Env from "../../utils/env";
import { applyDPI } from "../../utils/image-utils/image-dpi";
import DocumentLoader from "./DocumentLoader";
import Context from "./JarvisContext";

const SessionProvider = ({ children, props = {} }) => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const [isUsingJarvis, setIsUsingJarvis] = useState(false);
  const [plugins, setPlugins] = useState();

  const [appVersion, setAppVersion] = useState();
  const [authToken, setAuthToken] = useState();
  const [exchangedToken, setExchangedToken] = useState();
  const [deviceLang, setDeviceLang] = useState();
  const [documents, setDocuments] = useState();

  const cdmPublisherRef = useRef(null);

  useEffect(() => {
    initJarvis();
  }, []);

  const initJarvis = async () => {
    try {
      const res = await jWebReady;
      setPlugins(res.Plugins);
      setIsUsingJarvis(res.JWeb.isNative);
    } catch (error) {
      setError(error);
    } finally {
      setReady(true);
    }
  };

  const getDeviceInfo = async () => {
    const { Device } = plugins;
    if (!Device) return;
    try {
      console.log("[DeviceInfo] Getting device info");
      const { locale, appVersion } = await Device.getInfo();
      setDeviceLang(locale.languageTag);
      setAppVersion(appVersion);
      console.log("[DeviceInfo] Device info retrieved");
    } catch (error) {
      console.error("[DeviceInfo] Error while getting device info: " + error);
      closeInstance({ resultData: { source: "error", error } });
    }
  };

  const getAuthToken = async () => {
    const { Auth } = plugins;
    const tokenProviderOptions = {
      tokenType: "user",
      allowUserInteraction: true,
    };
    try {
      console.log("[AuthToken] Getting auth token");
      const { tokenValue } = await Auth.getToken({ tokenProviderOptions });
      setAuthToken(tokenValue);
      console.log("[AuthToken] Auth token retrieved");
    } catch (error) {
      console.error("[AuthToken] Error while getting token: " + error);
      closeInstance({ resultData: { source: "error", error } });
    }
  };

  const getExchangedToken = async (token) => {
    try {
      console.log("[ExchangedToken] Exchanging token");
      const { SCRIBBLE_GET_SESSION_URL } = Env.getEnv();
      const EXCHANGE_TOKEN_PATH = `${SCRIBBLE_GET_SESSION_URL}/ssm/authentication/exchange`;
      const { data } = await axios.post(EXCHANGE_TOKEN_PATH, { token });
      setExchangedToken(data.exchangedToken);
      console.log("[ExchangedToken] Token exchanged");
    } catch (error) {
      console.error("[ExchangedToken] Error while exchanging token: " + error);
      closeInstance({ resultData: { source: "error", error } });
    }
  };

  const getDocuments = async () => {
    const { DocProvider } = plugins;
    try {
      console.log("[Documents] Getting documents");
      const documentLoader = new DocumentLoader(DocProvider);
      const documents = await documentLoader.getDocumentsData();
      setDocuments(documents);
      console.log("[Documents] Documents retrieved");
    } catch (error) {
      console.error("[Documents] Error while getting the documents: " + error);
      closeInstance({ resultData: { source: "error", error } });
    }
  };

  const replaceDocuments = async (modifiedDocuments) => {
    const { DocProvider } = plugins;

    const modifiedDocumentIds = modifiedDocuments.map(({ id }) => id);
    const originalDocumentIds = documents.map(({ id }) => id);

    await DocProvider.removeDocsFromResultDocSet({
      docIds: originalDocumentIds,
    });

    for (const originalDoc of documents) {
      const id = originalDoc.id;
      const fileName = originalDoc.fileName || id;
      let mediaType = originalDoc.mimeType;

      let data = originalDoc.data;

      const indexOfModified = modifiedDocumentIds.indexOf(id);
      if (indexOfModified >= 0) {
        console.log(
          `[Documents/Replace] The following document was modified: ${id}/${fileName}`
        );
        const modifiedDocument = modifiedDocuments[indexOfModified];
        console.log(`[Documents/Replace]: ${fileName} base64:`);
        console.log(modifiedDocument.data);
        data = modifiedDocument.data;
        mediaType = modifiedDocument.mimeType;
      }

      const normalizedData = applyDPI(data, originalDoc.dpi);

      const documentToAdd = {
        id,
        mediaType,
        fileName,
        data: formatBase64(normalizedData),
      };

      await DocProvider.addDocToResultDocSet(documentToAdd);
    }
  };

  const closeInstance = async (options) => {
    const { ServiceRouting } = plugins;
    if (cdmPublisherRef.current) {
      cdmPublisherRef.current.destroy?.();
    }
    try {
      console.log("[CloseInstance]: Closing app instance.");
      await ServiceRouting.closeServiceInstance(options);
      console.log("[CloseInstance]: App instance closed");
      document.title += " (Closed)";
      document.body.innerHTML = "";
    } catch (error) {
      console.error(
        "[CloseInstance]: Error while closing app instance: " + error
      );
    }
  };

  const getServiceOptions = async () => {
    const { ServiceRouting } = plugins;
    try {
      console.log("[ServiceOptions]: Getting service options.");
      const options = await ServiceRouting.getServiceInstanceLaunchOptions();
      if (options && options.serviceOptions) {
        console.log(
          "[ServiceOptions]: Service options retrieved:",
          JSON.stringify(options.serviceOptions)
        );
        return options.serviceOptions;
      }
    } catch (error) {
      console.error(
        "[ServiceOptions]: Error while retrieveing service options: " + error
      );
      return closeInstance({ resultData: { source: "error", error } });
    }
    return undefined;
  };

  const getAppInstanceId = async () => {
    const { appInstanceId = "" } = await getServiceOptions();

    if (!appInstanceId) {
      console.error(new Error('Missing "appInstanceId" option'));
    }

    return appInstanceId;
  };

  const getAnalyticsPublisher = async () => {
    if (!plugins) return;

    console.debug(
      "[EventService] Listing available plugins: ",
      JSON.stringify(plugins, null, 2)
    );
    console.log("[EventService] Creating the publisher");

    const { EventService } = plugins;
    const publisherId = "com.hp.scribblewebapp.datacollection.publisher";
    const createPublisherOptions = {
      allowEventingFallback: true,
    };
    const publisher = await EventService.createPublisher(
      publisherId,
      createPublisherOptions
    );

    if (publisher instanceof Error) {
      console.error(
        "[EventService] Error while creating the CDM publisher" + publisher
      );
      return closeInstance({
        resultData: { source: "error", error: publisher },
      });
    }

    cdmPublisherRef.current = publisher;

    console.log("[EventService] Publisher created successfully");

    return publisher;
  };

  const value = {
    ready,
    error,
    authToken,
    exchangedToken,
    isUsingJarvis,
    appVersion,
    deviceLang,
    documents,
    getAuthToken,
    getDeviceInfo,
    getExchangedToken,
    getDocuments,
    getServiceOptions,
    replaceDocuments,
    closeInstance,
    getAnalyticsPublisher,
    getAppInstanceId,
    ...props,
  };

  return <Context.Provider value={value}>{ready && children}</Context.Provider>;
};

export default SessionProvider;
