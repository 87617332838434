const X = 0;
const Y = 1;

export default class Rectangle {
  constructor(x, y, width, height, aspectRatio = undefined) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.aspectRatio = aspectRatio;
  }

  isPointInside(x, y) {
    const xStart = this.x;
    const yStart = this.y;
    const xEnd = this.x + this.width;
    const yEnd = this.y + this.height;

    return x >= xStart && y >= yStart && x <= xEnd && y <= yEnd;
  }

  centerInside(rectangle) {
    this.setX(rectangle.x + rectangle.width / 2 - this.width / 2);
    this.setY(rectangle.y + rectangle.height / 2 - this.height / 2);
  }

  isInside(rectangle) {
    return this.corners.every((corner) => rectangle.isPointInside(...corner));
  }

  fitTo(rectangle, padding = 0) {
    this.x = Math.min(this.x, rectangle.bottomRight[X] - this.width - padding);
    this.y = Math.min(this.y, rectangle.bottomRight[Y] - this.height - padding);
    this.x = Math.max(this.x, rectangle.x + padding);
    this.y = Math.max(this.y, rectangle.y + padding);

    if (this.bottomRight[X] > rectangle.bottomRight[X]) {
      const deltaX = rectangle.bottomRight[X] - padding - this.bottomRight[X];
      this.width += deltaX;
      if (this.usesAspectRatio) this.height = this.width / this.aspectRatio;
    }
    if (this.bottomRight[Y] > rectangle.bottomRight[Y]) {
      const deltaY = rectangle.bottomRight[Y] - padding - this.bottomRight[Y];
      this.height += deltaY;
      if (this.usesAspectRatio) this.width = this.height * this.aspectRatio;
    }
  }

  get corners() {
    return [this.bottomLeft, this.bottomRight, this.topLeft, this.topRight];
  }

  get topLeft() {
    return [this.x, this.y];
  }

  get bottomLeft() {
    return [this.x, this.y + this.height];
  }

  get topRight() {
    return [this.x + this.width, this.y];
  }

  get bottomRight() {
    return [this.x + this.width, this.y + this.height];
  }

  get usesAspectRatio() {
    return this.aspectRatio !== undefined;
  }

  getAspectRatio() {
    return this.aspectRatio || this.width / this.height;
  }

  transform({ x, y, width, height } = {}) {
    this.x += x || 0;
    this.y += y || 0;
    this.width += width || 0;
    this.height += height || 0;
    return this;
  }

  clone() {
    return new Rectangle(
      this.x,
      this.y,
      this.width,
      this.height,
      this.aspectRatio
    );
  }

  cloneDimensions(x = 0, y = 0) {
    return new Rectangle(x, y, this.width, this.height);
  }

  scale(scale = 1) {
    const aspectRatio = this.aspectRatio || this.width / this.height;
    this.width *= scale;
    this.height = this.width / aspectRatio;
    return this;
  }
}
