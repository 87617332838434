/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const rootStyle = css`
  display: inline-block;
  fill: #212121;
  height: 24px;
  width: 24px;
  padding: 0;
`;

const MediumStrokeIcon = (props) => {
  return (
    <svg
      css={rootStyle}
      fill="currentColor"
      focusable={false}
      version="1.1"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M552.2,1216.8c-1.077,0-3.379,4-7.5,4a8.442,8.442,0,0,1-7.5-4,8.442,8.442,0,0,0-7.5-4c-4.121,0-6.46,4-7.5,4"
        transform="translate(-517.2 -1196.8)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <g fill="none" opacity="0">
        <path d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" stroke="none" />
        <path
          d="M 20 1.5 C 15.05846977233887 1.5 10.4127197265625 3.424339294433594 6.918529510498047 6.918529510498047 C 3.424339294433594 10.4127197265625 1.5 15.05846977233887 1.5 20 C 1.5 24.94153022766113 3.424339294433594 29.5872802734375 6.918529510498047 33.08147048950195 C 10.4127197265625 36.57566070556641 15.05846977233887 38.5 20 38.5 C 24.94153022766113 38.5 29.5872802734375 36.57566070556641 33.08147048950195 33.08147048950195 C 36.57566070556641 29.5872802734375 38.5 24.94153022766113 38.5 20 C 38.5 15.05846977233887 36.57566070556641 10.4127197265625 33.08147048950195 6.918529510498047 C 29.5872802734375 3.424339294433594 24.94153022766113 1.5 20 1.5 M 20 0 C 31.04568862915039 0 40 8.954309463500977 40 20 C 40 31.04568862915039 31.04568862915039 40 20 40 C 8.954309463500977 40 0 31.04568862915039 0 20 C 0 8.954309463500977 8.954309463500977 0 20 0 Z"
          stroke="none"
          fill="#dbdbdb"
        />
      </g>
    </svg>
  );
};

MediumStrokeIcon.displayName = "MediumStrokeIcon";

export default MediumStrokeIcon;
