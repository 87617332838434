/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { IconCheckmarkCircle, IconXCircle } from "@veneer/core";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer";
import SquareButton from "../../../components/SquareButton/SquareButton";

const trailingAreaStyle = css`
  display: flex;
  align-items: center;
`;

const leadingAreaStyle = css`
  min-width: 50vw;
  display: flex;
  justify-content: flex-end;
`;

const rootStyle = css`
  > div {
    padding: 0;
    border: 0;
    min-height: 38px;
  }
`;

const containerStyle = css`
  height: 100%;
`;

function FooterContainer({
  children,
  onDone,
  onCancel,
  doneDisabled,
  cancelDisabled,
}) {
  const { t } = useTranslation();
  return (
    <div css={containerStyle} data-testid="footer-container-desktop">
      <Footer
        appearence="ultraminimal"
        rootStyle={rootStyle}
        leadingAreaStyle={leadingAreaStyle}
        trailingArea={
          <div css={trailingAreaStyle}>
            {onCancel && (
              <SquareButton
                icon={<IconXCircle size={26} />}
                label={t("common.cancel")}
                aria-label={t("common.cancel")}
                onClick={onCancel}
                disabled={cancelDisabled}
              />
            )}
            {onDone && (
              <SquareButton
                data-coachmark="done-button"
                icon={<IconCheckmarkCircle size={26} />}
                label={t("common.done")}
                primary
                aria-label={t("common.done")}
                onClick={onDone}
                disabled={doneDisabled}
              />
            )}
          </div>
        }
      >
        {children}
      </Footer>
    </div>
  );
}

export default FooterContainer;
