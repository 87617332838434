import { data as udlData } from "../data";
import { refs } from "../refs";

export class UDLEventExtraDataAdapter {
  adaptExtraData(extraData) {
    if (Object.keys(extraData).length === 0) {
      return {};
    }

    return {
      v151: extraData.count,
    };
  }
}

export class UDLEventAdapter {
  constructor() {
    this.udlEventExtraDataAdapter = new UDLEventExtraDataAdapter();
  }
  adaptInternalEvent({ label, extra, shouldSkipUdl }) {
    if (!label || shouldSkipUdl) {
      return;
    }

    let data = udlData[label];

    if (label.includes(refs.labels.navitagion)) {
      const path = label.split(".").pop();
      data = udlData.navigation.GENERAL_NAVIGATION_BACK(path);
    }

    return {
      ...data,
      extra: this.udlEventExtraDataAdapter.adaptExtraData(extra),
    };
  }

  adaptPageViewEvent(event) {
    const [, name, tab, state] = new URL(event.location).pathname.split("/");
    return {
      name,
      tab,
      state,
    };
  }

  adaptUserLoginEvent(_event) {
    return {};
  }
}
