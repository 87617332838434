import { changeDpiDataUrl } from "changedpi";
import { Buffer } from "buffer";

const JPEG_PHYS_LEN = 24; //JPEG pHYS chunk size
const PNG_PHYS_SIGNATURES = ["AAlwSFlz", "AAAJcEhZ", "AAAACXBI"];
const PNG_PHYS_CODE = ["p", "H", "Y", "s"].map((c) => c.charCodeAt(0));
const PNG_DPI_PER_METER = 39.3701;

const DEFAULT_DPI = 96;

const findPhysIndex = (bytes) => {
  for (let index = 0; index < bytes.length; index++) {
    const hasMatch =
      bytes[index] === PNG_PHYS_CODE[0] &&
      bytes[index + 1] === PNG_PHYS_CODE[1] &&
      bytes[index + 2] === PNG_PHYS_CODE[2] &&
      bytes[index + 3] === PNG_PHYS_CODE[3];

    if (hasMatch) return index + 4;
  }
  return -1;
};

const readDPIFromJPEG = (data) => {
  const pHYSData = data.substr(0, JPEG_PHYS_LEN);
  const bytes = Buffer.from(pHYSData, "base64");
  return (bytes[14] << 8) | (bytes[15] & 0xff) || DEFAULT_DPI; //pHYS chunk position DPI high and low bytes
};

const readDPIFromPNG = (data) => {
  let b64Index;
  PNG_PHYS_SIGNATURES.find((signature) => {
    b64Index = data?.indexOf(signature);
    return b64Index !== -1;
  });

  const headerLength =
    b64Index >= 0 ? Math.ceil((b64Index + 28) / 3) * 4 : JPEG_PHYS_LEN;

  const pHYSData = data.substr(0, headerLength);
  const bytes = Buffer.from(pHYSData, "base64");

  let physChunkStart = findPhysIndex(bytes);
  if (physChunkStart === -1) return;

  const dpiValue =
    (bytes[physChunkStart] << 24) |
    (bytes[physChunkStart + 1] << 16) |
    (bytes[physChunkStart + 2] << 8) |
    bytes[physChunkStart + 3];

  return Math.ceil(dpiValue / PNG_DPI_PER_METER);
};

export const applyDPI = (imageData, dpi) => {
  return changeDpiDataUrl(imageData, dpi);
};

export const readDPI = (imageData, mimeType) => {
  const strategies = {
    "image/jpeg": readDPIFromJPEG,
    "image/png": readDPIFromPNG,
  };
  return strategies[mimeType]?.(imageData) ?? DEFAULT_DPI;
};
