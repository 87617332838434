/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Footer as VeneerFooter } from "@veneer/core";

const leadingAreaStyleDefault = css`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const rootStyleDefault = css`
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.05);
`;

const Footer = ({
  children,
  trailingArea,
  leadingAreaStyle = leadingAreaStyleDefault,
  rootStyle = rootStyleDefault,
  ...rest
}) => {
  return (
    <VeneerFooter
      data-testid="footer"
      css={rootStyle}
      mainContent={{
        leadingArea: <div css={leadingAreaStyle}>{children}</div>,
        trailingArea: trailingArea,
      }}
      {...rest}
    ></VeneerFooter>
  );
};

export default Footer;
