class DocumentMedia {
  constructor({ id, mimeType, content, scale, numPages = 1 }) {
    this.id = id;
    this.mimeType = mimeType;
    this.content = content;
    this.scale = scale;
    this.numPages = numPages;
    this.source = null;
  }

  setSource(source) {
    this.source = source;
  }

  isImage() {
    return this.mimeType.indexOf("image/") !== -1;
  }
}

export default DocumentMedia;
