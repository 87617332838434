/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import useApi from "../../hooks/useApi";
import Context from "./FetchMarksContext";

const FetchMarksProvider = ({ children, props = {} }) => {
  const {
    data: markups,
    hasSessionToken,
    startFetch: loadMarkups,
    isFetching: isLoading,
  } = useApi("marks");

  const { startFetch: deleteMarkup, isFetching: isDeleting } = useApi(
    "marks",
    "DELETE"
  );

  useEffect(() => {
    if (hasSessionToken) {
      loadMarkups();
    }
  }, [hasSessionToken]);

  const deleteMarkups = async (selectedIds) => {
    await Promise.all(selectedIds.map((id) => deleteMarkup({}, id)));

    loadMarkups();
  };

  const value = {
    markups,
    isLoading,
    isDeleting,
    loadMarkups,
    deleteMarkups,
    ...props,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default FetchMarksProvider;
