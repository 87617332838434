/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const rootStyle = css`
  display: inline-block;
  fill: #212121;
  height: 24px;
  width: 24px;
  padding: 0;
`;

const ThinStrokeIcon = (props) => {
  return (
    <svg
      css={rootStyle}
      fill="currentColor"
      focusable={false}
      version="1.1"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M552.2,1216.8c-1.077,0-3.379,4-7.5,4a8.442,8.442,0,0,1-7.5-4,8.442,8.442,0,0,0-7.5-4c-4.121,0-6.46,4-7.5,4"
        transform="translate(-517.2 -1196.8)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
      />
      <path d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" fill="none" />
    </svg>
  );
};

ThinStrokeIcon.displayName = "ThinStrokeIcon";

export default ThinStrokeIcon;
