/**
 * Defines a context (a page or part of a page)
 * All labels MUST start with [markup.]
 */
const labels = {
  MARKUP_PLACE: "markup.place",
  MARKUP_CREATE: "markup.create",
  MARKUP_SELECT: "markup.select",
  GENERAL_NAVIGATION: "markup.navigation",
};

/**
 * Defines an action of a context
 */
const actions = {
  BACK: "back",
  ADD_A_NEW_MARK: "new-mark",
  NEW_MARK: "new-mark",
  SAVED_MARKS: "saved-marks",
  START_SCALING: "start-scaling",
  START_DRAWING: "start-drawing",
  DELETE: "delete",
  LIST: "list",
  FINISH: "finish",
  ADD: "add",
  SAVE_THICKNESS_THIN: "save.thickness.thin",
  SAVE_THICKNESS_MEDIUM: "save.thickness.medium",
  SAVE_THICKNESS_THICK: "save.thickness.thick",
  HISTORY_UNDO: "history.undo",
  HISTORY_REDO: "history.redo",
  HISTORY_DELETE: "history.delete",
  EDIT: "edit",
  CANCEL: "cancel",
  PAGE_PAGE_VIEW: "page.page.view",
  PAGE_TILES_VIEW: "page.tiles.view",
};

/**
 * Defines custom behaviors
 */
export const data = {
  /**
   * General
   */
  navigation: {
    GENERAL_NAVIGATION_BACK: (path) => ({
      label: `${labels.GENERAL_NAVIGATION}.${path}`,
      action: actions.BACK,
    }),
  },
  /**
   * MarkupApplyScreenContainer
   */
  MARKUP_PLACE_DELETE_MARK: {
    label: labels.MARKUP_PLACE,
    action: actions.DELETE,
  },
  MARKUP_PLACE_FINISH: {
    label: labels.MARKUP_PLACE,
    action: actions.FINISH,
  },
  MARKUP_PLACE_HISTORY_UNDO: {
    label: labels.MARKUP_PLACE,
    action: actions.HISTORY_UNDO,
  },
  MARKUP_PLACE_HISTORY_REDO: {
    label: labels.MARKUP_PLACE,
    action: actions.HISTORY_REDO,
  },
  MARKUP_PLACE_ADD_A_NEW_MARK: {
    label: labels.MARKUP_PLACE,
    action: actions.ADD_A_NEW_MARK,
  },
  MARKUP_PLACE_NEW_MARK: {
    label: labels.MARKUP_PLACE,
    action: actions.NEW_MARK,
  },
  MARKUP_PLACE_SAVED_MARKS: {
    label: labels.MARKUP_PLACE,
    action: actions.SAVED_MARKS,
  },
  MARKUP_PLACE_START_SCALING: {
    label: labels.MARKUP_PLACE,
    action: actions.START_SCALING,
  },

  /**
   * MarkupCreateScreenContainer
   */
  MARKUP_CREATE_SAVE_THICKNESS_THIN: {
    label: labels.MARKUP_CREATE,
    action: actions.SAVE_THICKNESS_THIN,
  },
  MARKUP_CREATE_SAVE_THICKNESS_MEDIUM: {
    label: labels.MARKUP_CREATE,
    action: actions.SAVE_THICKNESS_MEDIUM,
  },
  MARKUP_CREATE_SAVE_THICKNESS_THICK: {
    label: labels.MARKUP_CREATE,
    action: actions.SAVE_THICKNESS_THICK,
  },
  MARKUP_CREATE_HISTORY_UNDO: {
    label: labels.MARKUP_CREATE,
    action: actions.HISTORY_UNDO,
  },
  MARKUP_CREATE_HISTORY_REDO: {
    label: labels.MARKUP_CREATE,
    action: actions.HISTORY_REDO,
  },
  MARKUP_CREATE_HISTORY_DELETE: {
    label: labels.MARKUP_CREATE,
    action: actions.HISTORY_DELETE,
  },
  MARKUP_CREATE_ADD: {
    label: labels.MARKUP_CREATE,
    action: actions.ADD,
  },
  MARKUP_CREATE_START_DRAWING: {
    label: labels.MARKUP_CREATE,
    action: actions.START_DRAWING,
  },

  /**
   * MarkupListScreenContainer
   */
  MARKUP_SELECT_EDIT: {
    label: labels.MARKUP_SELECT,
    action: actions.EDIT,
  },
  MARKUP_SELECT_CANCEL: {
    label: labels.MARKUP_SELECT,
    action: actions.CANCEL,
  },
  MARKUP_SELECT_ADD: {
    label: labels.MARKUP_SELECT,
    action: actions.ADD,
  },
  MARKUP_SELECT_DELETE: {
    label: labels.MARKUP_SELECT,
    action: actions.DELETE,
  },
  MARKUP_SELECT_LIST: {
    label: labels.MARKUP_SELECT,
    action: actions.LIST,
  },
  /**
   * PageSelectionView
   */
  PAGE_SELECT_PAGE_VIEW: {
    label: labels.MARKUP_SELECT,
    action: actions.PAGE_PAGE_VIEW,
  },
  PAGE_SELECT_TILES_VIEW: {
    label: labels.MARKUP_SELECT,
    action: actions.PAGE_TILES_VIEW,
  },
};
