/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import Screen from "../../../components/Screen";
import ViewModeSwitch from "../../../components/ViewModeSwitch";
import { FULL_VIEW_MODE, TILES_VIEW_MODE } from "../../../constants";
import { useJarvisContext } from "../../../contexts/Jarvis";
import { usePlaceMarkContext } from "../../../contexts/PlaceMark";
import useDevice from "../../../hooks/useDevice";
import useMediaControls from "../../../hooks/useMediaControls";
import { sendInternalEvent } from "../../../plugins/analytics";
import { refs } from "../../../plugins/analytics/refs";
import { paths } from "../../../route.settings";
import FullPageSelectionContainer from "../FullPageSelectionContainer";
import TilePageSelectionContainer from "../TilePageSelectionContainer";

const DocumentPageSelectionScreenContainer = () => {
  const { t } = useTranslation();
  const { setSelectedPage, viewMode, setViewMode } = usePlaceMarkContext();
  const { isDesktop } = useDevice();
  const { numPages } = useMediaControls();
  const { closeInstance } = useJarvisContext();
  const history = useHistory();

  const onSelectPage = () => {
    if (isDesktop) {
      history.replace(paths.MARKUPS_APPLY);
    } else {
      history.push(paths.MARKUPS_APPLY);
    }
  };

  const onHistoryBack = () => {
    history.goBack();
    closeInstance({ resultData: { source: "backButton" } });
  };

  const onFullClick = () => {
    sendInternalEvent(refs.PAGE_SELECT_PAGE_VIEW);
    setViewMode(FULL_VIEW_MODE);
  };

  const onTilesClick = () => {
    sendInternalEvent(refs.PAGE_SELECT_TILES_VIEW);
    setViewMode(TILES_VIEW_MODE);
  };

  if (numPages === 1) {
    setSelectedPage(1);
    return <Redirect to={paths.MARKUPS_APPLY} />;
  }

  const isFullView = viewMode === FULL_VIEW_MODE;
  if (isFullView && isDesktop) {
    return <Redirect to={paths.MARKUPS_APPLY} />;
  }

  const selectionContainer = isFullView ? (
    <FullPageSelectionContainer onSelectPage={onSelectPage} />
  ) : (
    <TilePageSelectionContainer onSelectPage={onSelectPage} />
  );

  return (
    <Screen
      scrollable={false}
      title={t("page.document_page_selection.title")}
      body={
        <>
          <ViewModeSwitch
            currentView={viewMode}
            onFullClick={onFullClick}
            onTilesClick={onTilesClick}
          />
          {selectionContainer}
        </>
      }
      onHistoryBack={onHistoryBack}
    />
  );
};

export default DocumentPageSelectionScreenContainer;
