/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const rootStyle = css`
  display: inline-block;
  fill: #212121;
  height: 24px;
  width: 24px;
  padding: 0;
`;

const ScribbleIcon = (props) => {
  return (
    <svg
      css={rootStyle}
      fill="currentColor"
      focusable={false}
      version="1.1"
      viewBox="0 0 40 40"
      {...props}
    >
      <path
        stroke="#4e4e4e"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        strokeLinecap="round"
        fill="none"
        d="m0.19403,32.67705s34.57021,-50.12416 24.95394,-24.40074s-10.90661,37.8564 0,25.72342s14.82217,-6.92216 14.82217,-1.68157"
      />
    </svg>
  );
};

ScribbleIcon.displayName = "ScribbleIcon";

export default ScribbleIcon;
