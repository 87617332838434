import { Button } from "@veneer/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Footer from "../../../components/Footer";
import MarkupList from "../../../components/MarkupList";
import MarkupsGridListItemContent from "../../../components/MarkupsGridListItemContent";
import Screen from "../../../components/Screen";
import { useFetchMarks } from "../../../contexts/FetchMarks";
import { usePlaceMarkContext } from "../../../contexts/PlaceMark";
import useMarkupSelection from "../../../hooks/useMarkupSelection";
import { sendInternalEvent } from "../../../plugins/analytics";
import { refs } from "../../../plugins/analytics/refs";
import { paths } from "../../../route.settings";

const MarkupsListScreenContainer = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isManaging, setIsManaging] = useState(false);
  const history = useHistory();

  const { placedMarksList, addMarkToPlacedList, removePlacedMarksById } =
    usePlaceMarkContext();
  const { selectedMarks, toggleMarkSelection, clearSelection } =
    useMarkupSelection();

  const { markups, isLoading, isDeleting, deleteMarkups } = useFetchMarks();

  const isDeleteButtonDisabled =
    Object.keys(selectedMarks).length === 0 || isDeleting;

  const onClickActionButton = useCallback(() => {
    setIsManaging(!isManaging);

    if (isManaging) {
      sendInternalEvent(refs.MARKUP_SELECT_CANCEL);
    } else {
      sendInternalEvent(refs.MARKUP_SELECT_EDIT);
    }
  }, [setIsManaging, isManaging]);

  const onClick = useCallback(
    ({ id }) => {
      const selectedMarkup = markups.find((markup) => markup.id === id);
      addMarkToPlacedList(selectedMarkup);
      sendInternalEvent(refs.MARKUP_SELECT_ADD);
      history.goBack();
    },
    [history, markups, addMarkToPlacedList]
  );

  const onClickNewMark = () => {
    history.replace(paths.MARKUPS_CREATE);
  };

  const onClickDelete = async () => {
    setLoading(true);

    const isMarkupBeingUsed = placedMarksList.some(
      (markup) => selectedMarks[markup.id]
    );

    if (isMarkupBeingUsed) {
      //TODO: add confirm dialog to show that the mark will be deleted from the canvas
    }

    const selectedIds = Object.keys(selectedMarks);
    removePlacedMarksById(...selectedIds);
    await deleteMarkups(selectedIds);
    sendInternalEvent(refs.MARKUP_SELECT_DELETE, {
      count: selectedIds.length,
    });

    setIsManaging(markups.length > selectedIds.length);
    clearSelection();
    setLoading(false);
  };

  const items = useMemo(
    () =>
      (markups || []).map((markup) => ({
        id: markup.id,
        content: <MarkupsGridListItemContent {...markup} />,
        selected: !!selectedMarks[markup.id],
        selectable: isManaging,
      })),
    [markups, isManaging, selectedMarks]
  );

  useEffect(() => {
    if ((markups || []).length) {
      sendInternalEvent(refs.MARKUP_SELECT_LIST, {
        count: markups.length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markups]);

  return (
    <Screen
      title={t("page.saved_marks.title")}
      actionButtonTitle={
        (isManaging && t("common.cancel")) || t("common.select")
      }
      onClickActionButton={onClickActionButton}
      body={
        <MarkupList
          items={items}
          isLoading={isLoading}
          selectable={isManaging}
          columns={2}
          selectedItems={selectedMarks}
          onClick={onClick}
          onToggleSelection={toggleMarkSelection}
          onClickNewMark={onClickNewMark}
        />
      }
      footer={
        isManaging && (
          <Footer>
            <Button
              disabled={isDeleteButtonDisabled}
              loading={loading}
              onClick={onClickDelete}
              data-testid="delete-selected-button"
            >
              {t("page.saved_marks.delete_selected")}
            </Button>
          </Footer>
        )
      }
    />
  );
};

export default MarkupsListScreenContainer;
