/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { IconPlus, IconX } from "@veneer/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DesktopSidebar from "../../../components/DesktopSidebar";
import IconButton from "../../../components/IconButton";
import ScribbleIcon from "../../../components/icons/ScribbleIcon";
import { useSidebarContext } from "../../../contexts/Sidebar/SidebarContext";
import { sendInternalEvent } from "../../../plugins/analytics";
import { refs } from "../../../plugins/analytics/refs";
import { paths } from "../../../route.settings";
import MarkupPanelContainer from "../MarkupPanelContainer/MarkupPanelContainer";

const rootStyle = css`
  height: 100%;
`;

const CREATE_MARKS_TAB_ID = 0;
const LIST_MARKS_TAB_ID = 1;
const MANAGE_MARKS_TAB_ID = 2;

const MarkupTabs = ({ styles = {} }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(1);
  const { setIsSidebarOpen } = useSidebarContext();

  const updateCurrentTab = (tabId) => {
    const targetTabId = tabId === currentTab ? -tabId : tabId;
    setCurrentTab(targetTabId);
    setIsSidebarOpen(targetTabId > 0);
  };

  const onNewMarkClick = () => {
    sendInternalEvent(refs.MARKUP_PLACE_NEW_MARK);
    history.push(paths.MARKUPS_CREATE);
  };

  const onSavedMarksClick = () => {
    updateCurrentTab(LIST_MARKS_TAB_ID);
    sendInternalEvent(refs.MARKUP_PLACE_SAVED_MARKS);
  };

  const onManageMarksClick = () => {
    updateCurrentTab(MANAGE_MARKS_TAB_ID);
    sendInternalEvent(refs.MARKUP_PLACE_MANAGE_MARKS);
  };

  const getTabButtonClassName = (tabId) => {
    return tabId === currentTab ? "is-active" : null;
  };

  return (
    <section data-testid="markup-tabs" css={[rootStyle, styles.root]}>
      <DesktopSidebar
        currentTabIndex={currentTab}
        onTabChange={setCurrentTab}
        tabs={[
          {
            id: CREATE_MARKS_TAB_ID,
            labelComponent: (
              <IconButton
                data-testid="new-mark-button"
                data-coachmark="new-mark-button"
                appearance="ghost"
                leadingIcon={<IconPlus />}
                onClick={onNewMarkClick}
                label={t("tabs.create")}
              />
            ),
          },
          {
            id: LIST_MARKS_TAB_ID,
            labelComponent: (
              <IconButton
                data-testid="place-mark-button"
                data-coachmark="place-mark-button"
                appearance="ghost"
                leadingIcon={<ScribbleIcon />}
                className={getTabButtonClassName(LIST_MARKS_TAB_ID)}
                onClick={onSavedMarksClick}
                label={t("tabs.marks")}
              />
            ),
            content: (
              <MarkupPanelContainer
                dataTestId="markup-list-select-marks"
                manageMode={false}
              />
            ),
          },
          {
            id: MANAGE_MARKS_TAB_ID,
            labelComponent: (
              <IconButton
                data-testid="manage-marks-button"
                data-coachmark="manage-marks-button"
                className={getTabButtonClassName(MANAGE_MARKS_TAB_ID)}
                onClick={onManageMarksClick}
                appearance="ghost"
                leadingIcon={<IconX />}
                label={t("tabs.manage")}
              />
            ),
            content: (
              <MarkupPanelContainer
                dataTestId="markup-panel-manage-marks"
                manageMode={true}
              />
            ),
          },
        ]}
      />
    </section>
  );
};

export default MarkupTabs;
