/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import DOMPurify from "dompurify";

const svgWrapper = css`
  object-fit: contain;
  width: 100%;
`;

const markupWrapper = css`
  max-width: 100%;
  max-height: 100%;
  display: flex;
`;

const aspectRatioStyle = {
  "--aspect-ratio": 16 / 9,
};

const MarkupsGridListItemContent = ({ markSVG }) => {
  const base64SVG = window.btoa(DOMPurify.sanitize(markSVG));
  const src = `data:image/svg+xml;base64,${base64SVG}`;
  return (
    <div css={markupWrapper} style={aspectRatioStyle}>
      <img src={src} css={svgWrapper} alt="" draggable={false} />
    </div>
  );
};

export default MarkupsGridListItemContent;
