/** @jsxImportSource @emotion/react */

import { memo, useMemo } from "react";
import { IconUndo, IconRedo, IconTrash } from "@veneer/core";

import IconButton from "../IconButton";
import { css } from "@emotion/react";
import { tokens } from "../../css.settings";

const rootStyle = css`
  display: flex;
  flex: 1;
`;

const rightAreaStyle = css`
  margin-left: auto;
  display: flex;
`;

const pointerEventsStyle = css`
  pointer-events: auto;
`;

const rightTrashStyle = css`
  margin-left: 24px;
`;

const buttonStyle = {
  button: css`
    background-color: ${tokens.color.gray0};
  `,
};

const ActionButtons = ({
  onUndo,
  onRedo,
  onTrash,
  canUndo = false,
  canRedo = false,
  canTrash = false,
  small = true,
  variant = "mobile",
  styles = {},
}) => {
  const showRightTrash = useMemo(
    () => onTrash && variant === "desktop",
    [onTrash, variant]
  );
  const showLeftTrash = useMemo(
    () => onTrash && variant === "mobile",
    [onTrash, variant]
  );

  return (
    <div data-testid="action-buttons" css={[rootStyle, styles]}>
      {showLeftTrash && (
        <div css={[styles.leadingArea, pointerEventsStyle]}>
          <IconButton
            data-testid="trash-button"
            aria-disabled={!canTrash}
            disabled={!canTrash}
            appearance="ghost"
            leadingIcon={<IconTrash />}
            onClick={onTrash}
            small={small}
            styles={buttonStyle}
          />
        </div>
      )}
      {(onUndo || onRedo) && (
        <div css={[rightAreaStyle, pointerEventsStyle, styles.trailingArea]}>
          {onUndo && (
            <IconButton
              data-testid="undo-button"
              aria-disabled={!canUndo}
              disabled={!canUndo}
              appearance="ghost"
              leadingIcon={<IconUndo />}
              onClick={onUndo}
              small={small}
              styles={buttonStyle}
            />
          )}
          {onRedo && (
            <IconButton
              data-testid="redo-button"
              aria-disabled={!canRedo}
              disabled={!canRedo}
              appearance="ghost"
              leadingIcon={<IconRedo />}
              onClick={onRedo}
              small={small}
              styles={buttonStyle}
            />
          )}
          {showRightTrash && (
            <IconButton
              data-testid="trash-button"
              aria-disabled={!canTrash}
              disabled={!canTrash}
              appearance="ghost"
              css={rightTrashStyle}
              leadingIcon={<IconTrash />}
              onClick={onTrash}
              small={small}
              styles={buttonStyle}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default memo(ActionButtons);
