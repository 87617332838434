import { Rectangle } from "../../math";
import { IdleState } from "./idle-state";
import {
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  TOP_LEFT,
  TOP_RIGHT,
} from "../../../constants";
import { ViewportEvents } from "../viewport-events";

export class ScalingState {
  constructor(controller, scalingCorner, cursorIcon) {
    this.controller = controller;
    this.scaleRectangle = this.getScalingStrategy(scalingCorner);
    this.aspectRatio = this.controller.markup.getAspectRatio();
    this.cursorIcon = cursorIcon;
  }

  getScalingStrategy(scalingCorner) {
    const scalingStrategies = {
      [BOTTOM_LEFT]: this._scaleBottomLeft,
      [BOTTOM_RIGHT]: this._scaleBottomRight,
      [TOP_LEFT]: this._scaleTopLeft,
      [TOP_RIGHT]: this._scaleTopRight,
    };
    return scalingStrategies[scalingCorner];
  }

  getCursorIcon(_mouseX, _mouseY) {
    return this.cursorIcon;
  }

  _scaleBottomLeft(markup, dx, _dy) {
    markup.x += dx;
    markup.width -= dx;
    markup.height = markup.width / this.aspectRatio;
  }

  _scaleBottomRight(markup, dx, _dy) {
    markup.width += dx;
    markup.height = markup.width / this.aspectRatio;
  }

  _scaleTopLeft(markup, dx, _dy) {
    markup.width -= dx;
    markup.height = markup.width / this.aspectRatio;
    markup.x += dx;
    markup.y += dx / this.aspectRatio;
  }

  _scaleTopRight(markup, _dx, dy) {
    markup.height -= dy;
    markup.width = markup.height * this.aspectRatio;
    markup.y += dy;
  }

  handle(transform) {
    const markup = this.controller.markup;
    const { width, height } = markup.viewport.element;
    const relativeX = transform.x / transform.viewportScale;
    const relativeY = transform.y / transform.viewportScale;
    const viewport = new Rectangle(0, 0, width, height);
    const nextScaleRect = markup.clone();

    this.scaleRectangle(nextScaleRect, relativeX, relativeY);
    nextScaleRect.fitTo(viewport);

    const scaleDelta = nextScaleRect.width / markup.width;
    const newScale = markup.scale * scaleDelta;
    const isValidScale =
      newScale >= markup.minScale && newScale <= markup.maxScale;

    if (nextScaleRect.isInside(viewport) && isValidScale) {
      markup.setX(nextScaleRect.x);
      markup.setY(nextScaleRect.y);
      markup.setScale(newScale);
    }
  }

  handleStop() {
    this.controller.currentState = new IdleState(this.controller);
    this.controller.markup.viewport.eventSystem.emit(
      ViewportEvents.MARKUP_SCALE
    );
  }
}
