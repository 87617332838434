import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend) // load translation using http -> see /public/locales
  .init({
    backend: {
      requestOptions: {
        cache: "no-store",
      },
      queryStringParams: { time: Date.now() },
    },
    lng: "en_US",
    fallbackLng: "en_US",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
