/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useHistory } from "react-router";
import FullScreenCircularIndicator from "../components/FullScreenCircularIndicator";
import { useSessionContext } from "../contexts/Session";
import useAnalytics from "../hooks/useAnalytics";
import useDevice from "../hooks/useDevice";
import useQuery from "../hooks/useQuery";
import useSetup from "../hooks/useSetup";
import { sendLoginEvent } from "../plugins/analytics";
import { paths } from "../route.settings";

const LoginContainer = () => {
  const query = useQuery();
  const history = useHistory();
  const { applyAnalyticsCookies } = useAnalytics();
  const { isDesktop } = useDevice();

  const abortApplication = () => {
    if (callbackSchema) {
      history.replace(callbackSchema);
    } else {
      history.goBack();
    }
  };

  const {
    isReady,
    sessionData,
    sessionError,
    selectedDocument,
    loadMedia,
    applyAppLocale,
    loadSession,
  } = useSetup({ onError: abortApplication });

  const { callbackSchema, setCallbackSchema, setSession } = useSessionContext();

  useEffect(() => {
    setCallbackSchema(null);
    loadSession(query.session_id);
  }, []);

  useEffect(() => {
    if (sessionData) {
      const { documentUrl, langCountryCode, analyticsInfo } = sessionData;
      setSession(sessionData);
      loadMedia(documentUrl);
      applyAppLocale(langCountryCode.replace("-", "_"));
      applyAnalyticsCookies(analyticsInfo);
    } else if (sessionError) {
      abortApplication();
    }
  }, [sessionData, sessionError]);

  useEffect(() => {
    if (isReady && selectedDocument) {
      sendLoginEvent();
      history.replace(isDesktop ? paths.MARKUPS_APPLY : paths.DOCUMENT_SELECT);
    }
  }, [isReady, selectedDocument]);

  return <FullScreenCircularIndicator />;
};

export default LoginContainer;
