export default class Circle {
  constructor(x, y, radius) {
    this.x = x;
    this.y = y;
    this.radius = radius;
  }

  isPointInside(x, y) {
    const deltaX = x - this.x;
    const deltaY = y - this.y;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY) <= this.radius;
  }
}
