import SignaturePad from "signature_pad";

class Point {
  constructor(x, y, time) {
    this.x = x;
    this.y = y;
    this.time = time || Date.now();
  }
  distanceTo(start) {
    return Math.sqrt(
      Math.pow(this.x - start.x, 2) + Math.pow(this.y - start.y, 2)
    );
  }
  equals(other) {
    return this.x === other.x && this.y === other.y && this.time === other.time;
  }
  velocityFrom(start) {
    return this.time !== start.time
      ? this.distanceTo(start) / (this.time - start.time)
      : 0;
  }
}

export class SignaturePadExtension extends SignaturePad {
  _createPoint = function (x, y, time) {
    const rect = this.canvas.getBoundingClientRect();
    x -= rect.left;
    y -= rect.top;

    // point must always be inside the canvas
    // never negative and bigger than rect dimensions
    x = x < 0 ? 0 : Math.min(x, rect.width);
    y = y < 0 ? 0 : Math.min(y, rect.height);

    return new Point(x, y, time || new Date().getTime());
  };
}
