export { default as resizeImage, MAX_AREA } from "./resize-image";

export const getImage = (source) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.crossOrigin = "anonymous";
    img.src = source;
  });
};

export const isImage = (element) => {
  return element instanceof Image;
};

export const loadImages = async (images) => {
  const promises = images.map(async (source) => {
    source.img = isImage(source.src) ? source.src : await getImage(source.src);
    return source;
  });
  return await Promise.all(promises);
};
