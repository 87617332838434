export default class MergeMethod {
  get targetMimeTypes() {
    return [];
  }

  canMergeMimeType(mimeType) {
    return this.targetMimeTypes.some((mime) => mime === mimeType);
  }

  async merge(payload) {}
}
