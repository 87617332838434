/** @jsxImportSource @emotion/react */

import IconButton from "../IconButton";
import { IconImageEditView, IconTiles } from "@veneer/core";
import { css } from "@emotion/react";
import { tokens } from "../../css.settings";

const rootStyle = css`
  display: flex;
  z-index: 2;
`;

const iconButtonStyle = css`
  border: none;
`;

const activeIconStyle = css`
  color: ${tokens.color.hpBlue6} !important;
`;

const ViewModeSwitch = ({
  onFullClick,
  onTilesClick,
  currentView = "full",
  canFullClick = true,
  canTilesClick = true,
}) => {
  const isFullMode = currentView === "full";

  return (
    <div css={rootStyle} data-testid="view-mode-switch">
      <IconButton
        styles={{
          button: [iconButtonStyle, isFullMode && activeIconStyle],
        }}
        data-testid="view-mode-switch-button-full"
        appearance="ghost"
        leadingIcon={<IconImageEditView />}
        disabled={!canFullClick}
        onClick={onFullClick}
        small={true}
      />
      <IconButton
        styles={{ button: [iconButtonStyle, !isFullMode && activeIconStyle] }}
        data-testid="view-mode-switch-button-tiles"
        appearance="ghost"
        leadingIcon={<IconTiles />}
        disabled={!canTilesClick}
        onClick={onTilesClick}
        small={true}
      />
    </div>
  );
};

export default ViewModeSwitch;
