// Media viewport contants
export const IMAGE_VIEWPORT_SCALE = 1;
export const PDF_VIEWPORT_SCALE = 4;
export const PDF_VIEWPORT_SCALE_IOS = 2;
export const THUMBNAIL_QUALITY = 2 / 100;

// Markup corner indexes
export const NONE = -1;
export const BOTTOM_LEFT = 0;
export const BOTTOM_RIGHT = 1;
export const TOP_LEFT = 2;
export const TOP_RIGHT = 3;

// Markup mouse cursor icon mapping
export const MOVE_CURSOR = "move";
export const DEFAULT_CURSOR = "auto";
export const CURSOR_MAPPING = {
  [TOP_LEFT]: "nw-resize",
  [BOTTOM_LEFT]: "sw-resize",
  [BOTTOM_RIGHT]: "nw-resize",
  [TOP_RIGHT]: "sw-resize",
};

// Markup corner size percentage relative to min(width, height)
export const CORNER_RATIO = 0.2;

// Markup scaling constants
export const MARKUP_VIEWPORT_ADJUSTMENT = 0.8;
export const MARKUP_MIN_SCALE = 0.1;
export const MARKUP_MAX_SCALE = 200;

// Renderable scaling defaults
export const RENDERABLE_MIN_SCALE = 0.5;
export const RENDERABLE_MAX_SCALE = 16;
export const RENDERABLE_DEFAULT_SCALE = 1;
export const RENDERABLE_CHANGE_SCALE = 0.1;

// Viewport controller constants
export const ZOOM_WHEEL_FACTOR = 10 / 100;
export const ZOOM_BUTTON_FACTOR = 50 / 100;
export const PINCH_WAIT_TIME = 100;
export const PAN_WAIT_TIME = 100;

// Viewport initial position constants
export const Y_BOTTOM_MARGIN = 0.25;

// View mode
export const TILES_VIEW_MODE = "tiles";
export const FULL_VIEW_MODE = "full";

// Texbox top padding
export const TEXTBOX_TOP_PADDING = 42;

// Media Query min-width thumbnail visible breakpoint
export const BREAKPOINT_LEFT_SIDEBAR_VISIBLE = 786;
