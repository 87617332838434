/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useMemo } from "react";
import PageThumbnail from "../../../components/PageThumbnail";
import { usePlaceMarkContext } from "../../../contexts/PlaceMark";
import useThumbnailGenerator from "../../../hooks/useThumbnailGenerator";
import useDevice from "../../../hooks/useDevice";

const ThumbnailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: calc(100vh - 120px);

  /* Hide Sidebar */
  -ms-overflow-style: none; /* Edge */
  ::-webkit-scrollbar {
    display: none; /* Safari & Chrome */
  }
`;

const TilePageSelectionContainer = ({ onSelectPage }) => {
  const { setSelectedPage, setSelectedDocument } = usePlaceMarkContext();
  const { thumbnails } = useThumbnailGenerator();
  const { isMobile } = useDevice();

  const onSelect = (document, page) => {
    setSelectedDocument(document);
    setSelectedPage(page);
    onSelectPage();
  };

  const thumbnailSize = useMemo(() => (isMobile ? 140 : 164), [isMobile]);

  const thumbnailElements = useMemo(
    () =>
      thumbnails.map((thumbnail, index) => (
        <PageThumbnail
          size={thumbnailSize}
          index={index}
          source={thumbnail.content}
          key={`thumbnail_${index}`}
          isSelected={thumbnail.isSelected}
          isEdited={thumbnail.isEdited}
          pageNumber={thumbnail.globalPageIndex}
          onClick={() => onSelect(thumbnail.mediaDocument, thumbnail.page)}
        />
      )),
    [thumbnails, thumbnailSize]
  );

  return (
    <ThumbnailWrapper data-testid="tile-page-selection">
      {thumbnailElements}
    </ThumbnailWrapper>
  );
};

export default TilePageSelectionContainer;
