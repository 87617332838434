import { analyticsMediator } from "./analytics-mediator";
import {
  InternalEvent,
  InternalExtraData,
  PageViewEvent,
  UserLoginEvent,
} from "./events";
import { refs } from "./refs";

export const sendInternalEvent = (label = "", extra = {}) => {
  analyticsMediator.sendEvent(
    new InternalEvent({
      label,
      extra: new InternalExtraData(extra),
    })
  );
};

export const sendPageView = () => {
  const location = window.location.href;
  analyticsMediator.sendEvent(new PageViewEvent({ location }));
};

export const sendLoginEvent = () => {
  const location = window.location.href;
  analyticsMediator.sendEvent(new UserLoginEvent({ location }));
};

export const sendPlanEvent = async (plan) => {
  // Works only for CDM for now (UDL will not handle this plan info yet)
  // you can fix this by removing the shouldSkipUdl param
  analyticsMediator.sendEvent(
    new InternalEvent({ label: refs.PLAN, extra: plan, shouldSkipUdl: true })
  );
};
