import { useMemo, useState } from "react";

import { Random } from "../../utils/math";
import {
  AddMarkupCommand,
  RemoveMarkupCommand,
  UpdateMarkupCommand,
} from "./PlaceMarkCommands";
import Context from "./PlaceMarkContext";

const PlaceMarkProvider = ({ children, props = {} }) => {
  const [placedMarksList, setPlacedMarksList] = useState([]);
  const [commandHistory, setCommandHistory] = useState([]);
  const [currentCommand, setCurrentCommand] = useState(-1);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [viewMode, setViewMode] = useState("full");

  const addAndExecuteCommand = (command) => {
    const sliceSize = currentCommand + 1;
    setCommandHistory((history) => [...history.slice(0, sliceSize), command]);
    setCurrentCommand(sliceSize);
    command.execute();
  };

  const removePlacedMark = (uid) => {
    const markup = placedMarksList.find((mark) => mark.uid === uid);
    if (!markup) return;
    addAndExecuteCommand(RemoveMarkupCommand(setPlacedMarksList, markup));
  };

  const addMarkToPlacedList = (markup) => {
    const markupToAdd = Object.assign({}, markup, {
      id: `${markup.id}`,
      uid: Random.getId(),
      isNew: true,
      page: selectedPage,
      document: selectedDocument,
    });

    addAndExecuteCommand(AddMarkupCommand(setPlacedMarksList, markupToAdd));
  };

  const updateMarkPosition = ({ uid, x, y, yFromBottom, scale }) => {
    const markup = placedMarksList.find((mark) => mark.uid === uid);
    if (!markup) return;

    const markupArgs = {
      x,
      y,
      yFromBottom,
      scale,
      isNew: false,
    };

    const command = UpdateMarkupCommand(setPlacedMarksList, markup, markupArgs);

    if (markup.isNew) {
      return command.execute();
    }

    addAndExecuteCommand(command);
  };

  const removePlacedMarksById = (...ids) => {
    setPlacedMarksList((markups) =>
      markups.filter((markup) => !ids.includes(markup.id))
    );

    setCommandHistory((commands) => {
      let newCurrentCommand = null;
      const filteredHistory = commands.filter((command, index) => {
        const shouldKeep = !ids.includes(command.markupId);

        if (shouldKeep && index <= currentCommand) {
          newCurrentCommand = command;
        }

        return shouldKeep;
      });

      setCurrentCommand(filteredHistory.indexOf(newCurrentCommand));
      return filteredHistory;
    });
  };

  const placedMarks = useMemo(() => {
    return placedMarksList.filter(
      ({ document, page }) =>
        document === selectedDocument && page === selectedPage
    );
  }, [selectedDocument, selectedPage, placedMarksList]);

  const canUndo = currentCommand >= 0;
  const canRedo = currentCommand < commandHistory.length - 1;
  const hasMarkups = placedMarksList.length > 0;

  const matchDocument = (document, page) => {
    const sameDocument = document === selectedDocument;
    const samePage = page === selectedPage;

    !sameDocument && setSelectedDocument(document);
    !samePage && setSelectedPage(page);

    return sameDocument && samePage;
  };

  const undo = () => {
    if (!canUndo) return;
    const command = commandHistory[currentCommand];

    if (matchDocument(command.document, command.page)) {
      command.undo();
      setCurrentCommand((current) => current - 1);
    }
  };

  const redo = () => {
    if (!canRedo) return;
    const command = commandHistory[currentCommand + 1];

    if (matchDocument(command.document, command.page)) {
      command.execute();
      setCurrentCommand((current) => current + 1);
    }
  };

  const value = {
    undo,
    redo,
    viewMode,
    canRedo,
    canUndo,
    hasMarkups,
    setViewMode,
    placedMarks,
    placedMarksList,
    addMarkToPlacedList,
    removePlacedMark,
    removePlacedMarksById,
    updateMarkPosition,
    selectedDocument,
    selectedPage,
    numberOfPages,
    setSelectedDocument,
    setSelectedPage,
    setNumberOfPages,
    ...props,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default PlaceMarkProvider;
