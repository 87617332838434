/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useRef } from "react";
import { useGridContext } from "../Grid";
import GridRow from "../GridRow";
import GridListColumn from "../GridListColumn";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import useDevice from "../../hooks/useDevice";
import useVisible from "../../hooks/useVisible";

const GridListRow = ({
  items = [],
  colsSmall = 1,
  colsMedium = 1,
  colsLarge = 1,
  ...rest
}) => {
  const { isDesktop } = useDevice();
  const { columns } = useGridContext();
  const gridRowRef = useRef();
  const isGridRowVisible = useVisible(gridRowRef);

  const ITEMS_COUNT = items.length;
  const ITEMS_PER_ROW = isDesktop ? 1 : 2;

  const [itemSize, setItemSize] = useState();

  const colSizes = useMemo(
    () => ({
      small: columns / Math.min(colsSmall, columns),
      medium: columns / Math.min(colsMedium, columns),
      large: columns / Math.min(colsLarge, columns),
    }),
    [colsSmall, colsMedium, colsLarge, columns]
  );

  const calcItemSize = () => {
    const factor = isDesktop ? 1.5 : 3;
    const width = gridRowRef.current.offsetWidth;
    return width / factor;
  };

  const onResize = (e) => {
    setItemSize(calcItemSize());
  };

  useEffect(() => {
    if (isGridRowVisible) {
      setItemSize(calcItemSize());
    }
  }, [isGridRowVisible]);

  useEffect(() => {
    setItemSize(calcItemSize());

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <GridRow
      ref={gridRowRef}
      data-testid={rest["data-testid"] || "grid-list-row"}
    >
      <AutoSizer>
        {({ height, width }) => {
          const rowCount = Math.ceil(ITEMS_COUNT / ITEMS_PER_ROW);
          return (
            <FixedSizeList
              id={"grid-list-row-markups"}
              data-testid={"grid-list-row-markups"}
              height={height}
              itemCount={rowCount}
              itemSize={itemSize}
              width={width}
            >
              {({ index, style }) => {
                const rowItems = [];
                const convertedIndex = index * ITEMS_PER_ROW;

                for (
                  let i = convertedIndex;
                  i < convertedIndex + ITEMS_PER_ROW;
                  i++
                ) {
                  if (items[i]) {
                    rowItems.push(
                      <GridListColumn
                        key={items[i].id}
                        {...items[i]}
                        {...colSizes}
                        customStyle={{
                          width: isDesktop ? "100%" : "50%",
                        }}
                      />
                    );
                  }
                }

                return (
                  <div
                    className="Row"
                    style={{
                      ...style,
                      display: isDesktop ? "block" : "flex",
                    }}
                  >
                    {rowItems}
                  </div>
                );
              }}
            </FixedSizeList>
          );
        }}
      </AutoSizer>
    </GridRow>
  );
};

export default GridListRow;
