import { useState } from "react";

const useMarkupSelection = () => {
  const [selectedMarks, setSelectedMarks] = useState({});

  const toggleMarkSelection = (markId) => {
    setSelectedMarks((currentState) => {
      const selectedMarks = { ...currentState };
      if (selectedMarks[markId]) {
        delete selectedMarks[markId];
      } else {
        selectedMarks[markId] = true;
      }
      return selectedMarks;
    });
  };

  const removeMarksFromSelection = (markIds) => {
    setSelectedMarks((currentState) => {
      const selectedMarks = { ...currentState };
      markIds.forEach((markId) => {
        delete selectedMarks[markId];
      });
      return selectedMarks;
    });
  };

  const clearSelection = () => {
    removeMarksFromSelection(Object.keys(selectedMarks));
  };

  return {
    selectedMarks,
    toggleMarkSelection,
    removeMarksFromSelection,
    clearSelection,
  };
};

export default useMarkupSelection;
