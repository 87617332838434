/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import PageSelectionSidebar from "../../../components/PageSelectionSidebar/PageSelectionSidebar";
import PageThumbnail from "../../../components/PageThumbnail";
import { TILES_VIEW_MODE } from "../../../constants";
import { usePlaceMarkContext } from "../../../contexts/PlaceMark";
import useThumbnailGenerator from "../../../hooks/useThumbnailGenerator";
import { paths } from "../../../route.settings";
import { sendInternalEvent } from "../../../plugins/analytics";
import { refs } from "../../../plugins/analytics/refs";

const PageSelectionSidebarContainer = () => {
  const history = useHistory();
  const { thumbnails } = useThumbnailGenerator();
  const { setViewMode, setSelectedDocument, setSelectedPage } =
    usePlaceMarkContext();

  const onSelect = (document, page) => {
    setSelectedDocument(document);
    setSelectedPage(page);
  };

  const onTilesClick = () => {
    sendInternalEvent(refs.PAGE_SELECT_TILES_VIEW);
    setViewMode(TILES_VIEW_MODE);
    history.replace(paths.DOCUMENT_SELECT);
  };

  const thumbnailElements = useMemo(
    () =>
      thumbnails.map((thumbnail, index) => (
        <PageThumbnail
          source={thumbnail.content}
          key={`thumb_${index}`}
          isSelected={thumbnail.isSelected}
          isEdited={thumbnail.isEdited}
          pageNumber={thumbnail.globalPageIndex}
          onClick={() => onSelect(thumbnail.mediaDocument, thumbnail.page)}
        />
      )),
    [thumbnails]
  );

  return (
    <PageSelectionSidebar
      onTilesClick={onTilesClick}
      items={thumbnailElements}
    />
  );
};

export default PageSelectionSidebarContainer;
