import { Rectangle } from "../math";
import { RENDERABLE_MAX_SCALE, RENDERABLE_MIN_SCALE } from "../../constants";
export class Renderable extends Rectangle {
  constructor() {
    super(0, 0, 0, 0);
    this.minScale = RENDERABLE_MIN_SCALE;
    this.maxScale = RENDERABLE_MAX_SCALE;
    this.currentScale = 1;
    this.initialScale = 1;
  }

  render() {}

  applyTransformation(transform) {
    if (transform.type === "translation") {
      this.handleTranslation(transform);
    } else if (transform.type === "scale") {
      this.handleScaling(transform);
    }
  }

  onTransformationEnd(transform) {
    if (transform.type === "translation") {
      this.handleTranslationEnd(transform);
    } else if (transform.type === "scale") {
      this.handleScalingEnd(transform);
    }
  }

  handleTranslation(transform) {
    this.setX(this.x + transform.x);
    this.setY(this.y + transform.y);
  }

  handleTranslationEnd() {}

  handleScaling(transform) {
    let deltaScale = transform.scale;
    deltaScale *= transform.relative ? this.currentScale : 1;
    let relativeScale = deltaScale + this.currentScale;

    if (relativeScale >= this.maxScale) {
      deltaScale = this.maxScale - this.currentScale;
    } else if (relativeScale <= this.minScale) {
      deltaScale = this.minScale - this.currentScale;
    }

    const relativeX = (transform.centerX - this.x) / this.currentScale;
    const relativeY = (transform.centerY - this.y) / this.currentScale;
    this.setX(this.x - relativeX * deltaScale);
    this.setY(this.y - relativeY * deltaScale);
    this.setScale(this.currentScale + deltaScale);
  }

  handleScalingEnd() {}

  setX(value) {
    this.x = value;
  }

  setY(value) {
    this.y = value;
  }

  setScale(value) {
    this.currentScale = value;
  }

  setWidth(value) {
    this.width = value;
    if (this.element) this.element.width = value;
  }

  setHeight(value) {
    this.height = value;
    if (this.element) this.element.height = value;
  }

  get scalePercent() {
    return Math.trunc((this.currentScale / this.initialScale) * 100);
  }
}
