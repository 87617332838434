/** @jsxImportSource @emotion/react */
import { IconPencil } from "@veneer/core";
import { useTranslation } from "react-i18next";
import { tokens } from "../../css.settings";
import styled from "@emotion/styled";

const signatureLinesColor = tokens.color.hpBlue6;

const SignatureLinesWrapper = styled.div((props) => ({
  position: "absolute",
  top: `calc(50% - ${props.height / 2}px)`,
  left: `calc(50% - ${props.width / 2}px)`,
  pointerEvents: "none",
  opacity: props.isDirty ? 0.4 : 1,
  height: props.height,
  width: props.width,
}));

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  gap: 6px;
`;

const DrawMarkText = styled.h6`
  margin-top: 4px;
  font-size: 1.125rem;
`;

const SolidBorder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-top: 1px solid ${signatureLinesColor};
  border-bottom: 1px solid ${signatureLinesColor};
`;

const DottedBorder = styled.div`
  width: 100%;
  height: 50%;
  position: relative;
  border-bottom: 1px dotted ${signatureLinesColor};
`;

function SignatureLines({ height = 0, width = 0, isDirty }) {
  const { t } = useTranslation();

  return (
    <SignatureLinesWrapper
      height={height}
      width={width}
      isDirty={isDirty}
      data-testid="signature-lines-wrapper"
      data-coachmark="signature-lines"
    >
      <SolidBorder>
        <DottedBorder />
      </SolidBorder>
      <TextWrapper>
        <IconPencil />
        <DrawMarkText data-testid="draw-mark-text">
          {t("page.new_mark.draw_mark_here")}
        </DrawMarkText>
      </TextWrapper>
    </SignatureLinesWrapper>
  );
}

export default SignatureLines;
