class MultiDocumentMedia {
  constructor(...documents) {
    const [firstDocument] = documents;
    this.mimeType = firstDocument.mimeType;
    this.documents = documents;
  }

  get firstDocument() {
    const [firstDocument] = this.documents;
    return firstDocument;
  }

  get numPages() {
    return this.documents.reduce(
      (totalPages, doc) => totalPages + doc.numPages,
      0
    );
  }
}

export default MultiDocumentMedia;
