import { useState } from "react";
import axios from "axios";

import Context from "./MediaManagerContext";
import ImageMediaLoader from "./ImageMediaLoader";
import PdfMediaLoader from "./PdfMediaLoader";
import DocumentMedia from "./DocumentMedia";
import MultiDocumentMedia from "./MultiDocumentMedia";

const MediaManagerProvider = ({ children, props = {} }) => {
  const [media, setMedia] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadMedia = async (url) => {
    setMedia(null);
    setLoading(true);

    const mediaDocument = await fetchMedia(url);

    setMedia(new MultiDocumentMedia(mediaDocument));
    setLoading(false);
  };

  const fetchMedia = async (url) => {
    const res = await axios.get(url, { responseType: "arraybuffer" });
    const data = res.data;
    const contentType = res.headers["content-type"];

    const loaderMethod = _getLoaderMethodByMimeType(contentType);
    console.log(`loading an ${contentType} media`);

    const mediaDocument = await loaderMethod.loadMedia(data);
    mediaDocument.setSource(url);

    return mediaDocument;
  };

  const parseMediaDocuments = (documents) => {
    setLoading(true);
    const documentsMedia = documents.map(async (document) => {
      const loaderMethod = _getLoaderMethodByMimeType(document.mimeType);
      const { content, scale } = await loaderMethod.parseToMedia(document.data);

      const documentMedia = new DocumentMedia({
        id: document.id,
        mimeType: document.mimeType,
        content,
        scale,
      });
      documentMedia.setSource(document.data);

      return documentMedia;
    });
    Promise.all(documentsMedia).then((results) => {
      setLoading(false);
      setMedia(new MultiDocumentMedia(...results));
    });
  };

  const value = {
    media,
    loading,
    loadMedia,
    setMedia,
    parseMediaDocuments,
    ...props,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const _getLoaderMethodByMimeType = (mimeType) => {
  const availableLoaderMethods = [
    new ImageMediaLoader(mimeType),
    new PdfMediaLoader(mimeType),
  ];

  const loaderMethod = availableLoaderMethods.find((loaderCandidate) =>
    loaderCandidate.canMergeMimeType(mimeType)
  );

  if (!loaderMethod) {
    throw new Error(mimeType + " media type is not supported");
  }

  return loaderMethod;
};

export default MediaManagerProvider;
