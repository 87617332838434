/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef } from "react";

const rootStyle = css`
  min-width: 100%;
  margin-right: -12px;
  margin-left: -12px;
  height: 100%;
`;

const GridRow = ({ children, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      data-testid={rest["data-testid"] || "grid-row"}
      css={rootStyle}
    >
      {children}
    </div>
  );
};

export default forwardRef(GridRow);
