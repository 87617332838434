import { IdleState } from "./states";

export class MarkupController {
  constructor(markup) {
    this.markup = markup;
    this.currentState = new IdleState(this);
  }

  getCursorIcon(mouseX, mouseY) {
    return this.currentState.getCursorIcon(mouseX, mouseY);
  }

  handle(transform) {
    this.currentState.handle(transform);
  }

  handleStop() {
    this.currentState.handleStop();
  }
}
