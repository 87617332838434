import GridContext from "./GridContext";

const Grid = ({ columns = 12, gutter = 12, children }) => {
  return (
    <GridContext.Provider value={{ columns, gutter }}>
      {children}
    </GridContext.Provider>
  );
};

export default Grid;
