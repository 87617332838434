export class ViewportElement {
  constructor(viewport, parentElement) {
    this.viewport = viewport;
    this.parentElement = parentElement;
    this.node = document.createElement("div");
    this.parentElement.appendChild(this.node);
    this.node.style.transformOrigin = "top left";
    this.node.style.position = "absolute";
    this.node.style.pointerEvents = "none";
    this.node.style.borderStyle = "solid";
    this.node.style.borderColor = "#c9c9c9";
    this.viewportWidth = 0;
    this.viewportHeight = 0;
    this.x = 0;
    this.y = 0;
  }

  addChild(child) {
    this.node.appendChild(child);
  }

  removeChild(child) {
    if (!this.node.contains(child)) return;
    this.node.removeChild(child);
  }

  setPosition(x, y) {
    this.x = x;
    this.y = y;
    this.node.style.left = `${x}px`;
    this.node.style.top = `${y}px`;
  }

  scaleRelativeTo(viewportWidth, viewportHeight) {
    this.viewportWidth = viewportWidth;
    this.viewportHeight = viewportHeight;
    this.scale = this.getScale();
    this.node.style.transform = this.getScaledTransformation();
    this.node.style.borderWidth = `${1 / this.scale}px`;
  }

  applyScale(scale) {
    this.scale = this.getScale() * scale;
    this.node.style.transform = this.getScaledTransformation();
    this.node.style.borderWidth = `${1 / this.scale}px`;
  }

  getScaledTransformation() {
    return `scale(${this.scale})`;
  }

  getScaledDimensions() {
    return {
      width: this.width * this.scale,
      height: this.height * this.scale,
    };
  }

  getScale() {
    const { configuration } = this.viewport;
    const mediaRatio = configuration.viewportRelativeRatio || 0.8;

    return Math.min(
      (this.viewportWidth * mediaRatio) / this.width,
      (this.viewportHeight * mediaRatio) / this.height
    );
  }

  setWidth(value) {
    this.width = value;
    this.node.style.width = `${value}px`;
  }

  setHeight(value) {
    this.height = value;
    this.node.style.height = `${value}px`;
  }

  onDestroy() {
    if (!this.parentElement.contains(this.node)) return;
    this.parentElement.removeChild(this.node);
  }
}
