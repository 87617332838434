import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useJarvisContext } from "../contexts/Jarvis";
import {
  AnalyticsEventHandler,
  analyticsMediator,
  CDMAnalyticsService,
  CDMEventAdapter,
  InternalEvent,
  PageViewEvent,
  sendPageView,
  UDLAnalyticsService,
  UDLEventAdapter,
  UserLoginEvent,
} from "../plugins/analytics";
import useDevice from "./useDevice";
import useMediaControls from "./useMediaControls";

const useAnalytics = (listenRouteChanges = false) => {
  const location = useLocation();
  const { platform } = useDevice();
  const { numPages } = useMediaControls();
  const {
    getAnalyticsPublisher,
    getAppInstanceId,
    isUsingJarvis,
    getServiceOptions,
  } = useJarvisContext();

  const initAnalytics = async () => {
    let service, adapter;

    const eventsToListen = [
      UserLoginEvent.eventName,
      PageViewEvent.eventName,
      InternalEvent.eventName,
    ];

    console.debug("isUsingJarvis:", isUsingJarvis);
    if (isUsingJarvis) {
      const serviceOptions = await getServiceOptions();
      const publisher = await getAnalyticsPublisher();
      const serviceInstanceId = await getAppInstanceId();
      service = new CDMAnalyticsService(publisher, serviceInstanceId, platform);
      adapter = new CDMEventAdapter(serviceOptions);
    } else {
      service = new UDLAnalyticsService();
      adapter = new UDLEventAdapter();
    }

    AnalyticsEventHandler.init(
      analyticsMediator,
      service,
      adapter,
      eventsToListen
    );
  };

  const applyAnalyticsCookies = (analyticsInfo) => {
    const { optanonConsent, optanonAlertBoxClosed } = analyticsInfo;
    if (!optanonConsent || !optanonAlertBoxClosed) return;
    document.cookie = `OptanonConsent=${optanonConsent};path=/`;
    document.cookie = `OptanonAlertBoxClosed=${optanonAlertBoxClosed};path=/`;
  };

  // we must skip the event if actual page is "/document/select" and we have one page.
  const handleSendPageView = (pathname, listenRoutes, numberOfPages) => {
    const isSelectPage = pathname === "/document/select";
    const haveJustOnePage = numberOfPages === 1;
    const shouldSkipEvent = isSelectPage && haveJustOnePage;

    const isRequirementsSatisfied =
      listenRoutes && !shouldSkipEvent && numberOfPages;

    if (isRequirementsSatisfied) {
      sendPageView();
    }
  };

  useEffect(() => {
    const { pathname } = location;

    handleSendPageView(pathname, listenRouteChanges, numPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, listenRouteChanges, numPages]);

  return {
    initAnalytics,
    applyAnalyticsCookies,
    handleSendPageView,
  };
};

export default useAnalytics;
