import { request } from "../../services/request";
import MergeMethod from "./MergeMethod";
import Env from "../../utils/env";

export default class PdfMergeMethod extends MergeMethod {
  constructor(accessToken) {
    super();
    this.accessToken = accessToken;
  }

  get name() {
    return "PdfMergeMethod";
  }

  get targetMimeTypes() {
    return ["application/pdf"];
  }

  async merge(payload) {
    const [firstDocument] = payload.documents;
    const data = {
      sourceUrl: firstDocument.source,
      markInsertions: payload.markInsertions.map((mark) => ({
        markId: mark.id,
        scale: mark.scale * 100,
        xAxis: mark.x,
        yAxis: mark.yFromBottom,
        page: mark.page,
      })),
    };
    return await request({
      url: await Env.getEnv().SCRIBBLE_API_BASE_URL,
      resource: "merge/pdfmerge",
      method: "POST",
      accessToken: this.accessToken,
      data,
    });
  }
}
