class AnalyticsMediator {
  constructor() {
    this._listeners = {};
    this._queue = [];
  }

  registerListener(eventName, listener) {
    if (!this._listeners[eventName]) {
      this._listeners[eventName] = [listener];
    } else {
      this._listeners[eventName].push(listener);
    }
  }

  sendEvent(event) {
    this._queue.unshift(event);
    this._consumeQueue();
  }

  _consumeQueue() {
    if (!this._hasListeners()) return;
    while (this._queue.length > 0) {
      const event = this._queue.pop();
      this._notifyListeners(event);
    }
  }

  _notifyListeners(event) {
    const eventName = event.eventName;
    const listeners = this._listeners[eventName];
    if (!listeners) return;
    listeners.forEach((listener) => listener.handle(event));
  }

  _hasListeners() {
    return Object.keys(this._listeners).length > 0;
  }

  clearListeners() {
    this._listeners = {};
  }
}

export const analyticsMediator = new AnalyticsMediator();
