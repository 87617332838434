import { paths } from "../../../route.settings";
import MarkupApplyCoachmarks from "./MarkupApplyCoachmarks";
import MarkupCreateCoachmarks from "./MarkupCreateCoachmarks";

const coachmarksMapping = {
  [paths.MARKUPS_APPLY]: MarkupApplyCoachmarks,
  [paths.MARKUPS_CREATE]: MarkupCreateCoachmarks,
};

export default coachmarksMapping;
