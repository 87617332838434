/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const rootStyle = css`
  display: inline-block;
  color: #212121;
  font-size: 0.8rem;
  text-align: center;
  padding: 0;
`;

const TextIcon = (props) => {
  return (
    <svg
      data-testid="thick-stroke-icon"
      css={rootStyle}
      fill="currentColor"
      focusable={false}
      version="1.1"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 30"
      >
        <path
          fill="black"
          stroke="none"
          d="M1.96 22.45L-0.02 22.45L5.39 5.27L7.39 5.27L13.16 22.45L11.03 22.45L9.39 17.25L3.50 17.25L1.96 22.45ZM5.59 10.36L4.04 15.40L8.81 15.40L7.35 10.64Q6.67 8.45 6.35 7.08L6.35 7.08Q6.08 8.73 5.59 10.36L5.59 10.36ZM19.95 22.45L14.58 22.45L14.58 5.27L19.86 5.27Q21.47 5.27 22.41 5.77Q23.34 6.27 23.94 7.32Q24.54 8.38 24.54 9.66L24.54 9.66Q24.54 10.84 24.02 11.79Q23.51 12.74 22.57 13.31L22.57 13.31Q23.80 13.76 24.51 14.83Q25.22 15.90 25.22 17.47L25.22 17.47Q25.22 19.03 24.58 20.21Q23.94 21.39 22.88 21.92Q21.81 22.45 19.95 22.45L19.95 22.45ZM16.44 7.30L16.44 12.49L19.49 12.49Q20.68 12.49 21.19 12.33L21.19 12.33Q21.91 12.08 22.31 11.50Q22.70 10.92 22.70 9.93L22.70 9.93Q22.70 9 22.34 8.38Q21.98 7.77 21.39 7.54Q20.80 7.30 19.27 7.30L19.27 7.30L16.44 7.30ZM16.44 14.52L16.44 20.43L19.95 20.43Q21.11 20.43 21.66 20.21L21.66 20.21Q22.45 19.91 22.87 19.21Q23.29 18.52 23.29 17.46L23.29 17.46Q23.29 16.49 22.89 15.81Q22.49 15.14 21.81 14.83Q21.14 14.52 19.70 14.52L19.70 14.52L16.44 14.52ZM37.77 16.43L37.77 16.43L39.64 17.00Q39.02 19.90 37.47 21.32Q35.92 22.75 33.69 22.75L33.69 22.75Q31.73 22.75 30.35 21.79Q28.96 20.84 28.07 18.73Q27.19 16.62 27.19 13.75L27.19 13.75Q27.19 10.91 28.07 8.95Q28.96 6.98 30.47 5.98Q31.98 4.98 33.84 4.98L33.84 4.98Q35.88 4.98 37.35 6.25Q38.81 7.52 39.40 9.98L39.40 9.98L37.57 10.51Q36.63 6.93 33.81 6.93L33.81 6.93Q32.40 6.93 31.37 7.65Q30.34 8.37 29.72 9.89Q29.11 11.41 29.11 13.75L29.11 13.75Q29.11 17.27 30.35 19.04Q31.59 20.80 33.66 20.80L33.66 20.80Q35.18 20.80 36.29 19.72Q37.39 18.63 37.77 16.43Z"
        />
      </svg>
    </svg>
  );
};

TextIcon.displayName = "TextIcon";

export default TextIcon;
