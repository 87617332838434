const applyArgsOrReturn = (predicate, value, args) => {
  return predicate ? { ...value, ...args } : value;
};

export const AddMarkupCommand = (setMarkups, markup) => ({
  name: "AddMarkup",
  markupId: `${markup.id}`,
  document: markup.document,
  page: markup.page,
  execute: () => {
    setMarkups((markups) => [...markups, markup]);
  },
  undo: () => {
    setMarkups((markups) => markups.filter(({ uid }) => uid !== markup.uid));
  },
});

export const RemoveMarkupCommand = (setMarkups, markup) => ({
  name: "RemoveMarkup",
  markupId: `${markup.id}`,
  document: markup.document,
  page: markup.page,
  execute: () => {
    setMarkups((markups) => markups.filter(({ uid }) => uid !== markup.uid));
  },
  undo: () => {
    setMarkups((markups) => [...markups, markup]);
  },
});

export const UpdateMarkupCommand = (setMarkups, markup, newPosition) => ({
  name: "UpdateMarkup",
  markupId: `${markup.id}`,
  document: markup.document,
  page: markup.page,
  execute: () => {
    setMarkups((markups) =>
      markups.map((mark) =>
        applyArgsOrReturn(mark.uid === markup.uid, mark, newPosition)
      )
    );
  },
  undo: () => {
    setMarkups((markups) =>
      markups.map((mark) =>
        applyArgsOrReturn(mark.uid === markup.uid, mark, markup)
      )
    );
  },
});
