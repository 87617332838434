import { MAX_AREA, resizeImage } from "../image-utils";
import { makeRenderer } from "./renderers/renderer-factory";
import { cleanUpCanvas } from "../canvas-utils";
const FIRST_PAGE = 1;

const cache = {};

export class PageThumbnailGenerator {
  constructor({ multiDocumentMedia, onFinishRendering, thumbnailQuality = 1 }) {
    this.multiDocumentMedia = multiDocumentMedia;
    this.qualityInPixelArea = MAX_AREA * thumbnailQuality;
    this.onFinishRendering = onFinishRendering;
    this.pageThumbArray = [];
  }

  async render() {
    const documentRenderPromises = this.multiDocumentMedia.documents.map(
      async (currentDocument, documentIndex) => {
        const cachedDocument = this._getFromCache(currentDocument);
        if (cachedDocument) {
          return this.pageThumbArray.push(...cachedDocument);
        }

        const pageRenderPromises = [];
        for (let page = 1; page <= currentDocument.numPages; page++) {
          const mediaRenderer = makeRenderer(currentDocument, page);
          await mediaRenderer.loadMedia();
          const documentSize = resizeImage(
            mediaRenderer,
            this.qualityInPixelArea
          );

          pageRenderPromises.push(
            this._renderPage({
              mediaRenderer,
              currentDocument,
              documentSize,
              documentIndex,
              page,
            })
          );
        }
        await Promise.all(pageRenderPromises);
      }
    );
    await Promise.all(documentRenderPromises);

    this.sortPages();
    this.addGlobalPageNumber();
    this.onFinishRendering(this.pageThumbArray);
  }

  _getFromCache(document) {
    return cache[document.id];
  }

  _saveToCache(document, payload) {
    const cachedValue = cache[document.id] || [];
    cachedValue.push(payload);
    cache[document.id] = cachedValue;
  }

  async _renderPage({
    mediaRenderer,
    currentDocument,
    documentSize,
    documentIndex,
    page,
  }) {
    const canvas = document.createElement("canvas");
    canvas.width = documentSize.width;
    canvas.height = documentSize.height;

    await mediaRenderer.render(canvas, page);
    const pageThumb = {
      page,
      documentIndex,
      content: canvas.toDataURL("image/jpeg"),
      mediaDocument: currentDocument,
    };
    this.pageThumbArray.push(pageThumb);
    this._saveToCache(currentDocument, pageThumb);
    cleanUpCanvas(canvas);
  }

  sortPages() {
    this.pageThumbArray.sort((a, b) => {
      return a.documentIndex - b.documentIndex || a.page - b.page;
    });
  }

  addGlobalPageNumber() {
    let globalPageIndex = FIRST_PAGE;
    this.pageThumbArray.forEach((page) => {
      page.globalPageIndex = globalPageIndex++;
    });
  }
}
