/** @jsxImportSource @emotion/react */

import { IconMinusCircle, IconPlusCircle } from "@veneer/core";
import { css } from "@emotion/react";
import { RENDERABLE_MAX_SCALE, RENDERABLE_MIN_SCALE } from "../../constants";

const zoomControlsContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 80px;

  svg {
    cursor: pointer;
  }
`;

const spanStyle = css`
  margin: 0 14px;
  min-width: 50px;
  text-align: center;
  cursor: pointer;
  font-size: 0.938rem;
`;

function ZoomControls({
  onMinusClick,
  onLabelClick,
  onPlusClick,
  currentZoomValue,
  minimumZoomValue = RENDERABLE_MIN_SCALE * 100,
  maximumZoomValue = RENDERABLE_MAX_SCALE * 100,
}) {
  const isMinusIconEnabled = currentZoomValue > minimumZoomValue;
  const isPlusIconEnabled = currentZoomValue < maximumZoomValue;

  function handleMinusClick() {
    if (!isMinusIconEnabled) {
      return;
    }
    onMinusClick?.();
  }

  function handlePlusClick() {
    if (!isPlusIconEnabled) {
      return;
    }
    onPlusClick?.();
  }

  return (
    <div
      css={zoomControlsContainerStyle}
      data-testid="zoom-controls"
      data-coachmark="zoom-controls"
    >
      <IconMinusCircle
        color={!isMinusIconEnabled ? "gray4" : "gray10"}
        onClick={handleMinusClick}
        data-testid="zoom-controls-minus-icon"
        aria-label="zoom-out"
      />
      <span onClick={onLabelClick} css={spanStyle}>
        {currentZoomValue}%
      </span>
      <IconPlusCircle
        color={!isPlusIconEnabled ? "gray4" : "gray10"}
        onClick={handlePlusClick}
        data-testid="zoom-controls-plus-icon"
        aria-label="zoom-in"
      />
    </div>
  );
}

export default ZoomControls;
