/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useCallback } from "react";
import { tokens } from "../../css.settings";
import { Button } from "@veneer/core";
import { useTranslation } from "react-i18next";

const rootStyle = css`
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
`;

const contentStyle = (onActionClick) => css`
  overflow: hidden;
  max-height: ${onActionClick ? "calc(100% - 48px)" : "initial"};
  min-height: 100px;
  height: 100%;
  width: 100%;
`;

const footerStyle = css`
  display: flex;
  flex-flow: row;
  margin: ${tokens.layout.size2} ${tokens.layout.size4};
`;

const actionStyle = css`
  flex: 0 1 0;
  span {
    color: ${tokens.color.white};
  }

  :disabled span {
    color: ${tokens.color.gray4};
  }
`;

const MarkupPanel = ({
  actionDisabled,
  onActionClick,
  actionIcon,
  children,
  styles = {},
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onAction = useCallback(() => {
    setLoading(true);
    onActionClick && onActionClick();
    setLoading(false);
  }, [setLoading, onActionClick]);

  return (
    <section
      data-testid="markup-panel"
      css={[rootStyle, styles.root]}
      {...rest}
    >
      <div css={contentStyle(onActionClick)}>{children}</div>
      {onActionClick && (
        <footer css={footerStyle}>
          <Button
            data-testid="markup-panel-action-button"
            css={actionStyle}
            disabled={actionDisabled}
            loading={loading}
            appearance="primary"
            leadingIcon={actionIcon}
            onClick={onAction}
          >
            {t("common.delete")}
          </Button>
        </footer>
      )}
    </section>
  );
};

export default MarkupPanel;
