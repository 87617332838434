/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRef, useEffect, useState, forwardRef } from "react";
import { TextBox } from "@veneer/core";
import styled from "@emotion/styled";

import SignatureLines from "../SignatureLines";
import { useTranslation } from "react-i18next";
import { TEXTBOX_TOP_PADDING } from "../../constants";

const canvaStyle = css`
  vertical-align: bottom;
`;

const footerStyle = css`
  padding: 20px;
`;

const sectionStyle = css`
  position: relative;
`;

const TextboxWrapper = styled.div((props) => {
  const topPadding = props.isTyping ? TEXTBOX_TOP_PADDING : 0;
  return {
    position: "absolute",
    top: props.canvaHeight / 2 - props.guideHeight / 2 + topPadding,
    left: props.canvaWidth / 2 - props.guideWidth / 2,
    width: props.guideWidth,
    height: props.guideHeight,
    display: props.isTyping ? "inherit" : "none",
  };
});

const CanvasWrapper = styled.div((props) => ({
  display: props.isTyping ? "none" : "inherit",
}));

const SignaturePad = forwardRef(
  (
    {
      width = 0,
      height = 0,
      footer,
      variant = "mobile",
      canUndo,
      input,
      isTyping,
      onType,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const [canvaSize, setCanvaSize] = useState({ width, height });
    const { t } = useTranslation();
    const footerRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
      setCanvaSize({
        width,
        height: height - (footerRef.current?.offsetHeight || 0),
      });
    }, [width, height]);

    useEffect(() => {
      isTyping && inputRef.current.focus();
    }, [isTyping, inputRef]);

    const guidesSize =
      variant === "mobile"
        ? { width: 316, height: 88 }
        : { width: 590, height: 130 };

    return (
      <section css={sectionStyle}>
        <TextboxWrapper
          isTyping={isTyping}
          canvaWidth={canvaSize.width}
          canvaHeight={canvaSize.height}
          guideWidth={guidesSize.width}
          guideHeight={guidesSize.height}
        >
          <TextBox
            data-testid="signature-pad-input"
            id="signature-pad-input"
            type="text"
            ref={inputRef}
            maxLength={40}
            value={input}
            onChange={(value) => onType(value)}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={t("page.new_mark.add_text.placeholder")}
          />
        </TextboxWrapper>
        <CanvasWrapper isTyping={isTyping}>
          <canvas
            data-testid="signature-pad-canvas"
            ref={ref}
            css={[canvaStyle]}
            width={canvaSize.width}
            height={canvaSize.height}
          ></canvas>
          <SignatureLines isDirty={canUndo} {...guidesSize} />
        </CanvasWrapper>
        {footer && <footer css={footerStyle}>{footer}</footer>}
      </section>
    );
  }
);

export default SignaturePad;
