import { useLocation } from "react-router";

const useQuery = () => {
  const { search = "" } = useLocation();
  const query = search
    .replace("?", "")
    .split("&")
    .map((param) => param.split("="))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
  return query;
};

export default useQuery;
