/* eslint-disable react-hooks/exhaustive-deps */
import { IconTrash } from "@veneer/core";
import { useCallback, useEffect, useMemo } from "react";
import MarkupList from "../../../components/MarkupList";
import MarkupPanel from "../../../components/MarkupPanel";
import MarkupsGridListItemContent from "../../../components/MarkupsGridListItemContent";
import { useFetchMarks } from "../../../contexts/FetchMarks";
import { usePlaceMarkContext } from "../../../contexts/PlaceMark";
import useMarkupSelection from "../../../hooks/useMarkupSelection";
import { sendInternalEvent } from "../../../plugins/analytics";
import { refs } from "../../../plugins/analytics/refs";

const MarkupPanelContainer = ({ manageMode = false, dataTestId }) => {
  const { addMarkToPlacedList, removePlacedMarksById } = usePlaceMarkContext();
  const { markups, isLoading, isDeleting, deleteMarkups } = useFetchMarks();
  const { selectedMarks, toggleMarkSelection, clearSelection } =
    useMarkupSelection();

  const items = useMemo(
    () =>
      (markups || []).map((markup) => ({
        id: markup.id,
        content: <MarkupsGridListItemContent key={markup.id} {...markup} />,
        selected: !!selectedMarks[markup.id],
      })),
    [markups, selectedMarks]
  );

  const hasAnyMarkSelected = Object.keys(selectedMarks).length > 0;

  const handleSelectedMarksDeletion = useCallback(async () => {
    const selectedMarkIds = Object.keys(selectedMarks);
    removePlacedMarksById(...selectedMarkIds);
    deleteMarkups(selectedMarkIds);
    sendInternalEvent(refs.MARKUP_SELECT_DELETE);
    clearSelection();
  }, [selectedMarks, removePlacedMarksById, deleteMarkups, clearSelection]);

  const onClick = useCallback(
    ({ id }) => {
      const selectedMarkup = markups.find((markup) => markup.id === id);
      addMarkToPlacedList(selectedMarkup);
      sendInternalEvent(refs.MARKUP_SELECT_ADD);
    },
    [markups, addMarkToPlacedList]
  );

  useEffect(() => {
    if (!isLoading && !manageMode && markups) {
      console.log(">>> SELECT LIST");
      sendInternalEvent(refs.MARKUP_SELECT_LIST, {
        count: markups.length,
      });
    }
  }, [markups]);

  return (
    <MarkupPanel
      data-testid="markup-panel-select-marks"
      actionDisabled={!hasAnyMarkSelected || isDeleting}
      actionIcon={<IconTrash />}
      onActionClick={manageMode ? handleSelectedMarksDeletion : null}
    >
      <MarkupList
        data-testid={dataTestId}
        items={items}
        isLoading={isLoading}
        selectable={manageMode}
        onToggleSelection={toggleMarkSelection}
        onClick={onClick}
        columns={1}
      />
    </MarkupPanel>
  );
};

export default MarkupPanelContainer;
