/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useGridContext } from "../Grid";
import { useMediaQueries } from "../../css.settings";
import useDevice from "../../hooks/useDevice";

const flexBasis = (column, columns, gutter) => css`
  flex-basis: calc(${(column / columns) * 100}% - ${gutter * 2}px);
  max-width: calc(${(column / columns) * 100}% - ${gutter * 2}px);
`;

const rootStyle = ({
  media,
  columns,
  gutter,
  small,
  medium,
  large,
  isDesktop,
}) => css`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 0;
  margin: ${gutter}px;
  ${media.small && isDesktop && flexBasis(small, columns, gutter)};
  ${media.medium && isDesktop && flexBasis(medium, columns, gutter)};
  ${media.large && isDesktop && flexBasis(large, columns, gutter)};
`;

const GridColumn = ({
  small = 12,
  medium = 12,
  large = 12,
  children,
  ...rest
}) => {
  const { columns, gutter } = useGridContext();
  const { isDesktop } = useDevice();
  const media = useMediaQueries();
  return (
    <div
      css={rootStyle({
        media,
        columns,
        gutter,
        small,
        medium,
        large,
        isDesktop,
      })}
      data-testid={rest["data-testid"] || "grid-column"}
    >
      {children}
    </div>
  );
};

export default GridColumn;
