const LS_PREFIX = "@scribble";

/**
 * @param {String} key
 * @param {Object} value
 */
export const setLocalStorageItem = (key, value) => {
  const serializedData = JSON.stringify(value);
  localStorage.setItem(`${LS_PREFIX}:${key}`, serializedData);
};

/**
 * @param {*} key
 * @returns {Object}
 */
export const getLocalStorageItem = (key) => {
  const data = localStorage.getItem(`${LS_PREFIX}:${key}`) || "{}";
  return JSON.parse(data);
};
