import { Circle, Rectangle } from "../../math";
import { ScalingState } from "./scaling-state";
import { TranslatingState } from "./translating-state";
import {
  NONE,
  CURSOR_MAPPING,
  CORNER_RATIO,
  MOVE_CURSOR,
  DEFAULT_CURSOR,
} from "../../../constants";

export class IdleState {
  constructor(controller) {
    this.controller = controller;
  }

  _getAbsoluteMarkupRect() {
    const { markup } = this.controller;
    const { left, top, width, height } = markup.element.getAbsoluteBounds();
    return new Rectangle(left, top, width, height);
  }

  _getHoveredCorner(mouseX, mouseY) {
    const markupRect = this._getAbsoluteMarkupRect();
    const minDimension = Math.min(markupRect.width, markupRect.height);
    const cornerRadius = minDimension * CORNER_RATIO;

    return markupRect.corners
      .map((corner) => new Circle(...corner, cornerRadius))
      .findIndex((circle) => circle.isPointInside(mouseX, mouseY));
  }

  getCursorIcon(mouseX, mouseY) {
    const markupRect = this._getAbsoluteMarkupRect();
    const activeCorner = this._getHoveredCorner(mouseX, mouseY);

    let cursor = CURSOR_MAPPING[activeCorner] || DEFAULT_CURSOR;
    if (cursor === DEFAULT_CURSOR && markupRect.isPointInside(mouseX, mouseY)) {
      cursor = MOVE_CURSOR;
    }

    return cursor;
  }

  handle(transform) {
    const { markup } = this.controller;
    const { left, top, width, height } = markup.element.getAbsoluteBounds();
    const { mouseX, mouseY } = transform;
    const absMarkupRectangle = new Rectangle(left, top, width, height);
    const cursorIcon = this.getCursorIcon(mouseX, mouseY);
    const hoveredCorner = this._getHoveredCorner(
      mouseX,
      mouseY,
      absMarkupRectangle
    );

    if (hoveredCorner !== NONE) {
      this.controller.currentState = new ScalingState(
        this.controller,
        hoveredCorner,
        cursorIcon
      );
      this.controller.currentState.handle(transform);
    } else if (absMarkupRectangle.isPointInside(mouseX, mouseY)) {
      this.controller.currentState = new TranslatingState(
        this.controller,
        cursorIcon
      );
      this.controller.currentState.handle(transform);
    }
  }

  handleStop() {}
}
