import { useState } from "react";
import { useMediaManagerContext } from "../../contexts/MediaManager";
import { useSessionContext } from "../../contexts/Session";
import useDevice from "../useDevice";
import ImageMergeMethod from "./ImageMergeMethod";
import PdfMergeMethod from "./PdfMergeMethod";

const useMergeMedia = () => {
  const [loading, setLoading] = useState(false);
  const [mergeResult, setMergeResult] = useState({});
  const { deviceOS } = useDevice();
  const { sessionToken } = useSessionContext();
  const { media } = useMediaManagerContext();

  const availableMergeMethods = [
    new ImageMergeMethod(deviceOS),
    new PdfMergeMethod(sessionToken),
  ];

  const getMergeMethodByMimeType = (mimeType) => {
    const mergeMethod = availableMergeMethods.find((mergeCandidate) =>
      mergeCandidate.canMergeMimeType(mimeType)
    );

    if (!mergeMethod) {
      throw new Error(mimeType + " media type is not supported");
    }

    return mergeMethod;
  };

  const mergeMedia = async (markInsertions, mimeType) => {
    try {
      setLoading(true);
      setMergeResult({});
      const mergeMethod = getMergeMethodByMimeType(mimeType);
      console.log(`merging ${mimeType} with ${mergeMethod.name}`);
      const data = await mergeMethod.merge({
        documents: media.documents,
        markInsertions,
      });
      setMergeResult({ data });
    } catch (error) {
      setMergeResult({ error });
      setLoading(false);
    }
  };

  return {
    loading,
    mergeResult,
    mergeMedia,
  };
};

export default useMergeMedia;
