export class ImageRenderer {
  constructor(media) {
    this.media = media;
    this.viewportScale = media.scale;
  }

  async loadMedia() {
    this.width = this.media.content.width;
    this.height = this.media.content.height;
  }

  async render(targetCanvas) {
    const canvasContext = targetCanvas.getContext("2d");
    canvasContext.drawImage(
      this.media.content,
      0,
      0,
      targetCanvas.width,
      targetCanvas.height
    );
  }
}
