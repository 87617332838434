import { DEFAULT_CURSOR, Y_BOTTOM_MARGIN } from "../../constants";
import { BackgroundMedia } from "./background-media";
import { GreyAreaElement } from "./grey-area-element";
import { Markup } from "./markup";
import { Renderable } from "./renderable";
import { makeRenderer } from "./renderers/renderer-factory";
import "./style/style.css";
import { ViewportController } from "./viewport-controller";
import { ViewportElement } from "./viewport-element";
import { ViewportEvents, ViewportEventSystem } from "./viewport-events";

export class Viewport extends Renderable {
  constructor(parentElement, configuration = {}) {
    super();
    this.parentElement = parentElement;
    this.configuration = configuration;
    this.markups = {};
    this.onClickComponent = this.onClickComponent.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.eventSystem = new ViewportEventSystem();
    this.currentCursor = DEFAULT_CURSOR;

    this.greyElement = new GreyAreaElement(parentElement, () =>
      this.onClickComponent(this)
    );
    this.element = new ViewportElement(this, parentElement);
    this.controller = new ViewportController(this, parentElement);
    this._activeComponent = this;

    this.isLoading = true;
    this.loadingUpdateCallback = () => {};
  }

  setLoading(isLoading) {
    this.loading = isLoading;
    this.loadingUpdateCallback(isLoading);
  }

  onLoadingUpdate(loadingUpdateCallback) {
    this.loadingUpdateCallback = loadingUpdateCallback;
  }

  _updateOrCreateMarks(markups) {
    markups.forEach((markupInfo) => {
      const exists = this.markups[markupInfo.uid];
      if (exists) {
        return this.markups[markupInfo.uid].setState(markupInfo);
      }
      const markup = new Markup(markupInfo, this);
      if (markup.isNew) this.onClickComponent(markup);
      markup.render();
      this.markups[markupInfo.uid] = markup;
    });
  }

  _deleteUnusedMarks(markups) {
    const usedMarkupsMapping = markups.reduce(
      (obj, mark) => ({ ...obj, [mark.uid]: true }),
      {}
    );

    Object.keys(this.markups).forEach((uid) => {
      const isUsed = usedMarkupsMapping[uid];

      if (!isUsed) {
        this.markups[uid].onDestroy();
        delete this.markups[uid];
      }

      return isUsed;
    });
  }

  loadMarks(markups = []) {
    if (markups.length === 0) {
      this.onClickComponent(this);
    }

    this._updateOrCreateMarks(markups);
    this._deleteUnusedMarks(markups);

    this.eventSystem.emit(
      ViewportEvents.SELECTION_UPDATE,
      this._activeComponent
    );
  }

  deleteMark(uid) {
    if (this.markups[uid]) {
      this.markups[uid].onDestroy();
      delete this.markups[uid];
      this.eventSystem.emit(ViewportEvents.MARKUP_DELETE, uid);
      this.onClickComponent(this);
    }
  }

  notifyMarkupUpdate() {
    this.eventSystem.emit(
      ViewportEvents.MARKUP_UPDATE,
      this._activeComponent.getState()
    );
  }

  setMedia(mediaData, mediaPage) {
    let mediaRenderer = makeRenderer(mediaData, mediaPage);
    const onClickAction = () => this.onClickComponent(this);
    this.backgroundMedia = new BackgroundMedia(
      mediaRenderer,
      this.element.node,
      onClickAction
    );
  }

  async load(centerViewport = true) {
    await this.backgroundMedia.load();
    this.applyScale();
    centerViewport && this.centerViewport();
    this.controller.enableControl();
  }

  async render() {
    this.setLoading(true);
    await this.backgroundMedia.render();
    this.setLoading(false);
  }

  onClickComponent(clickedComponent) {
    if (!this.controller.canClick()) return;
    if (this._activeComponent && this._activeComponent !== clickedComponent) {
      this._activeComponent.active = false;
    }
    this._activeComponent = clickedComponent;
    this._activeComponent.active = true;

    this.eventSystem.emit(
      ViewportEvents.SELECTION_UPDATE,
      this._activeComponent
    );
  }

  onMouseMove(mouseX, mouseY) {
    let cursor = DEFAULT_CURSOR;
    if (this._activeComponent && this._activeComponent.isMarkup) {
      cursor = this._activeComponent.controller.getCursorIcon(mouseX, mouseY);
    }
    if (this.currentCursor !== cursor) {
      this.currentCursor = cursor;
      this.element.node.style.cursor = cursor;
    }
  }

  applyScale() {
    const { width, height } = this.backgroundMedia;
    this.currentScale = 1;
    this.element.setWidth(width);
    this.element.setHeight(height);
    this.element.scaleRelativeTo(this.width, this.height);
    this.eventSystem.emit(ViewportEvents.VIEWPORT_SCALE, this.scalePercent);
  }

  centerViewport() {
    const { configuration } = this;
    const { width: scaledWidth, height: scaledHeight } =
      this.element.getScaledDimensions();

    const bottomMargin =
      configuration.bottomMargin !== undefined
        ? configuration.bottomMargin
        : Y_BOTTOM_MARGIN;

    const halfHSize = 2 + bottomMargin;
    this.setX(this.width / 2 - scaledWidth / 2);
    this.setY(this.height / halfHSize - scaledHeight / 2);
  }

  applyZoomOnCenter(zoomValue) {
    const greyAreaDimensions = this.greyElement.getDimensions();
    const centerX = greyAreaDimensions.width / 2;
    const centerY = greyAreaDimensions.height / 2;

    this.applyTransformation({
      type: "scale",
      source: "scroll",
      centerX,
      centerY,
      scale: zoomValue,
      viewportScale: this.element.scale,
    });
  }

  transformActiveElement(transform) {
    if (!this._activeComponent) return;
    this._activeComponent.applyTransformation({
      ...transform,
      viewportScale: this.element.scale,
    });
  }

  transformEndActiveElement(transform) {
    if (!this._activeComponent || this._activeComponent === this) {
      return this.eventSystem.emit(ViewportEvents.VIEWPORT_MOVE, 0);
    }
    this._activeComponent.onTransformationEnd(transform);
    if (!transform.cancelled) {
      this.notifyMarkupUpdate();
    }
  }

  onDestroy() {
    this.element.onDestroy();
    this.greyElement.onDestroy();
    this.backgroundMedia.onDestroy();
    this.controller.disableControl();
    this.eventSystem.clear();
    Object.values(this.markups).forEach((markup) => markup.onDestroy());
  }

  setX(value) {
    super.setX(value);
    this.element.setPosition(value, this.element.y);
  }

  setY(value) {
    super.setY(value);
    this.element.setPosition(this.element.x, value);
  }

  setScale(value) {
    super.setScale(value);
    this.element.applyScale(value);
    this.eventSystem.emit(ViewportEvents.VIEWPORT_SCALE, this.scalePercent);
  }

  setWidth(value) {
    super.setWidth(value);
    this.applyScale();
    this.centerViewport();
  }

  setHeight(value) {
    super.setHeight(value);
    this.applyScale();
    this.centerViewport();
  }

  get activeComponent() {
    return this._activeComponent;
  }
}
