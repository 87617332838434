import * as SVGUtils from "../svg-utils";

export class MarkupElement {
  constructor(markupSvg, x, y, scale) {
    this.x = x;
    this.y = y;
    this.width = 0;
    this.height = 0;
    this.aspectRatio = 1;
    this.scale = scale;

    this._createNode();
    this._applySvg(markupSvg);
  }

  _createNode() {
    this.node = document.createElement("div");
    this.node.dataset.testid = "markup-element";
    this.node.dataset.coachmark = "markup-element";
    this.node.style.position = "absolute";
    this.node.style.boxSizing = "border-box";
    this.node.style.pointerEvents = "auto";
    this.node.style.display = "none";
    this.node.style.left = `${this.x}px`;
    this.node.style.top = `${this.y}px`;
    this.node.style.transform = `scale(${this.scale})`;
    this.node.style.transformOrigin = "top left";
    this.image = document.createElement("img");
    this.image.setAttribute("draggable", false);
    this.image.style.pointerEvents = "none";
    this.node.appendChild(this.image);
  }

  _applySvg(markupSvg) {
    const svgElement = SVGUtils.createSVGNodeFromString(markupSvg);
    const { width, height } = SVGUtils.getDimensionsFromSVGElement(svgElement);
    this.setWidth(width);
    this.setHeight(height);
    this.aspectRatio = width / height;

    const svgBase64 = SVGUtils.convertSVGNodeToBase64(svgElement);
    this.image.src = svgBase64;
  }

  getAbsoluteBounds() {
    const { left, top, right, bottom, width, height } =
      this.node.getBoundingClientRect();

    return {
      left,
      top,
      right,
      bottom,
      width,
      height,
    };
  }

  applyScale(scale) {
    this.scale = scale;
    this.node.style.transform = this.getScaledTransformation();
  }

  getScaledTransformation() {
    return `scale(${this.scale})`;
  }

  setPosition(x, y) {
    this.x = x;
    this.y = y;
    this.node.style.left = `${this.x}px`;
    this.node.style.top = `${this.y}px`;
  }

  setWidth(value) {
    this.width = value;
    this.node.style.width = `${value}px`;
  }

  setHeight(value) {
    this.height = value;
    this.node.style.height = `${value}px`;
  }

  setCornerSize(value) {
    this.cornerSize = value / this.scale;
    this.node.style.backgroundSize = `${this.cornerSize}px`;
  }

  getScaledDimensions() {
    return {
      width: this.width * this.scale,
      height: this.height * this.scale,
    };
  }

  render() {
    this.node.style.display = "block";
  }
}
