/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import FullScreenCircularIndicator from "../components/FullScreenCircularIndicator";
import { useJarvisContext } from "../contexts/Jarvis";
import { useSessionContext } from "../contexts/Session";
import useDevice from "../hooks/useDevice";
import useSetup from "../hooks/useSetup";
import { sendLoginEvent } from "../plugins/analytics";
import { paths } from "../route.settings";

const JarvisLoginContainer = () => {
  const history = useHistory();

  const {
    documents,
    authToken,
    exchangedToken,
    deviceLang,
    getDeviceInfo,
    getAuthToken,
    getExchangedToken,
    getDocuments,
  } = useJarvisContext();
  const { isDesktop } = useDevice();

  const { setSessionToken } = useSessionContext();

  const abortApplication = () => {
    history.goBack();
  };

  const {
    loadingLocale,
    loadingMedia,
    applyAppLocale,
    selectedDocument,
    parseMediaDocuments,
    sendUserPlanToAnalytics,
  } = useSetup({
    onError: abortApplication,
  });

  useEffect(() => {
    getDeviceInfo();
    getAuthToken();
    getDocuments();
  }, []);

  useEffect(() => {
    if (authToken) {
      getExchangedToken(authToken);
    }
  }, [authToken]);

  useEffect(() => {
    if (documents && exchangedToken && deviceLang) {
      applyAppLocale(deviceLang.replace("-", "_"));
      setSessionToken(exchangedToken);
      parseMediaDocuments(documents);
    }
  }, [documents, exchangedToken, deviceLang]);

  useEffect(() => {
    if (!loadingLocale && !loadingMedia && selectedDocument) {
      sendLoginEvent();
      sendUserPlanToAnalytics(exchangedToken);
      history.replace(isDesktop ? paths.MARKUPS_APPLY : paths.DOCUMENT_SELECT);
    }
  }, [loadingLocale, loadingMedia, selectedDocument]);

  return <FullScreenCircularIndicator />;
};

export default JarvisLoginContainer;
