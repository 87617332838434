import Env from "../../../utils/env";

const TRACKING_ID = "GTM-MZXB4R4";

export const EVENT_PAGE_VIEW = "e_pageView";
export const EVENT_INTERNAL = "e_internal";
export const EVENT_LINK_CLICK = "e_linkClick";
export const EVENT_USER_LOGIN = "e_userLogin";

const removeEmptyProperties = (extra) => {
  const sanitizedObject = {};
  Object.entries(extra).forEach(([key, value]) => {
    if (!!value) sanitizedObject[key] = value;
  });
  return sanitizedObject;
};

export default class UDLAnalyticsService {
  constructor(shouldInitUDL = true) {
    this._wasInitialized = !shouldInitUDL;
    this._isDev = Env.getEnv().currentStack !== "prod";
    this._init();
  }

  _init() {
    if (!this._wasInitialized) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        const [f] = d.getElementsByTagName(s);
        const dl = l !== "dataLayer" ? `&l=${l}` : "";
        const j = d.createElement(s);
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
        // LOGGER.debug(j.src);
      })(window, document, "script", "dataLayer", TRACKING_ID);
      this._wasInitialized = true;
    }
    console.debug("Analytics: Initializing UDL");
  }

  sendEvent({ event, data }) {
    if (!event || !data) {
      return;
    }

    this.addToDataLayer({
      event,
      data,
    });
  }

  sendPageView(event) {
    if (!event) {
      return;
    }

    const data = {
      pageNameL5: event.name,
      pageNameL6: event.tab || undefined,
      pageNameL7: event.state || undefined,
    };
    this.addToDataLayer({
      event: EVENT_PAGE_VIEW,
      data,
    });
  }

  sendInternalEvent({ label, action, extra = {} } = {}) {
    if (!label || !action) {
      return;
    }

    const sanitizedExtra = removeEmptyProperties(extra);

    this.sendEvent({
      event: EVENT_INTERNAL,
      data: {
        label,
        action,
        ...sanitizedExtra,
      },
    });
  }

  sendLoginEvent() {
    this.sendEvent({
      event: EVENT_USER_LOGIN,
      data: {},
    });
  }

  addToDataLayer({ event, data }) {
    if (!window.dataLayer) return;
    window.dataLayer.push(
      Object.assign({ event }, { dev: this._isDev, ...data })
    );
  }
}
