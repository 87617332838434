import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useFetchMarks } from "../contexts/FetchMarks";
import { usePlaceMarkContext } from "../contexts/PlaceMark";
import { THICKNESS_SIZES, useSignaturePad } from "../contexts/SignaturePad";
import { sendInternalEvent } from "../plugins/analytics";
import { refs } from "../plugins/analytics/refs";
import useApi from "./useApi";
import useDevice from "./useDevice";
import useCoachmarksStore from "../store/coachmarks/coachmarks";

const useCreateMarkup = () => {
  const { addMarkToPlacedList } = usePlaceMarkContext();
  const { loadMarkups } = useFetchMarks();
  const showCoachmark = useCoachmarksStore((store) => store.showCoachmark);
  const { isMobile } = useDevice();

  const {
    isFetching: loading,
    data: markup,
    startFetch: registerMarkup,
  } = useApi("marks", "POST");

  const [isDirty, setIsDirty] = useState(false);
  const [shouldSaveMarkup, setShouldSaveMarkup] = useState(true);
  const [padSize, setPadSize] = useState({ width: 0, height: 0 });
  const [prevPadSize, setPrevPadSize] = useState({ width: 0, height: 0 });
  const bodyRef = useRef(null);

  const history = useHistory();

  const onDataUpdate = (markup) => {
    if (markup && markup.length && !isDirty) {
      setIsDirty(true);
      sendInternalEvent(refs.MARKUP_CREATE_START_DRAWING);
    }
  };

  const {
    thickness,
    isTyping,
    getSignatureAsSVG,
    setThickness,
    setIsTyping,
    setInput,
    redo,
    undo,
    input,
    canSave,
    canUndo,
    canRedo,
    trash,
    canTrash,
    registerCanvas,
  } = useSignaturePad({ onDataUpdate });

  const onSelectThin = () => {
    setThickness(THICKNESS_SIZES.thin.name);
    setIsTyping(false);
  };

  const onSelectMedium = () => {
    setThickness(THICKNESS_SIZES.medium.name);
    setIsTyping(false);
  };

  const onSelectThick = () => {
    setThickness(THICKNESS_SIZES.thick.name);
    setIsTyping(false);
  };

  const onSelectText = () => {
    setIsTyping(true);
  };

  const onUndo = () => {
    sendInternalEvent(refs.MARKUP_CREATE_HISTORY_UNDO);
    undo();
  };

  const onRedo = () => {
    sendInternalEvent(refs.MARKUP_CREATE_HISTORY_REDO);
    redo();
  };

  const onType = (value) => {
    // TODO: sendInternalEvent
    setInput(value);
  };

  const onTrash = () => {
    sendInternalEvent(refs.MARKUP_CREATE_HISTORY_DELETE);
    trash();
  };

  const onFocus = (_event) => {
    setPrevPadSize({ ...padSize });
    setPadSize({ ...padSize, height: padSize.height / 2 });
    setTimeout(() => window.scrollTo(0, 0), 100);
  };

  const onBlur = (_event) => {
    setPadSize({ ...prevPadSize });
  };

  const onCheckboxChange = () => {
    setShouldSaveMarkup((currentValue) => !currentValue);
  };

  const saveMarkup = async () => {
    const markSVG = await getSignatureAsSVG();

    const formData = new FormData();
    formData.append("markSVG", markSVG);
    formData.append("isTemporary", !shouldSaveMarkup);

    const thicknessData = {
      [THICKNESS_SIZES.thin.name]: refs.MARKUP_CREATE_SAVE_THICKNESS_THIN,
      [THICKNESS_SIZES.medium.name]: refs.MARKUP_CREATE_SAVE_THICKNESS_MEDIUM,
      [THICKNESS_SIZES.thick.name]: refs.MARKUP_CREATE_SAVE_THICKNESS_THICK,
    };

    sendInternalEvent(thicknessData[thickness]);
    sendInternalEvent(
      isMobile ? refs.MARKUP_CREATE_SAVE : refs.FOOTER_DONE_BUTTON
    );
    sendInternalEvent(
      shouldSaveMarkup
        ? refs.MARKUP_SAVE_MARK_CHECKBOX_ENABLED
        : refs.MARKUP_SAVE_MARK_CHECKBOX_DISABLED
    );

    registerMarkup(formData);
  };

  const adjustPadDimensions = () => {
    if (!bodyRef.current) {
      return;
    }
    setPadSize({
      width: bodyRef.current.offsetWidth,
      height: bodyRef.current.offsetHeight,
    });
  };

  const handleCancel = () => {
    sendInternalEvent(refs.FOOTER_CANCEL_BUTTON);
    history.goBack();
  };

  useEffect(() => {
    if (markup) {
      addMarkToPlacedList(markup);
      loadMarkups();

      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markup]);

  useEffect(() => {
    adjustPadDimensions();
    window.addEventListener("resize", adjustPadDimensions);
    return () => {
      window.removeEventListener("resize", adjustPadDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showCoachmark({ isTyping });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTyping]);

  const isDoneDisabled =
    loading || !canSave || (isTyping && input.trim().length === 0);

  return {
    bodyRef,
    registerCanvas,
    handleCancel,
    saveMarkup,
    onCheckboxChange,
    onSelectThin,
    onSelectMedium,
    onSelectThick,
    onSelectText,
    onTrash,
    onType,
    onUndo,
    onRedo,
    onFocus,
    onBlur,
    isTyping,
    isDoneDisabled,
    loading,
    input,
    canUndo,
    canRedo,
    canTrash,
    padSize,
    thickness,
  };
};

export default useCreateMarkup;
