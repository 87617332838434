import {
  getImage,
  loadImages,
  MAX_AREA,
  resizeImage,
} from "../../utils/image-utils";
import {
  convertSVGNodeToBase64,
  createSVGNodeFromString,
} from "../../utils/svg-utils";
import MergeMethod from "./MergeMethod";

export default class ImageMergeMethod extends MergeMethod {
  constructor(deviceOS) {
    super();
    this._svgCache = {};
    this._shouldResize = deviceOS === "ios";
  }

  get name() {
    return "ImageMergeMethod";
  }

  get targetMimeTypes() {
    return ["image/jpg", "image/jpeg", "image/png"];
  }

  async _getSvg(cacheId, svgString) {
    const cache = this._svgCache[cacheId];
    if (cache) return cache;

    const svgNode = createSVGNodeFromString(svgString);
    const svgAsBase64 = convertSVGNodeToBase64(svgNode);
    const svgImage = await getImage(svgAsBase64);
    this._svgCache[cacheId] = svgImage;

    return svgImage;
  }

  async _getBackgroundImage(source) {
    const background = await getImage(source);

    const newDim = this._shouldResize
      ? resizeImage(
          {
            width: background.width,
            height: background.height,
          },
          MAX_AREA
        )
      : background;

    this.bgWidth = newDim.width;
    this.bgHeight = newDim.height;

    return background;
  }

  _scaleImage(image, scale) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const scaledWidth = image.width * scale;
    const scaledHeight = image.height * scale;

    canvas.width = scaledWidth;
    canvas.height = scaledHeight;

    ctx.drawImage(image, 0, 0, scaledWidth, scaledHeight);

    return {
      data: canvas.toDataURL("image/png", 1.0),
      width: scaledWidth,
      height: scaledHeight,
    };
  }

  async _getScaledMarkups(markups) {
    const scaledMarkups = [];
    for (const mark of markups) {
      const normalizedScale = mark.scale;
      const normalizedX = mark.x;
      const normalizedY = mark.y;
      const markupImage = await this._getSvg(mark.id, mark.markSVG);
      const scaledMarkup = this._scaleImage(markupImage, normalizedScale);
      scaledMarkups.push({
        id: mark.id,
        x: normalizedX,
        y: normalizedY,
        src: scaledMarkup.data,
        width: scaledMarkup.width,
        height: scaledMarkup.height,
      });
    }
    return scaledMarkups;
  }

  async _mergeImages(
    background,
    markups,
    width,
    height,
    format,
    outputQuality = 1.0
  ) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;

    console.log(
      "[ImageMergeMethod] Merging image with the following dimensions: " +
        JSON.stringify({ width, height }, null, 2)
    );
    const markupImages = await loadImages(markups);

    ctx.drawImage(background, 0, 0, width, height);

    markupImages.forEach((image, index) => {
      const { src, img, ...markupData } = markups[index];

      console.log(
        "[ImageMergeMethod] Merging the following mark: " +
          JSON.stringify(markupData, null, 2)
      );

      ctx.drawImage(image.img, image.x || 0, image.y || 0);
    });

    return canvas.toDataURL(format, outputQuality);
  }

  async merge(payload) {
    const mergedImages = [];
    for (const document of payload.documents) {
      const backgroundImage = await this._getBackgroundImage(document.source);
      const documentMarks = payload.markInsertions.filter(
        (mark) => mark.document.id === document.id
      );

      if (documentMarks.length === 0) continue;

      const markups = await this._getScaledMarkups(documentMarks);
      const mergedImage = await this._mergeImages(
        backgroundImage,
        markups,
        this.bgWidth,
        this.bgHeight,
        "image/jpeg"
      );

      mergedImages.push({
        id: document.id,
        data: mergedImage,
        mimeType: "image/jpeg",
      });
    }
    return mergedImages;
  }
}
