import { ProgressIndicator as ProgressComponent } from "@veneer/core";

const getIndicatorStyle = (scale) => {
  return {
    transform: `scale(${scale})`,
    margin: "auto auto",
  };
};

const wrapperStyle = {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const CircularProgressIndicator = ({ scale = 1, style = {} }) => {
  const progressStyle = getIndicatorStyle(scale);
  return (
    <div style={{ ...wrapperStyle, ...style }}>
      <ProgressComponent
        data-testid="progress-indicator"
        style={progressStyle}
      />
    </div>
  );
};

export default CircularProgressIndicator;
