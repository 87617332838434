/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import IconButton from "../IconButton";
import ThinStrokeIcon from "../icons/ThinStrokeIcon";
import ThickStrokeIcon from "../icons/ThickStrokeIcon";
import MediumStrokeIcon from "../icons/MediumStrokeIcon";

import { tokens } from "../../css.settings";

const rootStyle = ({ active }) => css`
  ${active === true &&
  `
    > button {
      border-width: 2px;
      border-color: ${tokens.color.gray7};
      &:after {
        opacity: 1;
        border-radius: 50%;
        margin: 2px;
        border-color: ${tokens.color.gray3};
      }
    }
  `}
`;

const StrokeThicknessButton = ({
  label = "",
  thickness = "thin",
  active = false,
  disabled,
  onClick,
}) => {
  const leadingIcon = {
    thick: <ThickStrokeIcon />,
    medium: <MediumStrokeIcon />,
    thin: <ThinStrokeIcon />,
  };

  return (
    <IconButton
      data-testid={`stroke-thickness-button-${thickness}`}
      disabled={disabled}
      label={label}
      aria-selected={active}
      appearance="ghost"
      leadingIcon={leadingIcon[thickness]}
      onClick={onClick}
      styles={{ root: rootStyle({ active }) }}
    />
  );
};

export default StrokeThicknessButton;
